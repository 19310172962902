<template>

    <div class="content">

        <BtnIco @click="this.status=!this.status;" ico="menu" />

        <div v-show="status == true" id="menu" ref="menu" class="menu" :style="{ top: height_menu }">
        
            <ul>

                <!-- Menu Provisional para seleccionar el formato de equipo -->
                <li><BtnBase text="Start Game" v-on:click="$store.dispatch('startGame',game_selceted)" /></li>

                <!-- Menu Provisional para seleccionar el formato de equipo -->
                <li class="h6">Choose Game</li>
                <select v-model="game_selceted">
                    <option  selected value="pictionary">Pictionary</option>
                    <option  value="factMatching">Fact Matching</option>
                    <option  selected value="mingleTingle">Mingle Tingle</option>
                    <option  value="sellerSponge">Seller Sponge</option>
                    <option  value="wereWolf">Were Wolf</option>
                    <option  value="lobby">Lobby</option>

                </select>
            </ul>

            

        </div>

    </div>

</template>

<script>

    import BtnIco from '@/components/buttons/BtnIco';
    import InputSwitch from '@/components/inputs/InputSwitch';
    import InlineSvg from 'vue-inline-svg'
    import BtnBase from '@/components/buttons/BtnBase';

    export default {
    
        name: 'TopRight',

        components:{
            BtnIco,
            InlineSvg,
            InputSwitch,
            BtnBase,
        },

        props:{
            team_format: {type:Number,default:0}, // Formato de equipo que participan en la partida
            game_active: {type:Number,default:0}, // Juego Activo
            game_master: {type:Boolean,default:false}, // Juego Activo
            game_selceted:{type:String,default:"pictionary"}, 
        },

        data:function(){
            return{
                status: false,
                height_menu: 'auto'
            }
        },

      

    }

</script>

<style lang="scss" scoped>

    .content{
        
        position: relative;

    }

    .menu{

        position: absolute;
        top: 0;
        right: 0;
        height: auto;
        width:256px;
        padding: 20px 16px;
        z-index: 2999;
        background: color(grays,base);
        box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;

        ul{
            list-style-type: none;

            li{

                display: flex;
                align-items: center;
                margin-top: 8px;

                &:first-of-type{
                    margin:0 !important;
                }

                &.h6{
                    margin-top:16px;
                }

                button{

                    background: transparent;
                    border: none;
                    cursor: pointer;
                    font-family: $font-family;
                    color: color(text,100);
                    font-size: 16px;
                    line-height: 16px;

                    &.active{
                        font-weight: bold;

                        &::after{
                            content: '<'
                        }
                    }

                    &:focus-visible {
                        outline: none;
                        color: color(primary);
                        font-weight: bold;
                    }
                }

                .text{
                    flex: 1 1 100%;
                }

                svg{
                    height: 16px;
                    width: 16px;
                    flex: 0 0 16px;
                }

            }

        }

        .triangle{

            position: absolute;
            right: 0;
            bottom: -16px;
            display: flex;
            justify-content: flex-end;
            padding:0 4px 0 0px;
            margin: -1px 0 0;

        }

    }

</style>