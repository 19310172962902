<template>
    <div class="card card-welcome" :class="card_name">
        <inline-svg :src="require('@/assets/svg/'+ ico +'.svg')"/>
        <h2 class="h5">{{title}}</h2>
        <p v-html="text"></p>
    </div>
</template>

<script>
    
    import InlineSvg from 'vue-inline-svg';
    export default {
        name: 'CardWelcome',
        components:{
            InlineSvg
        },
        props:{
            ico:            String,
            title:          String,
            text:           String,
            card_name:      String
        }
    }

</script>

<style lang="scss" scoped>

    .card.card-welcome{
        
        text-align: center;
        border: none;
        border-radius: 0;
        padding: 24px;

        &-1,
        &-2{
          padding-bottom: 32px;
        }

        &-1{
          border-right: 1px solid color(border);
          border-bottom: 1px solid color(border);
        }

        &-2{
          border-bottom: 1px solid color(border);
        }

        &-3,
        &-4{
          padding-top: 32px;
        }
        
        &-3{
          border-right: 1px solid color(border);
        }

        h2{
          margin-top: 32px;

          & + p{
            margin-top: 8px;
          }

        }

    }

</style>