<template>
    <GameContainer v-if="$store.state.storeGameState.gameStep == 0">
        <img
            v-if="$store.state.storeGameState.demoPhoto < 8"
            :src="
                require('@/assets/games/demoGame/' +
                    $store.state.storeGameState.demoPhoto +
                    '_' +
                    languageContent +
                    '.png')
            "
            alt=""
            class="object-scale-down w-full h-full"
        />
        <FinalFeedback v-else />
    </GameContainer>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, watch, ref } from "vue";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import { useI18n } from "vue-i18n";
import FinalFeedback from "../FinalFeedback.vue";

export default {
    components: {
        GameContainer,
        FinalFeedback,
    },

    setup() {
        const store = useStore();
        const { locale } = useI18n();

        const languageContent = ref(computed(() => locale.value));
        return {
            languageContent,
        };
    },
};
</script>

<style lang="scss" scoped>
.row {
    flex: 1 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
}

.fullrow {
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.row2x2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.rowCenter {
    align-items: stretch;
    justify-content: center;
}

.userWarap {
    width: 160px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}

.userWarap-ranking {
    width: 160px;
    :deep(.card_user) {
        width: 80px + 10px;
        height: 80px + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
