<template>
    <div class="flex flex-row w-full card-rank py-10 px-2">
        <div class="w-6/12 items-center">
            <h4 class="text-center m-2">{{ $t("results.correct") }} +{{ pointsPlayers }} {{ $t("game.points") }}</h4>
            <div class="flex flex-row w-full justify-center gap-1 flex-wrap">
                <div class="userWarap-ranking" v-for="userID in winners" v-bind:key="userID.id">
                    <CardUser :track="$store.getters.getUserById(userID)" :photoMode="true" />
                </div>
            </div>
        </div>
        <div class="w-6/12 items-center">
            <h4 class="text-center m-2">{{ $t("results.incorrect") }}</h4>
            <div class="flex flex-row w-full justify-center gap-1 flex-wrap	">
                <div class="userWarap-ranking" v-for="userID in losers" v-bind:key="userID.id">
                    <CardUser :track="$store.getters.getUserById(userID)" :photoMode="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardUser from "@/components/cards/CardUser.vue";
export default {
    components: {
        CardUser,
    },

    props: {
        winners: Array,
        losers: Array,
        pointsPlayers: Number,
        pointsMainPlayer: Number,
    },
};
</script>

<style lang="scss" scoped>
.card-rank {
    background: #f4f5f7;
    /* Border/20 */

    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.userWarap-ranking {
    :deep(.card_user) {
        width: 80px + 10px;
        height: 80px + 10px;
    }
}
</style>
