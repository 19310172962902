
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};


const firebaseAPP = initializeApp(firebaseConfig);
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, ref as refImg, getDownloadURL } from "firebase/storage";

const db = getDatabase();
// if (location.hostname === "localhost") {
//   // Point to the RTDB emulator running on localhost.
//   console.log("in")
//   connectDatabaseEmulator(db, "localhost", 9000);
// } 

const getUrlPhoto = async (url) => {
  const storage = getStorage();
  console.log(url)
  return url = await getDownloadURL(refImg(storage, url))
}




export { firebaseAPP, db, getUrlPhoto }