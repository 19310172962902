<template>
    <div
        class="card card-team"
    >
        <div class="flex flex-col gap-2 items-center">
            <p
                v-if="title"
                class="font-bold mr-auto uppercase"
            >
                {{title}}
            </p>
            <h4>
                {{bonfireGame.name}}
            </h4>
            <img
                class="w-7/12"
                :src="bonfireGame.icon"
                alt=""
            >
            <div class="flex flex-row gap-10 font-bold text-center">
                <p>{{bonfireGame.duracion}} {{$t('cardGame.mins')}}</p>
                <p> {{bonfireGame.grup_size}} {{$t('cardGame.groupSize')}}</p>
            </div>
            <p class="text-center minh">
                {{bonfireGame.short_description}}
            </p>
            <BtnBase
                :text="$t('general.play')"
                class=""
                :disabled="!$store.state.storeMedia.localStream.admin"
                v-on:click="selectGame(bonfireGame.name_id.name_id)"
            />
        </div>
    </div>
</template>

<script>
import CardUser from "@/components/cards/CardUser";
import InlineSvg from "vue-inline-svg";
import BtnBase from "@/components/buttons/BtnBase.vue";
import { useStore } from "vuex";

export default {
    name: "CardWelcome",

    components: {
        CardUser,
        InlineSvg,
        BtnBase,
    },
    props: {
        bonfireGame: Object,
        title: { type: String, default: "" },
        image: String,
    },
    setup() {
        const store = useStore();
        const selectGame = (selectedGame) => {
            store.dispatch("selectGame", selectedGame);
        };
        return {
            selectGame,
        };
    },
};
</script>

<style lang="scss" scoped>
.minh {
    min-height: 3.5rem;
}
.card.card-team {
    padding: 24px;
    // margin-top: 32px;
    // margin-bottom: 10px;
    text-align: left;
    border: none;
    background: #ffffff;
    box-shadow: 0px 5px 16px rgba(119, 119, 119, 0.15);
    border: 1px solid #E4E4E4;
    border-radius: 12px;

    @media (min-width: $break-point) {
        // margin-top: 48px;
    }

    .card-team-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        button {
            cursor: pointer;
            border: 0;
            background: transparent;
        }
    }
}
</style>