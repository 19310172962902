<template>
    <div
        class="navbar navbar_top"
        :class="classNavbar"
    >

        <transition
            enter-active-class="animate__animated animate__fadeInLeft animate__slow"
            leave-active-class="animate__animated animate__fadeOutLeft"
            appear
            mode="out-in"
        >
            <div class="logo_wrap">
                <InlineSvg :src="require('@/assets/svg/logo_bonfire_full.svg')" />
            </div>
        </transition>

        <NotificationGroup />

        <transition
            enter-active-class="animate__animated animate__fadeInRight"
            leave-active-class="animate__animated animate__fadeOutRight"
            appear
            mode="out-in"
        >
            <div
                class="menu_right"
                v-if="page>=1"
            >
                <!-- <AdminPanel /> -->
                <div class="font-bold flex flex-row">
                    {{$store.getters.getAllTracePlayers.length}}/10 {{$t('general.players')}}

                </div>
                <select
                    class="py-3 px-4 ml-4 rounded-xl block background-input"
                    v-model="$i18n.locale"
                    v-on:change="setLanguage()"
                >
                    <option
                        v-for="(lang, key) in $store.state.storeNav.languages"
                        :key="key"
                        :value="key"
                    >
                        {{ lang }}
                    </option>
                </select>
                <BtnIco
                    ico="user_plus"
                    @click="this.$emit('setModal_add',true)"
                    :toolTip="$t('navbar.toolTip_invite_players')"
                />
                <!-- <BtnIco
                    ico="info_circle"
                    toolTip="Info"
                /> -->
                <BtnIco
                    ico="lobby"
                    :toolTip="$t('navbar.toolTip_lobby')"
                    v-if="$store.state.storeMedia.localStream.admin"
                    v-on:click="$store.dispatch('startGame',{game:'lobby'})"
                />
                <BtnIco
                    ico="crown-4697"
                    :toolTip="$t('navbar.toolTip_host_you')"
                    v-if="$store.state.storeMedia.localStream.admin"
                    dissabled
                    :activeIcon="$store.state.storeMedia.localStream.admin"
                    v-on:click="$store.commit('setModalHost',true)"
                />
                <BtnIco
                    ico="crown-4697"
                    :toolTip="$t('navbar.toolTip_request_host')"
                    v-else
                    @click="$store.dispatch('requestHost')"
                />
                <!--<BtnIco
                    ico="xmark-solid"
                    :toolTip="$t('navbar.exit')"
                    @click="closeTab()"
                /> -->
                <BtnIco
                    v-if="checkUser()"
                    ico="power-off-solid"
                    :toolTip="$t('navbar.logout')"
                    v-on:click="$store.dispatch('logout')"
                />  
                <BtnIco
                    v-else
                    ico="arrow-right-to-bracket-solid"
                    :toolTip="$t('auth.login')"
                    v-on:click="$store.commit('setModalAuth',true)"
                />  
            </div>
            <div v-else  class="menu_right">
                <div class="flex flex-row">
                    <select
                        class="py-3 px-4  rounded-xl block background-input"
                        v-model="$i18n.locale"
                        v-on:change="setLanguage()"
                    >
                        <option
                            v-for="(lang, key) in $store.state.storeNav.languages"
                            :key="key"
                            :value="key"
                        >
                            {{ lang }}
                        </option>
                    </select>
                    <BtnIco
                        v-if="checkUser()"
                        ico="power-off-solid"
                        :toolTip="$t('navbar.logout')"
                        v-on:click="$store.dispatch('logout')"
                    />  
                    <BtnIco
                        v-else
                        ico="arrow-right-to-bracket-solid"
                        toolTip="login"
                        v-on:click="$store.commit('setModalAuth',true)"
                    /> 

                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import NotificationGroup from "@/components/alerts/NotificationGroup";
import BtnIco from "@/components/buttons/BtnIco.vue";
import AdminPanel from "@/components/dropdowns/AdminPanel.vue";
import { useI18n } from 'vue-i18n';
export default {
    name: "NavbarTop",

    components: {
        InlineSvg,
        NotificationGroup,
        BtnIco,
        AdminPanel,
    },

    props: {
        page: { type: Number, default: 0 },
        alert: {
            type: Object,
            default: {
                type: "warning",
                text: "need more players (minum 2)",
                status: true,
            },
        },
    },

    computed: {
        classNavbar: function () {
            return this.page == 0 ? "navbar_setUp" : "navbar_lobby";
        },
    },

    setup(){
        const { locale } = useI18n();
        const setLanguage = ()=>{
            console.log(locale.value)
            localStorage.setItem("language",locale.value )
        }   
        const Logout = ()=>{

        }
        const checkUser  =()=>{
            return localStorage.getItem('user') != null
        }
        /*const closeTab = () => {
            window.top.close();
        }*/
        return{
            setLanguage,
            Logout,
            checkUser
        }
    }
};
</script>

<style lang="scss" scoped>
.alertgroup {
    z-index: 9999;
}
.navbar.navbar_top {
    display: flex;
    align-items: flex-start;
    height: 88px;
    flex: 0 0 88px;
    width: 100%;
    padding: 0 32px;
    transition: 0.3s all ease-in-out;
    border-bottom: 1px solid #ffffff;

    .logo_wrap {
        display: flex;
        align-items: center;
        flex: 1 0 auto;
        height: 100%;

        svg {
            height: 32px;
            width: auto;
        }
    }

    .menu_right {
        display: flex;
        align-items: center;
        flex: 1 0 auto;
        height: 100%;
        justify-content: flex-end;
        align-items: center;

        .btn {
            margin-left: 24px;

            &:first-child {
                margin: 0;
            }
        }
    }

    &.navbar_setup {
        border-bottom: 1px solid #e6e6e6;

        .logo_wrap {
            svg {
                opacity: 0.5;
            }
        }
    }

    &.navbar_lobby {
        border-bottom: 1px solid #e6e6e6;
    }
}
</style>