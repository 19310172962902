<template>
  <p class="round-text" v-if="$store.state.storeGameState.currentRound > 0">
     {{$t('game.round')}}: 
     <strong>
        {{$store.state.storeGameState.currentRound}} / {{$store.state.storeGameState.rounds}}
     </strong>
  </p>
</template>

<script>
export default {

}
</script>

<style>
.round-text{
  /* font-family: Gilroy; */
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #5F6165;
  }
</style>