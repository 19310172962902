import { db } from '../../firebase'
import { ref, off, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get } from "firebase/database";
import { shuffle } from '../../lib/common';
const url = window.location.pathname


const factMatchingData = {
    "question": null,
    "currentAnswer": null,
    "answerGuess": [],
    "arrayPlayers": [],
    userMatch: {},
    "fakeId": null,
}

const factMatchingMutations = {
    factMachingSetAnswer(state, data) {
        if (data) {
            state.factMatchingData.currentAnswer = data.currentAnswer;
            state.factMatchingData.userMatch = data.userMatch;
            state.factMatchingData.answerGuess = state.factMatchingData.answerGuess.filter((item) => item.id != state.factMatchingData.fakeId);

        }
        else {
            state.factMatchingData.currentAnswer = "";

        }
    },
    factMachingSetGameDataFact(state, data) {
        state.factMatchingData.question = data.data.question;

    },
    factMachingSetGameData2Fact(state, data) {
        const fakeId = (data.data.fakeIds[data.idPlayer])
        state.factMatchingData.fakeId = fakeId;
        state.factMatchingData.answerGuess = data.data.answerGuess.filter((item) => item.id != fakeId);
        shuffle(state.factMatchingData.answerGuess)

    },
    factMachingSetGameResults(state, data) {
        state.factMatchingData.wordResult = data.results[data.currentResult].currentAnswer
        state.factMatchingData.playerResultId = data.results[data.currentResult].playerID
        state.factMatchingData.winners = data.results[data.currentResult].correct
        state.factMatchingData.losers = data.results[data.currentResult].wrong

    }
}

const factMatchingActions = {
    factMatchingUpdateMatch({ commit, state, rootState }, data) {
        const dataBasePlayerUrl4 = url + '/gameDataUser/' + rootState.storeMedia.idPlayer + "/userMatch/"
        const teamDataDB4 = ref(db, dataBasePlayerUrl4);
        update(teamDataDB4, data)
    }
}

const factMatchingStartGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer
    const teamDataDB = ref(db, dataBasePlayerUrl);
    onValue(teamDataDB, (data) => {
        commit("factMachingSetAnswer", data.val())
    });
}
const factMatchingFinishGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer
    const teamDataDB = ref(db, dataBasePlayerUrl);
    off(teamDataDB)
}


const factMatchingGameDataAdmin = ({ commit, state, rootState }, data) => {
    commit("factMachingSetGameDataFact", {
        data: data.val(),
        idPlayer: rootState.storeMedia.idPlayer
    })
}

const factMatchingGameDataAdmin2 = ({ commit, state, rootState }, data) => {
    commit("factMachingSetGameData2Fact", {
        data: data.val(),
        idPlayer: rootState.storeMedia.idPlayer
    })
}

const factMatchingGameDataResults = ({ commit, state, rootState }, data) => {
    commit("factMachingSetGameResults", data.val())
}

const factMatchingGameDataScret = ({ commit, state, rootState }, data) => {
}



export {
    factMatchingGameDataAdmin,
    factMatchingGameDataAdmin2,
    factMatchingGameDataResults,
    factMatchingData,
    factMatchingMutations,
    factMatchingActions,
    factMatchingStartGame,
    factMatchingFinishGame,
    factMatchingGameDataScret
}