<template>
  <div class="flex flex-col gap-10 mt-3">
    <div class="pager-indicators">
      <div  v-for="n in maxStep" :key="n" class="pager-indicators-item" v-on:click="setStep(n-1)" :class="{active:step==n-1}">
      </div>  
    </div>
  </div>

</template>

<script>


  export default {
    
    name: 'PagerWelcome',
    components: {
      

    },
    props:{
      step: Number,
      maxStep:Number,
      setStep:{type:Function, default:()=>{}},
    }

  
  }

</script>

<style lang="scss" scoped>
    

    .pager-indicators{

        display: flex;
        justify-content: center;
        align-items: center;

        .pager-indicators-item{
            
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: color(primary,60);
            margin: 0 5px;
            transition: 0.5s all 0.2s ease-in-out;
            cursor: pointer;

            &.active{
              
              width: 20px;
              height: 20px;

              background: color(primary,base);

            }

        }

    }

</style>