import { db } from '../../firebase'
import { ref, off, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get } from "firebase/database";

const url = window.location.pathname


const wereWolfData = {
    "rol":null,
    "alivePlayers":[],
    "players":[],
    "actionPlayers":[],
    "actionPlayerOn":null,
    "deadPlayer":null,
    "seerAnswer":null,
    "votePlayers":[],
    "playersVotes":{},
    "myVote":null,
    "isTheKiller":null,
    "ableToVote":false,
    "winner":null,
    killerId:null,
}


let tempData={}

const wereWolfMutations = {
    wereWolfFetchVote(state,input)
    {
        let data = input.data
        let playerId = input.idPlayer
        if(data)
        {
            
            
            state.wereWolfData.playersVotes = {}
    
            Object.keys(data).map(function(key, index) {
                if(playerId == key)
                {
                    state.wereWolfData.myVote = data[key]
                }
    
                if(data[key] in state.wereWolfData.playersVotes)
                {
                    state.wereWolfData.playersVotes[data[key]]++;
                }
                else
                {
                    state.wereWolfData.playersVotes[data[key]] = 1
                }
            });
        }
        else
        {
            state.wereWolfData.playersVotes = []
            state.wereWolfData.myVote= ""
        }
       
    },
    wereWolfSetGameDataSecret(state, data) {
        state.wereWolfData.rol = data.rol
    },
    wereWolfSetGameData(state, data) {
        state.wereWolfData.alivePlayers = data.data.alivePlayers
        state.wereWolfData.players = data.data.arrayPlayers
        state.wereWolfData.actionPlayers = data.data.alivePlayers.filter((e)=>e!=data.idPlayer)
        state.wereWolfData.votePlayers =[];
        console.log(data.data.alivePlayers.find((e)=>e==data.idPlayer))
        if(data.data.alivePlayers.find((e)=>e==data.idPlayer))
        {
            state.wereWolfData.ableToVote = true; 
        }
        else{
            state.wereWolfData.ableToVote = false;
        }


        data.data.arrayPlayers.map((player)=>{
            console.log("in")
            let status = "dead"
            if(data.data.alivePlayers.indexOf(player)>= 0)
            {
                status = "alive"
            }   
            state.wereWolfData.votePlayers.push({
                idPlayer:player,
                status,
            })
        })


    },
    wereWolfSetGameData2(state, data) {
        state.wereWolfData.deadPlayer = data.data.playerDead
        if(state.wereWolfData.rol == "seer")
        {
            state.wereWolfData.seerAnswer = data.data[data.idPlayer]
        }
    },
    wereWolfSetGameResults(state, data) {
        state.wereWolfData.killerId = data.killerPlayer
        state.wereWolfData.deadPlayer = data.dead
        state.wereWolfData.isTheKiller = data.killer
        if(data.winner)
        {
            state.wereWolfData.winner = data.winner
        }
        else
        {
            state.wereWolfData.winner = null
        }

    }
}

const wereWolfActions = {
    wereWolfUserAction({commit,state, rootState},data){
        const dataBasePlayerUrl =  url + '/gameDataUser/vote'
        const teamDataDB = ref(db,dataBasePlayerUrl);
        update(teamDataDB, {
            [rootState.storeMedia.idPlayer]: data
        })
    }
}

const wereWolfStartGame =({commit,state,rootState})=>{
    const dataBasePlayerUrl =  url + '/gameDataUser/vote' 
    const teamDataDB = ref(db,dataBasePlayerUrl);
    onValue(teamDataDB, (data) => {
        commit("wereWolfFetchVote",{
            data:data.val(),
            idPlayer: rootState.storeMedia.idPlayer})
    });
    
}
const wereWolfFinishGame =({commit,state,rootState})=>{
    const dataBasePlayerUrl =  url + '/gameDataUser/vote' 
    const teamDataDB = ref(db,dataBasePlayerUrl);
    off(teamDataDB)
}

const wereWolfDataScret =({commit,state, rootState},data)=>{
    commit("wereWolfSetGameDataSecret", data.val())
}

const wereWolfGameDataAdmin=({commit,state, rootState},data)=>{
    commit("wereWolfSetGameData", {
        data:data.val(),
        idPlayer: rootState.storeMedia.idPlayer})
    
}


const wereWolfGameDataAdmin2=({commit,state, rootState},data)=>{
    commit("wereWolfSetGameData2",{
        data:data.val(),
        idPlayer: rootState.storeMedia.idPlayer})
    
}

const wereWolfGameDataResults=({commit,state, rootState},data)=>{
    commit("wereWolfSetGameResults", data.val())
}


export { 
    wereWolfGameDataAdmin,
    wereWolfGameDataAdmin2,
    wereWolfGameDataResults,
    wereWolfData, 
    wereWolfMutations, 
    wereWolfActions,
    wereWolfStartGame,
    wereWolfFinishGame,
    wereWolfDataScret,  
}