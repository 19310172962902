<template>

    <button
        :disabled="isLoading || disabled"
        type="button"
        class="rounded-xl" 
    >
        <slot></slot>
        <span
            v-if="isLoading"
            class="w-4 h-4 ml-2"
        ><svg
                width="80%"
                viewBox="-2 -2 42 42"
                xmlns="http://www.w3.org/2000/svg"
                stroke="white"
                class="w-full h-full"
            >
                <g
                    fill="none"
                    fill-rule="evenodd"
                >
                    <g
                        transform="translate(1 1)"
                        stroke-width="4"
                    >
                        <circle
                            stroke-opacity=".5"
                            cx="18"
                            cy="18"
                            r="18"
                        ></circle>
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"
                            ></animateTransform>
                        </path>
                    </g>
                </g>
            </svg>
        </span>
    </button>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
    props: {
        isLoading: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false }
    }
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 12px 24px;
  display: inline-flex;
  border: none;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  border: 2px solid transparent;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.15);

  &.btn-outline {
    background: transparent;
    border: 2px solid color(primary);
    color: color(primary);

    &:hover {
      border-color: darken(color(primary), 15%);
      background-color: color(primary);
      color: #fff;
    }

    &:disabled,
    &[disabled] {
        @apply opacity-70 cursor-not-allowed
    }
  }

  &.btn-primary {
    background: color(primary, base);
    color: color(text, base);

    &:hover {
      background: darken(color(primary), 15%);
    }
    &:disabled,
    &[disabled] {
       @apply opacity-70 cursor-not-allowed
    }
  }

  &.btn-light {
    background: color(grays, 200);
    color: color(text, 100);
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: color(grays, 400);
      color: color(text, base);
    }
  }

  &.btn-xxs {
    padding: 8px 12px;

    font-size: 8px;
    line-height: 8px;
  }

  &.btn-xs {
    padding: 10px 16px;

    font-size: 12px;
    line-height: 12px;
  }

  &.btn-sm {
    padding: 12px 24px;

    font-size: 16px;
    line-height: 16px;
  }

  &.btn-md {
    padding: 12px 24px;

    font-size: 24px;
    line-height: 24px;
  }

  &.btn-lg {
    padding: 16px 32px;

    font-size: 32px;
    line-height: 32px;
  }
}
</style>