const en = {
    // ON BOARDING
    "WELCOME0": {
        title: "Welcome to Bonfire",
        btn: "Let's get started!",
    },
    "WELCOME1": {
        headphones: "Use headphones",
        headphones_text: "To ensure there are no interferences between your speakers and your mic.",

        internet: "Connect to a stable network",
        internet_text: "Make sure you have a high speed internet connection with a steady signal.",

        window: "Use a computer",
        window_text: "Bonfire is compatible with computers and laptops, correct functioning is not guaranteed in mobile devices and tablets.",

        chat: "Close other apps",
        chat_text: "Other applications that use voice chat or videocalls may interfere with the Bonfire experience. Please make sure they are closed.",
        btn: "Next",
    },
    "WELCOME2": {
        title: "Test your mic and camera",
        text_mic: "Select your microphone device and see the fire light up when you speak.",
        text_camera: "Select your camera device and check that you look gorgeous today.",
        btnOK: "It works!",
        btnNoOK: "It's not working",
        btnOKNoCam: "Continue without camera",
        btn_sound: "Play test sound",
        btn_stop_sound: "Stop test sound",
        mic_error_title: "Microphone troubleshooting",
        mic_error_text: "The following steps might help fixing your microphone:",
        mic_error_list: [
            "Make sure you have selected the correct audio device.",
            "Close any apps that might also be using the microphone.",
            "Unplug and replug your microphone.",
            "Check the microphone’s volume level in system settings.",
            "Make sure there is nothing covering your microphone.",
            "Use a different microphone.",
            "Use a different web browser (we recommend Google Chrome).",
            "Check that your internet connection is fast and stable.",
            "If it still doesn't work, please try using a different computer.",
        ],
        CAM_error_title: "Camera troubleshooting",
        CAM_error_text: "The following steps might help fixing your camera:",
        CAM_error_list: [
            "Make sure you have selected the correct camera device.",
            "Close any applications that might also be using the camera.",
            "Unplug and replug your camera.",
            "Make sure there is nothing covering your camera, such as a sticker.",
            "Check your internet connection.",
            "Use a different camera.",
            "Use a different web browser (we recommend Google Chrome).",
            "If it still doesn't work, please try using a different computer.",
        ],
        CAM_not_detected: "Camera device not detected"
    },
    "WELCOME3": {
        title: "Say Cheese!",
        text: "Smile and look at the camera. This picture will be the icon of your profile during the game.",
        btnPhoto: "Take picture",
        btnRepeat: "Try again",
        btnNext: "Next",
        text_no_camera: "Here's your avatar.",
        continue: "Next",
        error_photo: "Your version of Google Chrome is not updated. Please update the google Google Chrome or try with Firefox. Thanks"
    },
    "WELCOME4": {
        title: "What’s your name?",
        text: "Enter the name that will be displayed during the game. If you’re familiar with the rest of the players, you might choose a cool nickname.",
        btn: "Enter Bonfire",
        placeholder: "Name",
        error_input: "You should enter a username."
    },

    PERMISSIONS: {
        text: "Allow the browser to use the microphone and camera. We need these permissions so that you can communicate with your teammates in the game. You can disable the camera or mute the microphone later."
    },

    // GENERAL
    general: {
        players: "Players",
        play: "Play",
        back: "Back",
        game_start: "Sorry, the game has started without you :( Wait for it to be over in order to join the next one.",
        date_format: "en-US",
        user_disconected: "Disconected"
    },

    // ALERTS 
    alerts: {
        error: "Error",
        success: "Success",
        need_more_players: "Not enough players for this game. Minimum players: ",
        skip_tutorial: "Tutorial skipped",
        skip_tutorial_fail: "The tutorial can't be skipped",
        you_host: "Your are the Host",
        your_host_request: " has requested to be the host", // X request the host
        host_request: "You have requested to be the host",
        name_update: "Username updated",
        photo_update: "Picture updated",
        give_host: "Give",
        game_start: "The game has started",
        game_end: "The game has been ended",
        step_skipped: "Game phase has been skipped",
        no_skipped: "Phase not skipped",
        no_skipped_text: "For skip the phase you have to hold the skip button for 1 second",
    },

    // AUTH 
    auth: {
        alert_login_success: "Login success",
        alert_login_fail: "Login fail",
        login: "Sign In",
        text_need_login: "You need to login in order to play! ",
        login_google: "Login in with Google",
        signup: "Sign up",
        logout: "Logout",
        wrong_password: "wrong password",
    },



    settingMenu: {
        settings: "Settings",
        user_setting: "Username",
        photo_settings: "User picture",
        microphone_settings: "Microphone",
        camera_settings: "Camera",
        bonfire_name: "Change username",
        bonfire_image: "Change user picture",
        update: "Save",
        title_mic: "Select microphone",
        text_mic: "Select your microphone device and see the fire light up when you speak.",
        title_camera: "Select camera",
        text_camera: "Select your camera device and check that you look gorgeous today.",

    },

    // Modals:
    Modals: {
        error_title: "Error",
        game_no_exist: "This game does not exist.",
        game_finish: "The game has already finished.",
        game_full: "This game is full.",
        game_user_not_allowed: " is not allowed to play in this room.", // X user is not allwoed to play
        game_user_already_contected: "This user is already online.",

    },

    // Card Game
    cardGame: {
        duration: "min:",
        mins: "min",
        groupSize: "players",
        game_year: "Game of the month",
        most_played: "Most Played"
    },



    //LOBBY
    navbar: {
        toolTip_invite_players: "Invite players",
        toolTip_lobby: "Return to lobby",
        modal_invite_title: "Invite players",
        modal_invite_text: "Share this link with your colleagues so that they can join this Bonfire session:",
        modal_invite_copy: "Copy invitation",
        toolTip_camera_icon: "Turn camera on / off",
        toolTip_host_you: "You are the host",
        toolTip_request_host: "Request host",
        logout: "Logout",
        exit: "Exit"
    },



    bottomvar: {
        toolTip_camera_icon_on: "Turn camera on",
        toolTip_camera_icon_off: "Turn camera off",
        toolTip_micro_on: "Turn microphone on",
        toolTip_micro_off: "Turn microphone off",
        toolTip_slow_mode_on: "Turn slow mode on",
        toolTip_slow_mode_off: "Turn slow mode off",
        toolTip_menu_on: "Open menu",
        toolTip_menu_off: "Close menu",
        toolTip_options_on: "Open settings",
        toolTip_options_off: "Open settings",
        toolTip_share_screen_on: "Share screen",
        toolTip_share_screen_off: "Stop sharing screen",
    },

    modalOptionsMeida: {
        title: "Change audio/video",
        select_micro: "Change mic",
        select_camera: "Change camera",
        btn_ok: "Change",
        error_media: "You need to choose a device."
    },


    modalHost: {
        title: "You are the Bonfire host.",
        text: "You have been assigned the control of the game session. You are responsible for choosing activities and you can stop the game if necessary. You can swap the controls with another player.",
        btn_ok: "Okay",
    },

    modalInvite: {
        title: "Invite players",
        text: "Share this link with your colleagues so that they can join this Bonfire session:",
        btn_ok: "Copy invitation",
        alert_title: "Link copied",
        alert_text: "The link has been copied to your clipboard. You just need to paste it.",
    },

    gameSelect: {
        theme: "Category",
        option: "Game set",
        altert_start: "Once the game starts, new players won't be able to join.",
        no_content: "There are no content choices available for this game.",
        placeholder: "Choose",
        language: "Language",
        duration: "Duration"
    },



    // GENERAL GAME

    game: {
        tutorial_wait: "Waiting for the captain to start the game.",
        btn_skip_tutorial: "Skip tutorial",
        btn_next_tutorial: "Next",
        btn_before_tutorial: "Back",
        points: "points",
        round: "Round",
        player: "player",
        retunr_lobby: "You can now go back to the lobby to select another game.",
        skip_step: "Skip",
        score: "score",
        start: "Start",
    },

    results: {
        correct: "Guessed it right:",
        incorrect: "Guessed it wrong:",
        votes: "votes",
    },

    ranking: {
        title: "Ranking",

    },

    feedback: {
        title: "Well done!",
        text: "Now the game host can return to the lobby to select another game. Before leaving, you can give us your opinion about this game.",
        text_placeholder: "Write a comment.",
        altert_thanks: "Thank you. Your feedback helps us to keep going!",
        btn_send: "Send feedback",
        btn_lobby: "Return to lobby"
    },

    chat: {
        input_placeholder: "Write a message...",
        tooltip_open: "Open chat",
        tooltip_close: "Close chat",

    },

    // COMPONENTNS GAMES
    openQuestion: {
        placeholder: "Answer",
        alert_send: "Answer saved",
        btn_save: "Save your answer",
        answer_text: "This is your saved answer: ",
        text_error: "You must enter an answer",

    },


    // PICTIONARY
    pictionary: {
        title_1: "Welcome to Draw it",
        text_1: "Put your artistic abilities to the test trying to represent specific concepts with a drawing. Let’s see how the game works:",
        title_2: "Draw",
        text_2: "You will be given a concept and you will have a few seconds to sketch a drawing masterpiece that represents it. Beware that the rest of the players can hear you, do not reveal anything about your drawing or the concept you are representing. Don’t write words on your drawing.",
        title_3: "Guess",
        text_3: "When you finish your drawing, it will be sent to the rest of the players. You will receive the other drawings and will be given 4 possible answers. You will get points for every drawing you guess and when someone else guesses your drawing. ",
        draw_top: "Let’s get drawing!",
        draw_bottom: "Draw the concept: ",
        guess_top: "It’s guess o’clock!",
        guess_bottom: "What is the concept represented by this masterpiece?",
        results_drew: " drew ",
        points_guess_each: " each time a player guesses your drawing."
    },
    // FACT MATCHING
    factMatching: {
        title_1: "Welcome to It’s a match!",
        text_1: "Get to know your colleagues better by guessing their answers to certain questions. Let’s see how the game works:",
        title_2: "Answer the question        ",
        text_2: "All players will be asked a question and they need to give an honest answer. Don’t let your writing give you away. ",
        title_3: "Match the answers",
        text_3: "You will receive the other players’ answers and you will have to guess who said what. You will get points for every correct player-answer match.",
        title_match: "How well do you know your colleagues? Let’s start guessing.",
        title_results: "Let’s see who knows their colleages better. ",
        text_results: "answered",
        no_answer: "No answer",
        users: "Users"
    },

    // MINGLE TINGLE
    mingleTingle: {
        title_1: "Welcome to Chit-Chat Express.",
        text_1: "Get to know your colleagues a little better by talking about different topics. \xa0 Here's how to play.",
        title_2: "Speed dating but with your colleagues",
        text_2: "Couples will have a few seconds to talk about the topic and answer the question. \xa0 When the time is up, you will be paired with another player and will have a new topic.",
        title_3: "The last question",
        text_3: 'Back with the rest of the players, the whole group will have to answer a "Who’s more likely to..." type question.',
        title_chat: "Talk about the folowing question with your colleague.",
        title_share: "Share with the rest of the players.",
        no_answer: "No answer",
        answer: "The team's answer is: ",
        vote_title: "Vote for one of your teammates:",
    },

    // WERE WOLF
    wereWolf: {
        title_1: "Welcome to Werewolf.",
        text_1: "There is a werewolf in your village that kills a villager every night. Find out who he is before your village is desolated.",
        title_2: "Does anyone look suspicious?",
        text_2: "Each night is a round, and in each round the wolf will secretly kill a villager. The next morning, if you are still alive, you will join the rest of the villagers to try to find out who the bloodthirsty Wolf is.",
        title_3: "Catch the werewolf before it's too late",
        text_3: "The game will continue until the werewolf has eaten the whole village or until you find out who he is. With the help of the Healer and the Seer, you will have a better chance of defeating him. Don't reveal your identity if you don't want the Wolf to come after you",
        character_title: "Your character is: ",
        character_killer_title: "Werewolf",
        character_killer_text: "You are the terror of your village. In each round, you can choose a victim. Don't be too obvious and try not to get caught. Put on your best poker face. You can lie and say you are the Healer or the Seer, but be careful that your lies don't get out.",
        character_town_title: "Villager",
        character_town_text: "You are a simple villager trying not to get eaten by the Werewolf in the dead of night. Use your detective skills to find out who the Wolf is before it's too late. You can lie and say you are the Healer or the Seer, but watch out because it can be a double-edged sword.",
        character_healer_title: "Healer",
        character_healer_text: "In each round, you have the chance to blindly heal a villager. If by chance (or by divine inspiration) it matches the person killed by the werewolf that night, they will stay alive and no one will die that night. If you want, you can reveal your identity but beware: the werewolf could come for you the next night.",
        character_seer_title: "Seer",
        character_seer_text: "In each round, you have the possibility to ask the spirits who is the Wolf. The spirits only answer yes or no, so point to one of your companions and the gods will tell you if he is the Wolf or not. If you want, you can reveal your identity, but be careful: the Wolf could come for you the next night",
        action_killer: "Choose your victim.",
        action_town: "Wait for the night to be over and keep your fingers crossed to be alive.",
        action_healer: "Who do you want to protect from a possible death? That person will only be saved if the werewolf tries to kill themtonight.",
        action_seer: "Who do you think is the werewolf?",
        action_result_no_kill: "No one has died tonight. The village is in luck.",
        action_result_kill: " has been killed.",
        action_result_vident_yes: "The spirits are on your side. You have correctly pointed out the werewolf. Information is power, use it wisely",
        action_result_vident_no: "The spirits are not on your side tonight. The person you have pointed out is NOT the werewolf.",
        vote_phase_title: "Who do you think is the werewolf? Vote for one of your neighbors. The one with the most votes will be eliminated. If there is a tie, no one will be eliminated and we will move on to the next round.", vote_result_no_kill: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        vote_result_no_kill: "No one has been killed.",
        vote_result_kill: " has democratically been eliminated.",
        vote_result_killer_yes: "Congratulations villagers! You have discovered and eliminated the werewolf. The village can rest easy again without murder.",
        vote_result_killer_no: "You have eliminated one of your own. The werewolf is still on the loose and terrorizing the village.",
        //falta text per quan guanya el poble o quan guanya el llop?
        alert_dead: "Sorry, you are dead. The dead cannot vote.",
        vote: "Votes | Vote | Votes",
        dead: "Dead",
        win_killer: "The werewolf eliminated the villagers and won the game!",

    },

    // Seller Sponge
    sellerSponge: {
        title_1: "Welcome to Take my money.",
        text_1: "Try to sell a seemingly useless object to your colleagues. Use your time to think of as many uses as possible.",
        title_2: "Let the improv begin",
        text_2: "You have a limited amount of time to try to convince your colleagues to buy your product in the purest teleshopping style",
        title_3: "Vote",
        text_3: "Your colleagues will rate from 1 to 10 how much you have convinced them to buy your product.",
        seller_title: "Good luck",
        seller_vote_text: "On a scale from 0 to 10 (0 being the lowest and 10 the highest), how likely would you be to buy this product after your colleague's intervention? .",
        seller_helper_text: "Find different ways to sell this product.",
        player_offline: "This user is offline.",
    },

    // The Mole.
    topo: {
        title_1: "Welcome to The Mole.",
        text_1: "All players (except the mole) are told a secret word. In each round, each player must write a word related to the secret word. If you are too obvious, the mole will discover the secret word and win the game. If you stray too far from the word, the other players will think you are the mole and eliminate you.",
        title_2: "Write a word related to the secret word",
        text_2: "The mole also has to write a word related to the secret word. He will use the words provided by the other players to try to get close to the secret word and not be discovered.",
        title_3: "Who could be the mole?",
        text_3: "After seeing all the answers, all players agree to eliminate who they think is the mole. If they eliminate a player who is not the mole, they do another round with the same secret word. Until the mole is discovered or the mole guesses the secret word. If the mole is discovered, he has one chance to say what he thinks the secret word is. If he guesses it, he is the winner of the game.",
        rol_text: "The secret word is: ",
        rol_text_topo: "You are the mole.",
        topo_hit: "You are the mole. Try to get close to the secret word based on the words of the rest of the players. Good luck.",
        no_topo_hit: "You are not the mole. Don't be too obvious with your words and don't stray too far either.",
        waiting_to_start: "It's almost time to begin.",
        turn: "It's this player's turn: ",
        word: "Related word:",
        input_word: "Try to get closer to the secret word guided by the words of your partners:",
        player_turn: "It's your turn",

        vote_title: "Vote for the player who looks most like the mole.",
        vote_title_out: "You are eliminated, wait for the rest of the players to vote.",

        topo_quiz__topo_title: "Whoops, you've been caught.... Since you're the mole, now you can try to guess the secret word.",
        topo_quiz_title: "Waiting for the mole to guess the secret word.",

        result_player_dead: " has been eliminated. ",
        result_no_topo: "But they were not a mole...",
        result_topo_no_win: "The mole hasn't been able to guess the secret word.",
        result_topo_count: "Moles left: ",
        result_draw: "There has been a tie. No one dies, let's play one more round.",

        final_result_topo_guess: "The mole guessed the secret word. The moles are the winners!",
        final_result_topo_kills: "All the moles have been eliminated. The players have won!",
        final_result_player_dead: "Almost all players have been eliminated.</br>The moles <span class='text-primary-100'>{topos}</span> are the winners! | Almost all players have been eliminated. The mole <span class='text-primary-100'>{topos}</span> is the winner!",


    },

    // quiz time, know-it-all, smarty pants trivia master?
    quiz: {
        title_1: "Welcome to Trivia.",
        text_1: "Answer the maximum number of questions correctly on a specific topic.",
        title_2: "+50 points",
        text_2: "For each correct answer you will get 50 points.",
        title_3: "Final",
        text_3: "The player with the most points at the end of the game will be the winner.",
        corrent_answ: "The correct answer was: "
    },

    thisOrThat: {
        title_1: "Welcome to Read My Mind.",
        text_1: "One player will be selected to answer 6 personal questions (6 rounds). The rest of the players will have to guess the selected player's answers.",
        title_2: "Read the selected player's mind.",
        text_2: "If you get the answer right, you will move on to the next round. If you miss, you will be eliminated from the game. Don't give the selected player any clues on wheter you are eliminated or still pllaying.",
        title_3: "Final Round",
        text_3: "When the 6 rounds are over, all players are eliminated or only one player is left, the selected player can try to guess who has made it to the end in order to claim the victory.",
        main_responding: "Player <span class='text-primary-100'>{main_player}</span> is responding",
        main_response: "Player <span class='text-primary-100'>{main_player}</span> response <span class='text-primary-100'>{response}</span>",
        choose: "Choose one of the two options",
        main_guess: "Player <span class='text-primary-100'>{main_player}</span> guess who's left standing",
        winner: "The winner is: | The winners are:",
        main_wins_no_alive_players: "No player has managed to survive, and therefore the main player is the winner of the game. Congratulations!",
        main_wins_guessing: "The main player has guessed which player or players have reached the final and is therefore the winner of the game. Congratulations!",
        player_win: "The main player has not been able to guess which player or players have reached the final and therefore the players who have reached the final are the winners. Congratulations!",
    }





}



module.exports = {
    en
}
