import { db } from '../../firebase'
import { ref, off, onValue } from "firebase/database";

const url = window.location.pathname


const thisOrThatData = {
    "main_player": "",
    "regular_players": [],
    "alive_players": [],
    "questions": [],
    "question": "",
    "round_eliminated_players": [],
    "main_player_response": "",
    "players_responses": [],
    "responses_1": [],
    "responses_2": [],
    "winner": [],
}

const thisOrThatMutations = {
    thisOrThatSetGameData(state, data) {
        console.log("data")
        console.log(data)
        state.thisOrThatData.main_player = data.data.main_player;
        state.thisOrThatData.regular_players = data.data.regular_players;
        state.thisOrThatData.alive_players = data.data.alive_players;
        state.thisOrThatData.questions = data.data.questions;
        state.thisOrThatData.question = data.data.question;
        state.thisOrThatData.round_eliminated_players = data.data.round_eliminated_players;
        state.thisOrThatData.main_player_response = data.data.main_player_response;
        state.thisOrThatData.players_responses = data.data.players_responses;
        state.thisOrThatData.winner = data.data.winner
        console.log(state.thisOrThatData)
    },
    thisOrThatSetGameData2(state, data) {

    },
    thisOrThatSetAnswer(state, data) {
        state.thisOrThatData.players_responses = data;

        console.log("thisOrThatSetAnswer")
        console.log(data)

        let responses_1 = []
        let responses_2 = []

        if (data !== null) {
            if (typeof data === 'object') {
                Object.keys(data).map((element) => {
                    if (element !== state.thisOrThatData.main_player) {

                        if (data[element].response === state.thisOrThatData.question.responses[0]) {
                            responses_1.push(element);
                        }
                        else {
                            responses_2.push(element);
                        }
                    }
                });
            }
        }

        state.thisOrThatData.responses_1 = responses_1;
        state.thisOrThatData.responses_2 = responses_2;
    }

}

const thisOrThatActions = {

}

const thisOrThatStartGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    onValue(teamDataDB, (data) => {
        commit("thisOrThatSetAnswer", data.val())
    });

}
const thisOrThatFinishGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    off(teamDataDB)
}


const thisOrThatGameDataAdmin = ({ commit, state, rootState }, data) => {
    commit("thisOrThatSetGameData", {
        data: data.val(),
        idPlayer: rootState.storeMedia.idPlayer
    })
}

const thisOrThatGameDataAdmin2 = ({ commit, state, rootState }, data) => {
    commit("thisOrThatSetGameData2",
        data.val(),
    )
}

const thisOrThatGameDataResults = ({ commit, state, rootState }, data) => {
    commit("thisOrThatSetGameResults", data.val())
}

const thisOrThatDataScret = ({ commit, state, rootState }, data) => {
}

export {
    thisOrThatGameDataAdmin,
    thisOrThatGameDataAdmin2,
    thisOrThatGameDataResults,
    thisOrThatData,
    thisOrThatMutations,
    thisOrThatActions,
    thisOrThatStartGame,
    thisOrThatFinishGame,
    thisOrThatDataScret,
}