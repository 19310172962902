<template>
    <div class="flex flex-col gap-3 p-5">
        <h4 class="text-center">
            {{$t('auth.login')}}
        </h4>
        <p v-if="textLogin">
            {{$t('auth.text_need_login')}}
        </p>
        <input
            type="text"
            class="py-3 px-4  rounded-md block background-input w-full"
            placeholder="Email"
            v-model ="loginForm.email"
        />
        <input
            type="password"
            class="py-3 px-4  rounded-md block mt-4 background-input w-full"
            placeholder="Password"
            v-model ="loginForm.password"
        />
        <BtnBase :text="$t('auth.login')" v-on:click="login()" :disabled="$store.state.storeAuth.loginLoading"/>
        <a
            :href="urlGoogle"
            class="w-full"
        >
            <button
                type="button"
                class="login-with-google-btn py-3 px-4 w-full  mt-3 xl:mt-0 align-top"
            >
                 {{$t('auth.login_google')}}
            </button>
        </a>
        <a href="https://app.remoteboost.io/register" class="w-full" target="_blank">
            <BtnBase :text="$t('auth.signup')" :disabled="$store.state.storeAuth.loginLoading" class="w-full" classBtn="btn-outline"/>
        </a>
    </div>
</template>

<script>
import BtnBase from '@/components/buttons/BtnBase.vue'
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import { notify } from "notiwind";
import { useI18n } from 'vue-i18n';

export default {
    components:{
        BtnBase
    },
    props:{
        textLogin:{ default:false,type:String}
    },
    setup(){
        const store = useStore();
        const {t } = useI18n();
        const urlGoogle = process.env.VUE_APP_GOOGLE_URL
        const loginForm = ref({
            email:"",
            password:""
        })
        const login = ()=>{
            console.log(loginForm.value.email)
            if(loginForm.value.email == "")
            {
                notify(
                    {
                        group: "bottom",
                        title:"you must enter a email",
                        text: "",
                    },
                    2000
                );
                return
            }
            if(loginForm.value.password == "")
            {
                notify(
                    {
                        group: "bottom",
                        title:"you must enter a password",
                        text: "",
                    },
                    2000
                );
                return
            }
            store.dispatch('login',{
                email:loginForm.value.email,
                password:loginForm.value.password,
            })
        }

        return {
            urlGoogle,
            loginForm,
            login,
        }
    }

};
</script>

<style lang="scss" scoped>
.login-with-google-btn {
    transition: background-color 0.3s, box-shadow 0.3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 1);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;

    &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    }

    &:active {
        background-color: #eeeeee;
    }

    &:focus {
        outline: none;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
            0 0 0 3px #c8dafc;
    }

    &:disabled {
        filter: grayscale(100%);
        background-color: #ebebeb;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
    }
}
</style>