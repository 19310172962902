<template>

    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        appear
    >
        <div
            v-if="status == true"
            class="modal"
        >
            <transition
                enter-active-class="animate__animated animate__fadeInDown animate__delay-1s"
                appear
            >
            
                <div
                    v-if="status == true"
                    class="modal-content"
                >
                <div class="modal-header">
                        <inline-svg
                            @click="$store.commit('setModalAuth',false)"
                            :src="require('@/assets/svg/close.svg')"
                        />
                </div>
                 <FormAuth/>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import InputPrimary from "@/components/inputs/InputPrimary.vue";
import BtnBase from "@/components/buttons/BtnBase";
import FormAuth from '../auth/FormAuth.vue'
export default {
    name: "ModalWelcome",

    components: {
        InlineSvg,
        BtnBase,
        InputPrimary,
        FormAuth,
    },

    props: {
        status: Boolean,
    },

    setup() {
        return {
        };
    },
};
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 3000;

    background: rgba(color(bg, 140), 0.5);

    &-content {
        max-width: 50%;
        padding: 16px 16px 32px;

        background: #ffffff;
        box-shadow: 0px 10px 24px #3c3c3c;
        border-radius: 12px;
    }

    &-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 8px;

        svg {
            height: 16px;
            widows: 16px;
            cursor: pointer;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text_wrap {
            display: flex;
            align-items: center;
        }

        :deep(svg) {
            height: 56px;
            width: 56px;
            margin: 0 8px 0 0;

            path,
            rect {
                fill: color(primary);
            }
        }

        :deep(input) {
            width: 100%;
            margin: 16px 0 0 0;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        margin: 16px 0 0;
    }



}
</style>