<template>
    <div v-if="$store.state.storeMedia.idPlayer == $store.state.storeGameData.topoData.topoDeadPlayer" class="p-5 flex flex-col gap-5">
        <h4 class="text-center">
            {{$t('topo.topo_quiz__topo_title')}}
        </h4>
        <Quiz
            :questions="$store.state.storeGameData.topoData.topoQuiz"
            :dataUrl="'/gameDataUser/' + $store.state.storeMedia.idPlayer"
            parameterDB="guess"
        />
    </div>
    <div v-else >
        <h4 class="text-center">
            {{$t('topo.topo_quiz_title')}}
        </h4>
    </div>
</template>


<script>
import Quiz from '@/components/gameWidgets/Quiz.vue'

export default {
    components:{
        Quiz
    },
    setup(props) {
        
        return {
       
        };
    }
};
</script>

<style lang="scss" scoped>

</style>