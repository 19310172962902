<template>
    <div class="flex flex-col gap-5 items-center justify-center w-full" v-if="$store.state.storeGameData.topoData.topoDeadPlayer != 'None'">
        <h4 class="text-center">
            {{$store.getters.getUserByIdName($store.state.storeGameData.topoData.topoDeadPlayer)}} {{$t('topo.result_player_dead')}}
        </h4>
        <h4
            v-if="$store.state.storeGameData.topoData.topoDead"
            class="text-center">
            {{$t('topo.result_topo_no_win')}} 
        </h4>
        <h4
            v-else
            class="text-center">
            {{$t('topo.result_no_topo')}} 
        </h4>
         <h4 class="text-center">
            {{$t('topo.result_topo_count')}} {{($store.state.storeGameData.topoData.topoCount)}}
        </h4>
        <img
            v-if="$store.state.storeGameData.topoData.topoDead"
            :src="require('@/assets/games/topo/Dead Mole.svg')" alt=""
            class="w-2/4 ">
        <img
            v-else
            :src="require('@/assets/games/topo/No Moles Dead.svg')" alt=""
            class="w-2/4 ">

    </div>
    <div v-else class="flex flex-col justify-center items-center w-full">
        <h4 class="text-center w-2/4">
        {{$t('topo.result_draw')}}

        </h4>
        <img
           
            :src="require('@/assets/games/topo/No Moles Dead.svg')" alt=""
            class="w-2/4 ">
    </div>
       

</template>


<script>
import Quiz from '@/components/gameWidgets/Quiz.vue'

export default {
    components:{
        Quiz
    },
    setup(props) {
        
        return {
       
        };
    }
};
</script>

<style lang="scss" scoped>

</style>