<template>
  <div class="card card-speakers" :class="card_name">
    <inline-svg :src="require('@/assets/svg/volume-low.svg')" />
    
    <audio class="player song">
      <source :src="require('@/assets/music/test.mp3')" type="audio/mpeg" />Your browser does not support the audio tag.
    </audio>
    <BtnBase v-if="play == 0" text="Reproduir so de prova" classBtn="btn-primary btn-xs" v-on:click="play_stop" />
    <BtnBase v-if="play == 1" text="Para el so de prova" classBtn="btn-primary btn-xs" v-on:click="play_stop" />

  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import BtnBase from "@/components/buttons/BtnBase";
import { ref } from 'vue'
export default {
    name: "CardWelcome",

    components: {
        InlineSvg,
        BtnBase
    },

    props: {
        ico: String,
        title: String,
        text: String,
        card_name: String,
        media: Object
    },

    setup(props)
    {

        const play = ref(0)
        var audio = new Audio(require('@/assets/music/test.mp3'))

        function play_stop()
        {
            if(play.value == 0)
            {
                play.value = 1
                audio.play()
            }
            else
            {
                audio.pause()
                play.value = 0
            }
        }

        return{
            play_stop,
            play
        }
    }


};
</script>

<style lang="scss" scoped>
.card.card-speakers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;

  text-align: center;
  border: 2px solid color(border);

  select {
    width: 100%;
    margin-top: 32px;
  }

  .btn {
    margin-top: 32px;
  }
}
</style>