<template>
    <div class="flex flex-col gap-5 mt-5" v-if="$store.state.storeGameData.topoData.alive">
        <h4 class="text-center">
            {{ $t("topo.vote_title") }}
        </h4>
        <div class="flex flex-row flex-wrap gap-5 justify-center">
            <div
                v-for="player in $store.state.storeGameData.topoData.alivePlayers"
                :key="player"
                class="userWarap flex flex-col items-center"
            >
                <CardUserImgVue
                    :track="$store.getters.getMediaById(player)"
                    v-on:click="updateAnsw(player)"
                    :class="selected == player ? 'selected' : ''"
                />
                <h4 class=" text-primary-100 text-center m-2 font-bold">
                    {{ $store.state.storeGameData.topoData.words[player] }}
                </h4>
            </div>
        </div>
    </div>
    <div v-else>
        <h4>
            {{ $t("topo.vote_title_out") }}
        </h4>
    </div>
</template>

<script>
const url = window.location.pathname;

import CardUserImgVue from "@/components/cards/CardUserImg.vue";
import { ref as vref, onMounted, watch } from "vue";
import { db } from "@/firebase/index";
import { ref, off, onValue, update } from "firebase/database";
import { useStore } from "vuex";

export default {
    components: {
        CardUserImgVue,
    },

    setup(props) {
        const store = useStore();
        const id = store.state.storeMedia.idPlayer;
        let lastCallback = null;
        const selected = vref({
            selected: "",
        });

        watch(
            () => props.dataUrl,
            () => {
                off(lastCallback);
                const dataBasePlayerUrl = url + "/gameDataUser/" + id;
                const teamDataDB = ref(db, dataBasePlayerUrl);
                onValue(teamDataDB, (snapshot) => {
                    updateDataBase(snapshot);
                });
                lastCallback = teamDataDB;
            }
        );

        onMounted(() => {
            const dataBasePlayerUrl = url + "/gameDataUser/" + id;
            const teamDataDB = ref(db, dataBasePlayerUrl);
            onValue(teamDataDB, (snapshot) => {
                updateDataBase(snapshot);
            });
            lastCallback = teamDataDB;
        });

        const updateAnsw = (text) => {
            const dataBasePlayerUrl = url + "/gameDataUser/" + id;
            const teamDataDB = ref(db, dataBasePlayerUrl);
            if (text == selected.value) {
                text = null;
            }
            update(teamDataDB, {
                vote: text,
            });
        };

        function updateDataBase(snapshot) {
            const data = snapshot.val();
            if (data) {
                selected.value = data.vote;
            } else {
                selected.value = null;
            }
        }

        return {
            selected,
            updateAnsw,
        };
    },
};
</script>

<style lang="scss" scoped>
.text-size {
    font-size: 1.5vh;
}
.userWarap {
    width: 17vw;
    height: 17vw;
    :deep(.card_user) {
        width: 15vw;
        height: 15vw;
    }

    // height: 80px+ 10px;;
}

.selected {
    :deep(.card_user) {
        border: 5px solid #ff7033;
    }
}
</style>
