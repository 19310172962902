<template>
    <div class="flex flex-row media-control gap-5" :class="menuState ? 'media-border' : 'trans-border'">
        <BtnIco
            v-if="menuState"
            :toolTipTop="true"
            ico="cameraOff"
            :toolTip="
                $store.state.storeMedia.localStream.video
                    ? $t('bottomvar.toolTip_camera_icon_off')
                    : $t('bottomvar.toolTip_camera_icon_on')
            "
            v-on:click="$store.dispatch('stopVideoPlayer')"
            :activeIcon="!$store.state.storeMedia.localStream.video"
        />
        <BtnIco
            v-if="menuState"
            :toolTipTop="true"
            ico="microphone-slash-solid"
            :toolTip="
                !$store.state.storeMedia.localStream.muted
                    ? $t('bottomvar.toolTip_micro_off')
                    : $t('bottomvar.toolTip_micro_on')
            "
            v-on:click="$store.dispatch('mutedPlayer')"
            :activeIcon="$store.state.storeMedia.localStream.muted"
        />
        <BtnIco
            v-if="menuState"
            :toolTipTop="true"
            ico="eye-low-vision-solid"
            :toolTip="
                $store.state.storeMedia.slowMode
                    ? $t('bottomvar.toolTip_slow_mode_off')
                    : $t('bottomvar.toolTip_slow_mode_on')
            "
            @click="$store.commit('setSlowMode')"
            :activeIcon="$store.state.storeMedia.slowMode"
        />
        <BtnIco
            v-if="menuState"
            :toolTipTop="true"
            ico="gear-solid"
            :toolTip="
                !$store.state.storeMedia.mediaOptionsModal
                    ? $t('bottomvar.toolTip_options_on')
                    : $t('bottomvar.toolTip_options_off')
            "
            v-on:click="$store.commit('setmediaOptionsModal')"
            :activeIcon="$store.state.storeMedia.mediaOptionsModal"
        />
        <BtnIco
            v-if="
                $store.state.storeMedia.localStream.admin &&
                    $store.state.storeGameState.gameState == 'lobby' &&
                    menuState
            "
            :toolTipTop="true"
            ico="desktop-solid"
            :toolTip="menuState ? $t('bottomvar.toolTip_share_screen_on') : $t('bottomvar.toolTip_share_screen_off')"
            v-on:click="$store.dispatch('shareScreen')"
            :activeIcon="$store.state.storeMedia.screenShare"
        />
        <BtnIco
            v-if="menuState"
            :toolTipTop="true"
            ico="comment-alt-regular"
            :toolTip="$store.state.storeMedia.chat.active ? $t('chat.tooltip_close') : $t('chat.tooltip_open')"
            v-on:click="$store.commit('setChatStatus')"
            :activeIcon="$store.state.storeMedia.chat.active"
        />
        <BtnIco
            :toolTipTop="true"
            ico="ellipsis-vertical-solid"
            :toolTip="menuState ? $t('bottomvar.toolTip_menu_off') : $t('bottomvar.toolTip_menu_on')"
            v-on:click="toggleMenuState()"
            :activeIcon="menuState"
        />
    </div>
</template>

<script>
import BtnIco from "@/components/buttons/BtnIco.vue";
import { ref } from "@vue/reactivity";

export default {
    components: {
        BtnIco,
    },

    setup() {
        const menuState = ref(true);

        const toggleMenuState = () => {
            menuState.value = !menuState.value;
            console.log(menuState.value);
        };
        return {
            menuState,
            toggleMenuState,
        };
    },
};
</script>

<style lang="scss" scoped>
.media-control {
    z-index: 999999999999;
    background: white;
    padding: 10px;
    position: fixed;
    bottom: 20px;
    right: 28px;

    border-radius: 12px;
}
.media-border {
    color: white;
    border: 2px solid #ff7033;
}
.trans-border {
    border: 2px solid transparent;
}
</style>
