<template>
    <div class="flex flex-col w-full h-full items-center justify-center p-10 gap-10">
        <h3
            v-html="
                $t('thisOrThat.main_guess', {
                    main_player: $store.getters.getUserById($store.state.storeGameData.thisOrThatData.main_player)
                        .playerName,
                })
            "
        />
        <div class="userWrap flex flex-row flex-auto w-full items-center justify-center flex-wrap gap-10">
            <div v-for="player in $store.state.storeGameData.thisOrThatData.regular_players" v-bind:key="player">
                <CardUserImgVue
                    :track="$store.getters.getMediaById(player)"
                    @click="updateMainSelected(player)"
                    :class="mainSelected.find((selected) => selected === player) ? 'selected' : ''"
                    v-if="$store.state.storeGameData.thisOrThatData.main_player == $store.state.storeMedia.idPlayer"
                />
                <CardUserImgVue :track="$store.getters.getMediaById(player)" v-else />
            </div>
        </div>
    </div>
</template>

<script>
import { ref as vref, onMounted } from "vue";
import { ref, update, onValue } from "firebase/database";
import { db } from "../../../firebase";
import { useStore } from "vuex";
import CardUserImgVue from "@/components/cards/CardUserImg.vue";
export default {
    components: {
        CardUserImgVue,
    },
    setup() {
        const url = window.location.pathname;
        const dataBasePlayerUrl = url + "/gameDataUser/";
        const teamDataDB = ref(db, dataBasePlayerUrl);
        const mainSelected = vref([]);

        onMounted(() => {
            onValue(teamDataDB, (snapshot) => {
                const data = snapshot.val();
                console.log(data);
                if (data.main_selected_players) {
                    mainSelected.value = data.main_selected_players;
                }
            });
        });

        const updateMainSelected = (player) => {
            let index = null;
            index = mainSelected.value.findIndex((selected) => selected === player);
            if (index === -1) {
                mainSelected.value.push(player);
            } else {
                mainSelected.value.splice(index, 1);
            }

            update(teamDataDB, { main_selected_players: mainSelected.value });
        };

        return { mainSelected, updateMainSelected };
    },
};
</script>

<style lang="scss" scoped>
.userWrap {
    width: 80px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}
.selected {
    :deep(.card_user) {
        border: 5px solid #ff7033;
    }
}
</style>
