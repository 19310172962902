<template>
    <TutorialLayout
        :content="[
            {
                title: $t('mingleTingle.title_1'),
                text: $t('mingleTingle.text_1'),
            },
            {
                title: $t('mingleTingle.title_2'),
                text: $t('mingleTingle.text_2'),
            },
            {
                title: $t('mingleTingle.title_3'),
                text: $t('mingleTingle.text_3'),
            },
        ]"
    >
    </TutorialLayout>
</template>

<script>
import TutorialLayout from "@/components/tutorial/TutorialLayout.vue";

export default {
    components: {
        TutorialLayout,
    },

    setup() {},
};
</script>

<style scoped lang="scss"></style>
