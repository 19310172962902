<template>
    <!-- Tutorial -->
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 0">
        <Tutorial />
    </GameContainer>

    <!-- Draw Phase -->

    <div class="row" v-else-if="$store.state.storeGameState.gameStep == 1">
        <GameTools />
        <GameContainer :timer="true" :countdown="true">
            <DrawGame />
            <template v-slot:bottom>
                <DrawBottom />
            </template>
        </GameContainer>
    </div>

    <!-- Guess Phase -->
    <div class="row" v-else-if="$store.state.storeGameState.gameStep == 2">
        <GameContainer :timer="true" :countdown="true">
            <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                appear
                mode="out-in"
            >
                <div :key="$store.state.storeGameData.pictionaryData.currentGuess" class="column gap-5">
                    <h4>
                        {{ $t("pictionary.guess_top") }}
                    </h4>
                    <ShowDraw />
                    <p class="text-lg">
                        {{ $t("pictionary.guess_bottom") }}
                    </p>
                    <Quiz
                        :dataUrl="dataUrl + currentGuess"
                        :questions="$store.state.storeGameData.pictionaryData.quiz"
                    />
                </div>
            </transition>
        </GameContainer>
    </div>
    <!-- Rank Phase -->
    <div class="row" v-else-if="$store.state.storeGameState.gameStep == 3">
        <GameContainer :timer="true" :countdown="true">
            <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                appear
                mode="out-in"
            >
                <div :key="$store.state.storeGameData.pictionaryData.playerResultId" class="column">
                    <h4 class="m-2 ">
                        {{ $store.getters.getUserByIdName($store.state.storeGameData.pictionaryData.playerResultId) }}
                        {{ $t("pictionary.results_drew") }}
                        <span class="text-primary-100">
                            {{ $store.state.storeGameData.pictionaryData.wordResult }}
                        </span>
                    </h4>
                    <div class="flex flex-row justify-center items-center gap-4 w-full">
                        <div class="w-4/12">
                            <div class="userWarap m-auto">
                                <CardUser
                                    :track="
                                        $store.getters.getUserById(
                                            $store.state.storeGameData.pictionaryData.playerResultId
                                        )
                                    "
                                    v-if="
                                        $store.getters.getUserById(
                                            $store.state.storeGameData.pictionaryData.playerResultId
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="column w-8/12">
                            <ShowDraw :scale="0.7" />
                        </div>
                    </div>
                    <h4 class="m-4">+50 {{ $t("game.points") }} {{ $t("pictionary.points_guess_each") }}</h4>

                    <PhaseRanking
                        :winners="$store.state.storeGameData.pictionaryData.winners"
                        :losers="$store.state.storeGameData.pictionaryData.losers"
                        :pointsPlayers="100"
                        :pointsMainPlayer="50"
                    />
                </div>
            </transition>
        </GameContainer>
    </div>
    <!-- FINAL RANKING -->
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 4">
        <FinalRanking />
    </GameContainer>
    <GameContainer v-if="$store.state.storeGameState.gameStep == 5">
        <FinalFeedback />
    </GameContainer>
</template>

<script>
import GameTools from "@/components/navbars/GameTools.vue";
import DrawGame from "./DrawGame.vue";
import Tutorial from "./Tutorial.vue";
import ShowDraw from "./ShowDraw.vue";
import DrawBottom from "./DrawBottom.vue";
import Timer from "@/components/gameWidgets/Timer";
import GameRound from "@/components/gameWidgets/GameRound";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import Quiz from "@/components/gameWidgets/Quiz";
import { useStore } from "vuex";
import { computed, onMounted, watch, ref } from "vue";
import CardUser from "@/components/cards/CardUser.vue";
import PhaseRanking from "@/components/gameWidgets/PhaseRanking.vue";
import FinalRanking from "@/components/gameWidgets/FinalRanking.vue";
import FinalFeedback from "../FinalFeedback.vue";

export default {
    components: {
        GameTools,
        DrawGame,
        Tutorial,
        ShowDraw,
        Timer,
        Quiz,
        CardUser,
        GameRound,
        GameContainer,
        DrawBottom,
        PhaseRanking,
        FinalRanking,
        FinalFeedback,
    },

    setup() {
        const store = useStore();

        const idPlayer = computed(() => store.state.storeMedia.idPlayer);
        const currentGuess = computed(() => store.state.storeGameData.pictionaryData.currentGuess);

        const dataUrl = ref("/gameDataUser/" + idPlayer.value + "/guess/");
        return {
            currentGuess,
            dataUrl,
        };
    },
};
</script>

<style lang="scss" scoped>
.row {
    flex: 1 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
}

.fullrow {
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.column-2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 10px;
    width: 50%;
}

.row2x2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.rowCenter {
    align-items: stretch;
    justify-content: center;
}

.userWarap {
    width: 160px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}

.userWarap-ranking {
    width: 160px;
    :deep(.card_user) {
        width: 80px + 10px;
        height: 80px + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
