<template>
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        appear
        mode="out-in"
    >

        <div
            class="flex flex-col gap-5 p-5"
            :key="$store.state.storeGameData.sellerSpongeData.currentSeller"
        >
            <!-- <h4 class="text-center">
                {{$store.state.storeGameData.sellerSpongeData.currentSeller}}
            </h4> -->
            <h4 class="text-center" >
                
                {{$store.state.storeGameData.sellerSpongeData.sellerInfo.question}}
                <!-- {{$store.state.storeGameData.sellerSpongeData.sellerInfo.name}} -->
            </h4>
            <div class="flex flex-row justify-center gap-10">
                <div class="flex userWarap" v-if="$store.getters.getMediaById($store.state.storeGameData.sellerSpongeData.currentSeller)">
                    <CardUser :track="$store.getters.getMediaById($store.state.storeGameData.sellerSpongeData.currentSeller)" />
                </div>
                <div v-else class="flex flex-col  justify-center items-center">
                    <h4 class="text-center">
                        Este usuario esta desconetado
                    </h4>
                </div>
                <div class=" my-auto flex userWarap ">
                    <img
                        class="img-border"
                        v-lazy="$store.state.storeGameData.sellerSpongeData.sellerInfo.url"
                        alt=""
                    >
                </div>
            </div>

            <div v-if="$store.state.storeMedia.idPlayer != $store.state.storeGameData.sellerSpongeData.currentSeller">
                <h4 class="text-center">
                    {{$t('sellerSponge.seller_vote_text')}}
                </h4>
                <QuestionSlider
                    :dataUrl="'/gameDataUser/' + $store.state.storeMedia.idPlayer "
                    :parameterDB="$store.state.storeGameData.sellerSpongeData.currentSeller"
                    :currentAnswer="$store.state.storeGameData.sellerSpongeData.currentVote"
                />
            </div>
            <div v-else>
                <p class="text-lg text-center">
                    {{$t('sellerSponge.seller_helper_text')}}
                </p>
            </div>
        </div>
    </transition>
</template>

<script>
import CardUser from "@/components/cards/CardUser.vue";
import QuestionSlider from "@/components/gameWidgets/QuestionSlider.vue";

export default {
    components: {
        CardUser,
        QuestionSlider,
    },
};
</script>

<style lang="scss" scoped>
.userWarap {
    width: 25vw;
    height: 25vw;
    :deep(.card_user) {
        width: 25vw;
        height: 25vw;
    }

    // height: 80px+ 10px;;
}

.userWarap-ranking {
    width: 160px;
    :deep(.card_user) {
        width: 80px + 10px;
        height: 80px + 10px;
    }

    // height: 80px+ 10px;;
}

.img-border {
    border: 5px solid #ff7033;
    border-radius: 12px;
    width: 100%;
    object-fit: cover;
}
</style>