<template>
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        appear
    >
        <div v-if="status" class="modal h-3/4">
            <div class=" flex-col flex h-full pl-2 pr-2 overflow-auto chat-box" ref="chat_box">
                <div v-for="message in $store.state.storeMedia.chat.messages" :key="message">
                    <div
                        class="flex flex-col w-full mb-2 "
                        :class="message.idPlayer !== $store.state.storeMedia.idPlayer ? 'text-left' : 'text-right'"
                    >
                        <p class="text-primary-100" v-if="message.changePlayer">
                            {{
                                $store.getters.getUserByIdName(message.idPlayer) +
                                    " - " +
                                    +(10 > new Date(message.timestamp).getHours()
                                        ? new Date(message.timestamp).getHours()
                                        : new Date(message.timestamp).getHours()) +
                                    ":" +
                                    (10 > new Date(message.timestamp).getMinutes()
                                        ? "0" + new Date(message.timestamp).getMinutes()
                                        : new Date(
                                              message.timestamp
                                          ).getMinutes()) /*+
                                                " - " +
                                                new Date(message.timestamp).toLocaleDateString(
                                                    $t("general.date_format"),
                                                    {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                    }
                                                )*/
                            }}
                        </p>
                        <p>{{ message.message }}</p>
                    </div>
                </div>
                <div class="mt-6">&nbsp;</div>
            </div>
            <div class="flex flex-row absolute h-2/12 msg-box">
                <input
                    class="textarea w-10/12 h-full"
                    id="chatInputMessage"
                    type="text"
                    v-model="inputMessage"
                    :placeholder="$t('chat.input_placeholder')"
                    @keyup.enter="sendMessage"
                    ref="chatInputMessage"
                />
                <button class="custombtn w-2/12" @click="sendMessage">
                    <inline-svg
                        :src="require('@/assets/svg/paper-plane-solid.svg')"
                        :fill="inputMessage ? '#FFA680' : '#85868E'"
                        class="p-2"
                    />
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import BtnIco from "@/components/buttons/BtnIco.vue";
import InlineSvg from "vue-inline-svg";
import { post } from "axios";
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
    components: { BtnIco, InlineSvg },

    props: { status: { type: Boolean, default: false } },

    setup(props) {
        const inputMessage = ref("");
        const store = useStore();
        const { t } = useI18n();
        const chat_box = ref(null);
        const chatInputMessage = ref(null);

        watch(
            () => chat_box.value,
            () => {
                console.log("in");
                if (props.status == true && chat_box.value) {
                    chat_box.value.scrollTo({ top: chat_box.value.scrollHeight });
                    chatInputMessage.value.focus();
                }
            }
        );

        const sendMessage = () => {
            if (inputMessage.value) {
                post(process.env.VUE_APP_CLOUD_FUNCTIONS_URL + "chat/message", {
                    message: inputMessage.value,
                    idPlayer: store.state.storeMedia.idPlayer,
                    idGame: window.location.pathname,
                }).then((response) => {
                    console.log(response);
                    inputMessage.value = "";
                });
            }
        };

        return { sendMessage, inputMessage, chat_box, chatInputMessage };
    },
};
</script>

<style lang="scss" scoped>
.modal {
    z-index: 1000;
    background: white;
    padding: 10px;
    position: fixed;
    bottom: 80px;
    right: 12px;
    width: 352px;

    border-radius: 12px;
    border: 2px solid #ff7033;
}

.msg-box {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    z-index: 99;
    border-radius: 12px;
    background: #fafafa;
}
.chat-box {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
.chat-box::-webkit-scrollbar {
    width: 8px;
}

.chat-box::-webkit-scrollbar-track {
    background: white;
}

.chat-box::-webkit-scrollbar-thumb {
    border-radius: 20px;
    @apply bg-primary-100;
}

input.textarea {
    border-radius: 12px;
    background: #fafafa;
    border: none;
    outline: none;
    padding-left: 20px;
    padding-right: 20px;
    color: #666;
    font-weight: 400;
}

.custombtn {
    border-radius: 12px;
    background: #fafafa;
    border: none;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
    color: #666;
    font-weight: 400;
}
</style>
