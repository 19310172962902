<template>
    <div class="flex flex-col text-center gap-10">
        <div
            class="deadcard p-5 flex flex-col gap-5 mx-auto"
            v-if="$store.state.storeGameData.wereWolfData.deadPlayer != 'None'"
        >
            <div class="flex flex-row w-full">
                <div class="userWarap ">
                    <CardUserVue
                        :track="$store.getters.getMediaById($store.state.storeGameData.wereWolfData.deadPlayer)"
                    />
                </div>

                <img
                    :src="require('@/assets/games/wereWolf/Villager Wins.svg')"
                    alt=""
                    class="userWarap"
                    v-if="$store.state.storeGameData.wereWolfData.winner == 'town'"
                />

                <img :src="require('@/assets/games/wereWolf/Someone Died.svg')" alt="" class="userWarap" v-else />
            </div>
            <h4>
                {{ $store.getters.getUserByIdName($store.state.storeGameData.wereWolfData.deadPlayer) }}
                {{ $t("wereWolf.vote_result_kill") }}
            </h4>
        </div>
        <div class="deadcard p-5 flex flex-col gap-5" v-else>
            <h4>
                {{ $t("wereWolf.vote_result_no_kill") }}
            </h4>
        </div>
        <template
            v-if="
                !$store.state.storeGameData.wereWolfData.winner &&
                    $store.state.storeGameData.wereWolfData.deadPlayer != 'None'
            "
        >
            <div>
                <h4 class="mb-6">
                    {{ $t("wereWolf.vote_result_killer_no") }}
                </h4>
            </div>
        </template>
        <template v-else-if="$store.state.storeGameData.wereWolfData.winner">
            <div>
                <div v-if="$store.state.storeGameData.wereWolfData.winner == 'town'">
                    <h4 class="h4PrimaryColor">
                        {{ $t("wereWolf.vote_result_killer_yes") }}
                    </h4>
                </div>
                <div v-else>
                    <div class="grid grid-cols-2">
                        <div class="userWarap ">
                            <CardUserVue
                                :track="$store.getters.getMediaById($store.state.storeGameData.wereWolfData.killerId)"
                            />
                        </div>
                        <img :src="require('@/assets/games/wereWolf/Werewolf Wins.svg')" alt="" class="userWarap" />
                    </div>

                    <h4 class="h4PrimaryColor">
                        {{ $t("wereWolf.win_killer") }}
                    </h4>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CardUserVue from "@/components/cards/CardUser.vue";
export default {
    components: {
        CardUserVue,
    },
};
</script>

<style lang="scss" scoped>
.userWarap {
    width: 25vw;
    height: 25vw;
    :deep(.card_user) {
        width: 25vw;
        height: 25vw;
    }

    // height: 80px+ 10px;;
}

.h4PrimaryColor {
    color: color(primary);
}

.deadcard {
    background: #f4f5f7;
    /* Border/40 */

    border: 2px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 6px;
}
</style>
