<template>
  <div class="flex flex-col gap-10 w-full">
    <h4 class="text-center m-3">Ranking</h4>
    <div class="flex flex-row w-full content-between mb-10">
      <div
        class="w-4/12"
        v-for="(player, index) in $store.state.storeGameData.sellerSpongeData.top3"
        v-bind:key="player"
        :style="'background-image:' + require( '@/assets/games/ranking/ranking'+index+'.svg')"
        
      >
        <div class="userWarap m-auto " :class="rankingData[index].class" v-if="player">
            <img :src="require( '@/assets/games/ranking/ranking'+index+'.svg')" alt=""
            class="w-3/4 opacity-50 mb-5 flex mx-auto ">
          <CardUser :track=" $store.getters.getUserById(player.idPlayer)" v-if="index < 3" />
          
          <h4 class="text-center m-2">
            {{ rankingData[index].text }}
            {{ $store.getters.getUserByIdName(player.idPlayer) }}
          </h4>
          <h4 class="text-center m-2">{{ player.score }} Score</h4>
          <h4 class="text-center m-2">{{ player.points }} points</h4>
        </div>
      </div>
    </div>

    <div class="sub-ranking flex flex-row flex-wrap gap-10 p-5 bottom-full justify-center" v-if="($store.state.storeGameData.sellerSpongeData.under).length >0">
      <div
        v-for="(player, index) in $store.state.storeGameData.sellerSpongeData.under"
        v-bind:key="player.idPlayer"
      >
        <div class="userWrap-sub">
          <CardUser :track=" $store.getters.getUserById(player.idPlayer)"/>
          <p class="text-center m-2">
            {{ index + 4 }} -
            {{ $store.getters.getUserByIdName(player.idPlayer) }}
          </p>
          <p class="text-center m-2">{{ player.points }} points</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardUser from "@/components/cards/CardUser";
import { useStore } from "vuex";
export default {
  components: {
    CardUser,
  },

  setup() {
    const store = useStore();
    const rankingData = [
      {
        class: "second",
        text: "2-",
      },
      {
        class: "first",
        text: "1-",
      },
      {
        class: "third",
        text: "3-",
      },
    ];

    return {
      rankingData,
    };
  },
};
</script>

<style lang="scss" scoped>
.userWarap {
  width: 160px;
  :deep(.card_user) {
    width: $width-user + 10px;
    height: $width-user + 10px;
  }

  // height: 80px+ 10px;;
}

.userWrap-sub {
  :deep(.card_user) {
    width: 80px;
    height: 80px;
  }

  // height: 80px+ 10px;;
}

.second {
  top: 50px;
  position: relative;
}

.third {
  top: 70px;
  position: relative;
}

.sub-ranking {
  background: #f4f5f7;
  /* Border/20 */

  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
</style>


