import { checkPermisionsMicrophone, checkPermisionsCamera, getDevicesMedia } from '../misc/media/media'
import axios from 'axios';
import { notify } from 'notiwind'


const url = window.location.pathname
let roomTwillo = {}


let token = ""
let timerRequestHost = Date.now()
let dataBasePlayerUrl = ""
const { connect, createLocalTracks } = require('twilio-video');
var javascriptNode;
function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

// let playerID;
// if (localStorage.playerID) {
//   playerID = localStorage.playerID
// }
// else {
//   playerID = makeid(15)
//   localStorage.playerID = playerID
// }


import { db } from '../firebase'
import { ref, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, onValue, off, remove, get } from "firebase/database";

const uniqBy = (arr, predicate) => {
  // const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

  // return [...arr.reduce((map, item) => {
  //   const key = (item === null || item === undefined) ? 
  //     item : cb(item);

  //   map.has(key) || map.set(key, item);

  //   return map;
  // }, new Map()).values()];
  let returnArray = []
  for (const player of arr) {
    const uniqueId = arr.filter(e => e.idPlayer == player.idPlayer)
    if (uniqueId.length == 1) {
      returnArray.push(uniqueId[0])
    }
    else if (uniqueId.length > 1) {
      const checkArray = returnArray.filter(e => e.idPlayer == uniqueId[0].idPlayer)
      if (checkArray.length > 0) {
        continue
      }
      for (const unique of uniqueId) {
        if ("stream" in unique) {
          returnArray.push(unique)
          break;
        }
      }
    }
  }
  return returnArray


};

function MergePlayers(state) {
  if (state.localStream.team) {
    var result = [...state.tracePlayers]
    var historicalPlayers = [...state.historicalPlayers]
    var tempLocal = { ...state.localStream }
    tempLocal.self = true
    result.push(tempLocal)
    historicalPlayers.forEach(player => {
      if (!result.find(p => p.idPlayer === player.idPlayer)) {
        player.disconected = true
        player.admin = false
        result.push(player)
      }
    });
    return uniqBy(result, 'idPlayer');
  }
  else {
    return []
  }
}

const creatPlayerDb = (state) => {
  dataBasePlayerUrl = url + '/playerMedia/'
  const teamDataDB = ref(db, dataBasePlayerUrl);
  console.log("create new user to db")
  update(teamDataDB, {
    [state.idPlayer]: {
      id: state.twilloId,
      team: "lobby",
      idPlayer: state.idPlayer,
      playerName: state.playerName,
      video: state.userVideo,
      urlImg: state.urlImg,
      muted: false,
      admin: false,
    }
  }).then(async (dataDBReturn) => {

    const presenceRef = ref(db, (dataBasePlayerUrl + state.idPlayer));
    onDisconnect(presenceRef).remove();

  }).catch((error) => {
    console.log(error)
  })
}


let firebasekey = undefined
export const storeMedia = {
  state: () => {
    return {
      idPlayer: null,
      test: "test",
      permisions: 0,
      localMedia: {},
      cameras: [],
      speakers: [],
      microphones: [],
      volMic: 0,
      localStream: {
        "team": null,
        muted: false,
        video: false,
      },
      setStream: false,
      tracePlayers: [],
      historicalPlayers: [],
      devices: {
        "audio": null,
        "video": null,
      },
      playerName: "",
      loadingStart: true,
      errorUrl: {
        modal: false,
        error: ""
      },
      blobImg: {},
      urlImg: "",
      slowMode: false,
      photoTaken: false,
      urlAvatar: null,
      mutedPlayers: {},
      modalHost: false,
      micLevelSound: true,
      screenShare: false,
      shareScreenTrack: {},
      mediaOptionsModal: false,
      media_change: {
        audio: null,
        video: null,
      },
      currentPoints: {},
      userVideo: false,
      twilloId: null,
      chat: {
        active: false,
        messages: {}
      },
    }
  },
  getters: {
    getPermisions(state) {
      return state.permisions
    },
    getloadingStart(state) {
      return state.loadingStart
    },
    getMedia(state) {
      if (state.microphones.length == 0) {
        return false

      }
      return {
        "cameras": state.cameras,
        "speakers": state.speakers,
        "microphones": state.microphones,
      }
    },
    getStreamStatus(state) {
      return state.setStream
    },
    getTracePlayers(state) {
      return state.tracePlayers.map(user => user.sid)
    },
    getAllTracePlayers(state) {
      // check new users
      const players = MergePlayers(state);
      return (players.filter(e => e.idPlayer && !e.disconected))
    },
    getPlayersNoTeam(state) {
      const players = MergePlayers(state);
      let playerLobby = 0
      players.forEach((element) => {
        if (element.team == "lobby") {
          playerLobby += 1;
        }
      })
      return playerLobby;
    },

    getUserTeam(state) {
      return state.localStream.team
    },

    getUserById: (state) => (id) => {
      const players = MergePlayers(state);

      const selectedPlayer = players.filter(user => user.idPlayer == id)[0]
      if (selectedPlayer) {
        return selectedPlayer
      }
      return false;
    },
    getUserByIdName: (state) => (id) => {
      const players = MergePlayers(state);

      const player = players.find(user => user.idPlayer == id)

      if (player) {
        return player.playerName
      }
      return ""
    },
    getPlayersByPoints(state) {
      const players = MergePlayers(state);
      if (players.length == 0) {
        return []
      }
      return players.sort(function (a, b) {
        return b.points - a.points;
      });
    },
    getTopPlayerRanking(state) {
      const players = MergePlayers(state);
      if (players.length == 0) {
        return []
      }
      players.sort(function (a, b) {
        return b.points - a.points;
      });

      return [
        players[1],
        players[0],
        players[2],
      ]
    },
    getRankingPlayers(state, input, rootState) {
      let players = MergePlayers(state);
      const playerPlaying = rootState.storeGameState.playerPlaying;

      let aux = [];
      console.log(players)
      players.forEach((element) =>
        element.idPlayer === playerPlaying.find((e) => e === element.idPlayer)
          ? aux.push(element)
          : ""
      );
      players = aux;

      let result = [];
      if (players.length == 0) {
        return result;
      }
      players.sort(function (a, b) {
        return b.points - a.points;
      });

      let pos = 1;
      for (let i = 0; i < players.length; i++) {
        const player = players[i];

        if (i != 0) {
          if (player.points != players[i - 1].points) {
            pos = i + 1;
          }
        }

        if (i == 1 && pos == 2) {
          result.unshift({ pos, player });
        } else {
          result.push({ pos, player });
        }
      }
      console.log(result)
      return result;
    },
    getNotTopPlayerRanking(state) {
      const players = MergePlayers(state);
      if (players.length == 0) {
        return []
      }
      players.sort(function (a, b) {
        return b.points - a.points;
      });
      players.shift();
      players.shift();
      players.shift();
      return players
    },

    getMediaById: (state) => (id) => {
      const players = MergePlayers(state);
      if (players.length == 0) {
        return []
      }
      const player = players.find(user => user.idPlayer == id)
      if (player) {
        return player
      }
      return null
    }

  },
  mutations: {
    setmediaOptionsModal(state) {
      state.mediaOptionsModal = !state.mediaOptionsModal
    },
    setChatStatus(state) {
      state.chat.active = !state.chat.active
    },
    setChatMessages(state, data) {
      var sortable = [];
      for (var message in data) {
        sortable.push(data[message]);
      }

      sortable.sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });

      var lastPlayer = ''
      sortable.forEach(e => {
        if (e.idPlayer === lastPlayer) {
          e.changePlayer = false;
        }
        else {
          e.changePlayer = true;
        }
        lastPlayer = e.idPlayer;
      })

      state.chat.messages = sortable;
    },
    setSlowMode(state) {
      state.slowMode = !state.slowMode
    },
    setUserImageUrl(state, data) {
      state.urlImg = data;
    },
    setIdPlayer(state, data) {
      state.idPlayer = data
    },
    waitingPermisions(state) {
      state.permisions = 1
    },
    setLoading(state, loading) {
      state.loadingStart = loading
    },
    acceptPermisions(state) {
      state.permisions = 2
      state.setStream = 0
    },
    errorPermisions(state) {
      state.permisions = 3
    },
    setLocalStream(state, localStream) {
      state.localStream = localStream
    },
    setPlayerName(state, playerName) {
      state.playerName = playerName
    },
    setCameras(state, cameras) {
      state.cameras = cameras
    },
    setMediaStream(state, data) {
      if (data.type == "audioinput") {
        state.devices.audio = data.id

      }
      else if (data.type == "videoinput") {
        state.devices.video = data.id
      }
    },
    setMicrophone(state, microphones) {
      state.microphones = microphones
    },
    setVolMic(state, volMic) {
      state.volMic = volMic
    },
    setTracePlayers(state, { participant, action }) {
      if (action == "add") {
        state.tracePlayers = [...state.tracePlayers, participant.tracks]
      }
      else if (action == "remove") {
        state.tracePlayers == state.tracePlayers.filter(item => item.key == participant.tracks.key)

      }
    },
    attachTracks(state, tracks) {
      tracks.forEach(function (track) {
        state.tracePlayers.push(track.attach());
      });
    },
    changeTeam(state, data) {
      if (firebasekey) {
        const customUser = ref(db, dataBasePlayerUrl + firebasekey);
        update((customUser), {
          "team": data
        })
      }

    },
    setMutedById(state, data) {
      state.mutedPlayers[data] = !state.mutedPlayers[data];
    },
    setModalHost(state, data) {
      if (!process.env.VUE_APP_LOCALHOST_TASK) {
        state.modalHost = data;
      }
    },
    setHistoricalPlayers(state, data) {
      console.log({ data })
      Object.values(data).forEach(e => {
        var historical = state.historicalPlayers.find(historical => historical.idPlayer == e.idPlayer)
        if (historical) {
          // TODO UPDATE HISTORICAL DATA FROM FIREBASE
          Object.keys(e).forEach(key => {
            historical[key] = e[key]
          })
          if (state.currentPoints) {
            const keyPoints = Object.keys(state.currentPoints).find(e => e == historical.idPlayer)
            if (keyPoints) {
              historical.points = state.currentPoints[keyPoints].points
            }
            else {
              historical.points = 0
            }
          }
          else {
            historical.points = 0
          }
        }
        else {
          state.historicalPlayers.push(e)
        }

      })

    },
    setTeam(state, dataArray) {
      //lost conection the only player online
      if (!dataArray) {
        creatPlayerDb(state);
        return
      }
      let userFind = false
      Object.keys(dataArray).map((key, index) => {
        const data = dataArray[key];
        var trace = state.tracePlayers.find(b => b.id === data.id);
        state.mutedPlayers[data.idPlayer] = process.env.VUE_APP_ALWAYS_MUTED;
        if (state.idPlayer == data.idPlayer) {
          userFind = true;
          state.localStream.team = data.team
          if (data.admin == true && state.localStream.admin != data.admin) {
            notify({
              group: "top",
              template: "you_host",
            }, 2000)
            if (!process.env.VUE_APP_LOCALHOST_TASK) {
              state.modalHost = true;
            }
          }
          state.localStream.muted = data.muted
          state.localStream.video = data.video
          state.localStream.admin = data.admin
        }
        else if (trace) {
          trace.team = data.team
          trace.playerName = data.playerName
          trace.video = data.video
          trace.idPlayer = data.idPlayer
          trace.urlImg = data.urlImg
          trace.admin = data.admin
          trace.muted = data.muted;
          let findPoints = false;
          for (const [key, value] of Object.entries(state.currentPoints)) {
            if (key == data.idPlayer) {
              trace.points = value.points
              findPoints = true;
              break;
            }
          }
          if (!findPoints) {
            trace.points = 0
          }
        }
        else {
          state.tracePlayers.push({
            "team": data.team,
            "playerName": data.playerName,
            "video": data.video,
            "idPlayer": data.idPlayer,
            urlImg: data.urlImg,
            id: data.id,
            admin: data.admin,
            muted: data.muted,
          })
        }
      })
      console.log("...state.tracePlayers")
      console.log({ ...state.tracePlayers })
      if (!userFind) {
        console.log("user not found")
        creatPlayerDb(state);
      }

    },
    setPointsUsers(state, data) {
      state.currentPoints = data
      console.log("--------------------")
      for (const [key, value] of Object.entries(data)) {
        var trace = state.tracePlayers.find(b => b.idPlayer === key);
        if (state.localStream.idPlayer == key) {
          state.localStream.points = value.points
        }
        if (trace) {
          trace.points = value.points
        }
        else {
          trace = state.historicalPlayers.find(b => b.idPlayer === key);
          if (trace) {
            console.log(value.points, "udpate points")
            trace.points = value.points
          }
        }
      };
    },
    // Attach the Participant's Tracks to the DOM.
    attachParticipantTracks(state, participant) {
      const [audioID] = (participant.audioTracks.keys())
      const DataStream = {
        id: participant.identity,
        stream: new MediaStream(),
        audioID: audioID,
      }


      participant.on('trackSubscribed', publication => {

        DataStream.stream.addTrack(publication.mediaStreamTrack)
      });
      participant.on('trackUnsubscribed', publication => {
        DataStream.stream.removeTrack(publication.mediaStreamTrack)
      });
      var trace = state.tracePlayers.find(b => b.id === participant.identity);
      if (trace) {
        trace.stream = DataStream.stream
        trace.audioID = DataStream.audioID
        trace.id = DataStream.id
      }
      else {
        state.tracePlayers.push(DataStream);
      }
    },
    // Detach the Tracks from the DOM.
    detachTracks(state, tracks) {
      tracks.forEach((track) => {
        track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
      });
    },
    // Detach the Participant's Tracks from the DOM.
    detachParticipantTracks(state, participant) {
      // let tracks = Array.from(participant.tracks.values());

      const removeIndex = state.tracePlayers.findIndex(item => item.id === participant.identity);


      // remove object
      state.tracePlayers.splice(removeIndex, 1);
    },

    // Detach the Participant's Tracks from the DOM.
    setMicLevel(state, micLevelData) {
      // let tracks = Array.from(participant.tracks.values());
      var trace = state.tracePlayers.find(b => b.audioID === micLevelData.id);
      if (trace) {
        trace.micLevel = micLevelData.micLevel

      }
    },
    errorUrl(state, error) {
      state.errorUrl.error = error
      state.errorUrl.modal = true
    },

    setBlobImg(state, blob) {
      state.blobImg = blob
      state.photoTaken = true
    },

    setUrlAvatar(state, urlAvatar) {
      state.urlAvatar = urlAvatar
    },
    setNewCamera(state, data) {
      state.media_change.video = data
    },
    setNewMic(state, data) {
      state.media_change.audio = data
    }



  },
  actions: {
    async getDevices({ commit, state }) {
      const devices = await getDevicesMedia()
      const audioSource = state.devices.audio;
      const videoSource = state.devices.video;
      let constraints = {}
      if (devices.audio.length > 0) {
        constraints.audio = { deviceId: audioSource ? { exact: audioSource } : undefined }
        checkPermisionsMicrophone(commit)
        commit('setMicrophone', devices.audio)
      }
      if (devices.video.length > 0) {
        constraints.video = { deviceId: videoSource ? { exact: videoSource } : undefined }
        checkPermisionsCamera(commit)
        commit('setCameras', devices.video)
      }

    },
    async updateTracksMic({ commit, state }) {
      console.log("in")
      for (const [key, publication] of roomTwillo.localParticipant.audioTracks.entries()) {
        roomTwillo.localParticipant.unpublishTrack(publication.track)
        await publication.track.stop();
      }

      let constraints = {}
      constraints.audio = state.media_change.audio
      constraints.video = false
      navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
        mediaStream.getTracks().map(track => {
          console.log(track)
          roomTwillo.localParticipant.publishTrack(track)
          if (track.kind == "video") {
            const stream = new MediaStream()
            stream.addTrack(track)
            state.localStream.stream = stream
          }
          else {
            const stream = new MediaStream()
            stream.addTrack(track)
            state.localStream.audioStream = stream
            const audioContext = new AudioContext();
            state.micLevelSound = false;

            const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(state.localStream.audioStream);
            const analyserNode = audioContext.createAnalyser();
            mediaStreamAudioSourceNode.connect(analyserNode);

            const pcmData = new Float32Array(analyserNode.fftSize);
            state.micLevelSound = true;
            const onFrame = () => {
              analyserNode.getFloatTimeDomainData(pcmData);
              let sumSquares = 0.0;
              for (const amplitude of pcmData) { sumSquares += amplitude * amplitude; }

              state.volMic = Math.sqrt(sumSquares / pcmData.length) * 250
              if (state.micLevelSound) {
                window.requestAnimationFrame(onFrame);
              }
            };
            window.requestAnimationFrame(onFrame);

          }
        })
      })

    },
    async updateTracksVideo({ commit, state }) {

      for (const [key, publication] of roomTwillo.localParticipant.videoTracks.entries()) {
        roomTwillo.localParticipant.unpublishTrack(publication.track)
        await publication.track.stop();
      }


      let constraints = {}
      constraints.video = state.media_change.video
      constraints.audio = false
      console.log(constraints)
      navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
        mediaStream.getTracks().map(track => {
          roomTwillo.localParticipant.publishTrack(track)
          if (track.kind == "video") {
            const stream = new MediaStream()
            stream.addTrack(track)
            state.localStream.stream = stream
          }
        })
      })
    },
    async setUpMedia({ commit, state }) {
      const devices = await getDevicesMedia()
      const audioSource = state.devices.audio;
      const videoSource = state.devices.video;
      let constraints = {}
      if (devices.audio.length > 0) {
        constraints.audio = { deviceId: audioSource ? { exact: audioSource } : undefined }
        checkPermisionsMicrophone(commit)
        commit('setMicrophone', devices.audio)
      }
      if (devices.video.length > 0) {
        constraints.video = { deviceId: videoSource ? { exact: videoSource } : undefined }
        checkPermisionsCamera(commit)
        commit('setCameras', devices.video)
      }
      if (window.stream) {
        window.stream.getTracks().forEach(track => {
          track.stop();
        });


      }

      navigator.mediaDevices.getUserMedia(constraints).then(async (stream) => {
        const devicesAfter = await getDevicesMedia()
        commit('setCameras', devicesAfter.video)
        commit('setMicrophone', devicesAfter.audio)
        commit('acceptPermisions')
        window.stream = stream; // make stream available to console

        commit('setLocalStream', stream)
        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
        const analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);

        const pcmData = new Float32Array(analyserNode.fftSize);
        const onFrame = () => {
          analyserNode.getFloatTimeDomainData(pcmData);
          let sumSquares = 0.0;
          for (const amplitude of pcmData) { sumSquares += amplitude * amplitude; }

          state.volMic = Math.sqrt(sumSquares / pcmData.length) * 250
          if (state.micLevelSound) {
            window.requestAnimationFrame(onFrame);
          }
        };
        window.requestAnimationFrame(onFrame);
      }).catch((error) => {
        commit('errorPermisions')
        //console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
      });
    },
    stopMicVol({ commit, state }) {
      state.micLevelSound = false;
    },
    changeSource({ commit, dispatch }, data) {
      commit('setMediaStream', {
        "type": data.kind,
        "id": data.deviceId,
      })
      if (window.stream) {
        window.stream.getTracks().forEach(track => {
          track.stop();
          commit('setLocalStream', null)
        });
      }
      dispatch('setUpMedia');

    },
    saveData({ commit, state }) {
      localStorage.audio = state.devices.audio
      localStorage.video = state.devices.video
      localStorage.name = state.playerName
    },
    async connect({ commit, state }, data) {
      const devices = await getDevicesMedia()
      const audioSource = state.devices.audio;
      const videoSource = state.devices.video;
      if (devices.audio.length == 0 && devices.video.length == 0) {
        commit('errorPermisions')
      }

      let video = false
      let constraints = {}

      if (devices.audio.length > 0) {

        constraints.audio = { deviceId: audioSource ? { exact: audioSource } : undefined }
        checkPermisionsMicrophone(commit)
        state.media_change.audio = constraints.audio.deviceId
      }
      if (devices.video.length > 0) {
        video = true

        constraints.video = { deviceId: videoSource ? { exact: videoSource } : undefined }
        constraints.video.width = 640
        checkPermisionsCamera(commit)
        state.media_change.video = constraints.video.deviceId
      }

      let headers = {}
      if (localStorage.user) {
        headers = { authorization: "Token " + localStorage.user }
      }
      await axios.get(process.env.VUE_APP_BE_REMOTEBOOST_URL + "bonfire/bonfireRooms" + data.url, { headers, params: { bonfire_id: state.idPlayer } })
        .then(res => {
          token = res.data.token;
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          commit("setLoading", false)
          commit("errorUrl", error.response.data.error_id)


        });
      let tracks = {}
      try {
        tracks = await createLocalTracks(constraints);
      }
      catch {
        commit('errorPermisions')
        return
      }

      commit('acceptPermisions')
      if (token) {
        connect(token, { tracks }).then(room => {
          console.log("iiiiiiiiiii")
          commit('acceptPermisions')
          roomTwillo = room;
          const localParticipant = room.localParticipant;
          let localTracks = Array.from(room.localParticipant.tracks.values())
          let localVideoTrack = null

          if (video) {
            for (var x = 0; x < localTracks.length; x++) {
              if (localTracks[x].kind == "video") {
                localVideoTrack = localTracks[x].track
              }
            }
          } else {
            localVideoTrack = localTracks[0].track
          }
          const datalocal = {
            stream: new MediaStream(),
            id: localParticipant.identity,
            idPlayer: state.idPlayer,
            team: "lobby",
            playerName: state.playerName,
            video: video,
            "points": 0,
            urlImg: state.urlImg,
            audioStream: new MediaStream(),
          }
          datalocal.stream.addTrack(localVideoTrack.mediaStreamTrack)
          for (var x = 0; x < localTracks.length; x++) {
            if (localTracks[x].kind == "audio") {
              datalocal.audioStream.addTrack(localTracks[x].track.mediaStreamTrack)
            }
          }

          window.onbeforeunload = () => {
            off(teamDataDB)
            room.disconnect();
            if (state.screenShare == true) {
              roomTwillo.localParticipant.unpublishTrack(screenShare);
              screenShare.stop();
              screenShare = null;
              state.screenShare = false;
              const url = window.location.pathname
              const dataBaseHost = url + '/generalInfo/screenShare'
              set(ref(db, dataBaseHost), false);
            }
            const presenceRef = ref(db, (dataBasePlayerUrl + state.idPlayer));
            // onDisconnect(presenceRef).remove();
            remove(presenceRef)
          };
          state.userVideo = video
          state.twilloId = localParticipant.identity


          const pingServer = () => {
            //ping to server to say i'm alive
            const dataBaseHost = data.url + '/pingPlayers'
            update(ref(db, dataBaseHost), { [state.idPlayer]: parseInt(Date.now() / 1000) });
            setTimeout(pingServer, 10000);
          }
          pingServer();



          commit("setLocalStream", datalocal)
          dataBasePlayerUrl = data.url + '/playerMedia/'
          const teamDataDB = ref(db, dataBasePlayerUrl);
          creatPlayerDb(state)
          // Attach the Tracks of the Room's Participants.
          room.participants.forEach(function (participant) {
            let previewContainer = document.getElementById('remoteTrack');
            let tracks = Array.from(participant.tracks.values());
            commit("attachParticipantTracks", participant);


          });
          // When a Participant joins the Room, log the event.
          room.on('participantConnected', function (participant) {
            commit("attachParticipantTracks", participant);

          });
          room.on('participantDisconnected', function (participant) {
            commit("detachParticipantTracks", (participant));
          });
          room.on('trackSubscribed', track => {

            if (track.name == 'screen') {
              const stream = new MediaStream()
              stream.addTrack(track.mediaStreamTrack)
              state.shareScreenTrack = stream
            }
            else {

            }
          });

          commit("setLoading", false)
          // data.loader.hide()

          onValue(teamDataDB, (snapshot) => {
            const data = snapshot.val();
            commit("setTeam", data)
          });

          const dataBasePlayerUrl2 = data.url + '/gameDataPoints'
          const teamDataDB2 = ref(db, dataBasePlayerUrl2);

          onValue(teamDataDB2, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              commit('setPointsUsers', data)
            }
          });

          const dataBaseURLHistoricalPlayers = data.url + '/historicalPlayers';
          const dataDBHistoricalPlayers = ref(db, dataBaseURLHistoricalPlayers);

          onValue(dataDBHistoricalPlayers, (snapshot) => {
            const data = snapshot.val();
            commit("setHistoricalPlayers", data)
          });

          const dataBaseURLChatMessages = data.url + '/chat';
          const dataDBChatMessages = ref(db, dataBaseURLChatMessages);


          onValue(dataDBChatMessages, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              commit("setChatMessages", data.messages)
            }
          });

          // request host update
          const dataBaseHost = data.url + '/requestHost/'
          onValue(ref(db, dataBaseHost), (snapshot) => {
            // check if current host
            const data = snapshot.val();
            if (state.localStream.admin) {
              notify({
                group: "request",
                user: data.user
              }, 30000)
            }
          });




        }, error => {
          console.error(`Unable to connect to Room: ${error.message}`);
        });
      }
      else {

      }
    },
    requestHost({ commit, state }, data) {
      if (Date.now() - timerRequestHost < 3000) {
        return;
      }
      timerRequestHost = Date.now()
      const url = window.location.pathname
      const dataBaseHost = url + '/requestHost'
      set(ref(db, dataBaseHost), {
        user: state.idPlayer,
        time: Date.now(),
      });
      notify({
        group: "top",
        template: "host_request",
      }, 2000)
    },
    giveHost({ commit, state }, data) {
      const url = window.location.pathname

      axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_URL + "game/giveAdmin" + url, {
        adminId: data
      })
    },
    async mutedPlayer({ commit, state }) {
      const data = !state.localStream.muted

      if (data == true) {
        roomTwillo.localParticipant.audioTracks.forEach(publication => {
          publication.track.disable();
        });
      }
      if (data == false) {
        roomTwillo.localParticipant.audioTracks.forEach(publication => {
          publication.track.enable();
        });
      }
      dataBasePlayerUrl = url + '/playerMedia/' + state.idPlayer
      const teamDataDB = ref(db, dataBasePlayerUrl);
      update(teamDataDB, {
        muted: data
      })

    },
    async stopVideoPlayer({ commit, state }) {
      const data = !state.localStream.video

      if (data == true) {
        roomTwillo.localParticipant.videoTracks.forEach(publication => {

          publication.track.enable();
        });

      }
      if (data == false) {
        roomTwillo.localParticipant.videoTracks.forEach(publication => {

          if (publication.track.name != 'screen') {
            publication.track.disable();

          }
        });
      }
      dataBasePlayerUrl = url + '/playerMedia/' + state.idPlayer
      const teamDataDB = ref(db, dataBasePlayerUrl);
      update(teamDataDB, {
        video: data
      })

    },
    async shareScreen({ commit, state }) {
      if (!state.screenShare) {
        navigator.mediaDevices.getDisplayMedia().then(async (stream) => {
          state.screenShare = true;
          screenShare = stream.getTracks()[0]
          roomTwillo.localParticipant.publishTrack(screenShare, { name: "screen" });
          state.shareScreenTrack = stream

          const url = window.location.pathname
          const dataBaseHost = url + '/generalInfo/screenShare'
          set(ref(db, dataBaseHost), true);

          screenShare.onended = () => {
            roomTwillo.localParticipant.unpublishTrack(screenShare);
            screenShare.stop();
            screenShare = null;
            state.screenShare = false;
            const url = window.location.pathname
            const dataBaseHost = url + '/generalInfo/screenShare'
            set(ref(db, dataBaseHost), false);

          };
        })
      }
      else {
        roomTwillo.localParticipant.unpublishTrack(screenShare);
        screenShare.stop();
        screenShare = null;
        state.screenShare = false;
        const url = window.location.pathname
        const dataBaseHost = url + '/generalInfo/screenShare'
        set(ref(db, dataBaseHost), false);
      }
    },
    async updateName({ commit, state }, data) {
      let dataForm = new FormData()
      dataForm.append('name', data.userName)
      dataForm.append('bonfire_id', state.idPlayer)
      let config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      data.setLoading();
      axios.put(process.env.VUE_APP_BE_REMOTEBOOST_URL + "accounts/bonfireUser", dataForm, config).then(response => {
        state.localStream.playerName = response.data.bonfire_name
        dataBasePlayerUrl = url + '/playerMedia/' + state.idPlayer + '/playerName/'
        console.log(dataBasePlayerUrl)
        const teamDataDB = ref(db, dataBasePlayerUrl);
        set(teamDataDB, response.data.bonfire_name)
        notify({
          group: "top",
          template: "name_update",
        }, 2000)
        data.setLoading();
      })

    },
    async updatePhoto({ commit, state }, data) {
      console.log(data)
      let dataForm = new FormData()
      dataForm.append('image', data.imageBlob)
      dataForm.append('bonfire_id', state.idPlayer)
      let config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      data.setLoading();
      axios.put(process.env.VUE_APP_BE_REMOTEBOOST_URL + "accounts/bonfireUser", dataForm, config).then(response => {
        state.localStream.urlImg = response.data.image
        dataBasePlayerUrl = url + '/playerMedia/' + state.idPlayer + '/urlImg'
        const teamDataDB = ref(db, dataBasePlayerUrl);
        set(teamDataDB, response.data.image)
        notify({
          group: "top",
          template: "photo_update",
        }, 2000)
        data.setLoading();
      })

    },
    async getMicLevel({ commit, state }, data) {
      const audioContext = new AudioContext();
      console.log(state.localStream.audioStream)
      const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(state.localStream.audioStream);
      const analyserNode = audioContext.createAnalyser();
      mediaStreamAudioSourceNode.connect(analyserNode);

      const pcmData = new Float32Array(analyserNode.fftSize);
      const onFrame = () => {
        analyserNode.getFloatTimeDomainData(pcmData);
        let sumSquares = 0.0;
        for (const amplitude of pcmData) { sumSquares += amplitude * amplitude; }

        state.volMic = Math.sqrt(sumSquares / pcmData.length) * 250
        if (state.micLevelSound) {
          window.requestAnimationFrame(onFrame);
        }
      };
      window.requestAnimationFrame(onFrame);
    }



  },
}



let screenShare = null
export default storeMedia