<template>
    <div class="flex flex-col gap-5 justify-center items-center w-9/12 h-full">
        <h4 class="text-center" v-if="$store.state.storeGameData.topoData.guess">
            {{ $t("topo.final_result_topo_guess") }}
        </h4>
        <h4
            class="text-center items-start"
            v-else-if="$store.state.storeGameData.topoData.win == 'topo'"
            v-html="
                $t(
                    'topo.final_result_player_dead',
                    {
                        topos: $store.state.storeGameData.topoData.winners
                            .map((topo) => $store.getters.getUserByIdName(topo))
                            .join(', '),
                    },
                    $store.state.storeGameData.topoData.winners.length - 1
                )
            "
        />
        <h4 class="text-center" v-else-if="$store.state.storeGameData.topoData.win == 'players'">
            {{ $t("topo.final_result_topo_kills") }}
        </h4>
        <img
            v-if="$store.state.storeGameData.topoData.win == 'players'"
            :src="require('@/assets/games/topo/Moles Loses.svg')"
            alt=""
            class="w-2/4 "
        />
        <img v-else :src="require('@/assets/games/topo/Moles Win.svg')" alt="" class="w-2/4 " />

        <h4 class="text-center">
            {{ $t("game.retunr_lobby") }}
        </h4>
    </div>
</template>

<script>
import Quiz from "@/components/gameWidgets/Quiz.vue";

export default {
    components: {
        Quiz,
    },
    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped></style>
