<template>

    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        appear
    >
        <div
            v-if="status == true"
            class="modal"
        >

            <transition
                enter-active-class="animate__animated animate__fadeInDown animate__delay-1s"
                appear
            >

                <div
                    v-if="status == true"
                    class="modal-content"
                >

                    <div class="modal-header">
                        <inline-svg
                            @click="this.$emit('setModal',false)"
                            :src="require('@/assets/svg/close.svg')"
                        />
                    </div>

                    <div class="modal-body">

                        <div class="text_wrap">

                            <inline-svg :src="require('@/assets/svg/user_plus.svg')" />
                            <div class="text">
                                <h2 class="h4 text-xl">{{$t('modalInvite.title')}}</h2>
                                <p class="text-lg">{{$t('modalInvite.text')}}</p>
                            </div>
                        </div>
                        <InputPrimary
                            :readonly="true"
                            placehold='Respon a la pregunta'
                            text_error="Deves introduir un nombre de usuario"
                            :valueInput="urlInput"
                        />
                    </div>

                    <div class="modal-footer">
                        <BtnBase
                            @click="this.$emit('setModal',false);copyToClipBoard() "
                            :text="$t('modalInvite.btn_ok')"
                            classBtn="btn-primary btn-sm"
                        />
                    </div>

                </div>

            </transition>

        </div>
    </transition>

</template>

<script>
import InlineSvg from "vue-inline-svg";
import InputPrimary from "@/components/inputs/InputPrimary.vue";
import BtnBase from "@/components/buttons/BtnBase";
import { onMounted, ref } from "@vue/runtime-core";
import { notify } from 'notiwind'
import { useI18n } from 'vue-i18n';

export default {
    name: "ModalWelcome",

    components: {
        InlineSvg,
        BtnBase,
        InputPrimary,
    },

    props: {
        status: Boolean,
    },

    methods: {
        nextStep: function (var_step) {
            // Pasem a la seguent pagina Welcome
            this.$emit("setStep", var_step + 1);
        },
    },
    setup() {

        const {t }  = useI18n();
        const urlInput = ref(window.location.href);
        const copyToClipBoard = () => {
            /* Copy the text inside the text field */
            navigator.clipboard.writeText(window.location.href);
            notify({
                group: "top",
                title: t('modalInvite.alert_title'),
                text: t('modalInvite.alert_text'),
                }, 2000)
        
        };
        return {
            urlInput,
            copyToClipBoard,
        };
    },
};
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 3000;

    background: rgba(color(bg, 140), 0.5);

    &-content {
        max-width: 50%;
        padding: 16px 16px 32px;

        background: #ffffff;
        box-shadow: 0px 10px 24px #3c3c3c;
        border-radius: 12px;
    }

    &-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 8px;

        svg {
            height: 16px;
            widows: 16px;
            cursor: pointer;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text_wrap {
            display: flex;
            align-items: center;
        }

        :deep(svg) {
            height: 56px;
            width: 56px;
            margin: 0 8px 0 0;

            path,
            rect {
                fill: color(primary);
            }
        }

        :deep(input) {
            width: 100%;
            margin: 16px 0 0 0;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        margin: 16px 0 0;
    }

}
</style>