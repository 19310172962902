<template>
    <div class="flex flex-col p-20 text-center justify-center gap-5">
        <h4>
            {{$t('feedback.title')}}
        </h4>
        <p class="text-lg font-bold">
            {{$t('feedback.text')}}
        </p>
        <div class="img-border p-5 flex flex-col items-center justify-center gap-5">
            <div class="flex flex-row w-full items-center justify-center gap-5">
                
                 <inline-svg class="h-12" :src="require('@/assets/svg/face-frown-solid.svg')" style="fill:#FF7033"/>

                <Slider
                    v-model="sliderNote"
                    class="slider-red w-2/4"
                    :min="0"
                    :max="10"
                />
                 <inline-svg class="h-12" :src="require('@/assets/svg/face-smile-solid.svg')"  style="fill:#FF7033"/>

            </div>
            <textarea
                v-model="textFeedback"
                cols="40"
                rows="5"
                type="text"
                :placeholder="$t('feedback.text_placeholder')"
            />
            <BtnBase
                :disabled="disabled_btn"
                :text="$t('feedback.btn_send')"
                v-on:click="sendFeedback()"
            />
             <BtnBase
                v-if="$store.state.storeMedia.localStream.admin"
                :text="$t('feedback.btn_lobby')"
                v-on:click="$store.dispatch('startGame',{game:'lobby'})"
            />
        </div>

    </div>
</template>

<script>
import Slider from "@vueform/slider";
import { ref } from "@vue/reactivity";
import InputPrimary from "@/components/inputs/InputPrimary.vue";
import BtnBase from "@/components/buttons/BtnBase";
import { notify } from "notiwind";
import { useStore } from "vuex";
import axios from "axios";
import { useI18n } from 'vue-i18n';
import InlineSvg from 'vue-inline-svg';

export default {
    components: {
        InputPrimary,
        BtnBase,
        Slider,
        InlineSvg,
    },

    setup() {
        const {t} = useI18n();
        const sliderNote = ref(5);
        const textFeedback = ref("");
        const disabled_btn = ref(false);
        const store = useStore();
        const sendFeedback = () => {
            axios
                .post(
                    process.env.VUE_APP_BE_REMOTEBOOST_URL +
                        "content/feedbackbonfire",
                    {
                        name_id: store.state.storeGameState.currentGame,
                        bonfire_id: store.state.storeMedia.idPlayer,
                        score: sliderNote.value,
                        text: textFeedback.value,
                    }
                )
                .then((response) => {
                    disabled_btn.value = true;
                    textFeedback.value = "";
                    notify(
                        {
                            group: "top",
                            title: t("feedback.altert_thanks"),
                            text: "",
                        },
                        2000
                    );
                });
        };
        return {
            sliderNote,
            disabled_btn,
            textFeedback,
            sendFeedback,
        };
    },
};
</script>

<style lang="scss" scoped>
.img-border {
    border: 1px solid #ff7033;
    border-radius: 12px;
    width: 100%;
    object-fit: cover;
}
textarea {
    border: 1px solid color(border);
    background: #fff;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 16px;
    color: color(text, 120);
    font-family: $font-family;

    ::placeholder {
        color: color(text, 60);
    }
}
</style>
<style scoped>
.titleQuestion {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
}

.slider-red {
    --slider-connect-bg: #ff7033;
    --slider-tooltip-bg: #ff7033;
    --slider-handle-ring-color: #ff703330;
}
</style>