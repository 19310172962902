<template>
    <div class="flex flex-col gap-10 mt-3">
        <div class="pager-indicators">
            <div
                v-for="page in tutorialLength"
                :key="page"
                class="pager-indicators-item"
                v-on:click="setStep(page - 1)"
                :class="{ active: step == page - 1 }"
            ></div>
        </div>
        <div v-if="$store.state.storeMedia.localStream.admin" class="text-center">
            <BtnLoad
                type="button"
                class="btn btn-primary btn-outline"
                :disabled="false"
                @click="setLoading(), skipTutorial(), setLoading()"
                :isLoading="isLoading"
            >
                {{ step + 1 != tutorialLength ? $t("game.btn_skip_tutorial") : $t("game.start") }}
            </BtnLoad>
        </div>
    </div>
</template>

<script>
import BtnLoad from "@/components/buttons/BtnLoad";
import { ref } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
    name: "PagerWelcome",
    components: {
        BtnLoad,
    },
    props: {
        step: Number,
        setStep: Function,
        tutorialLength: Number,
    },
    setup(props) {
        const store = useStore();
        const isLoading = ref(false);
        const setLoading = () => {
            isLoading.value = !isLoading.value;
        };
        const skipTutorial = () => {
            store.dispatch("skipTutorial", { setLoading });
        };
        return {
            skipTutorial,
            isLoading,
            setLoading,
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.pager-indicators {
    display: flex;
    justify-content: center;
    align-items: center;

    .pager-indicators-item {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: color(primary, 60);
        margin: 0 5px;
        transition: 0.5s all 0.2s ease-in-out;
        cursor: pointer;

        &.active {
            width: 20px;
            height: 20px;

            background: color(primary, base);
        }
    }
}
</style>
