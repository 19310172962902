<template>
<div class="flex flex-col gap-5 mt-5">
    <h4 class="text-center">
        {{$t('mingleTingle.vote_title')}}
    </h4>
    <h4 class="text-center">
        {{$store.state.storeGameData.mingleTingleData.finalQuestion}}
    </h4>
    <div class="flex flex-row flex-wrap gap-5 justify-center">
        <div v-for="player in $store.state.storeGameState.playerPlaying" :key="player" class="userWarap">
            <CardUser
                v-if="$store.getters.getMediaById(player) != null"
                :track="$store.getters.getMediaById(player)"
                v-on:click="updateAnsw(player)"
                :class="selected == player ? 'selected' : ''"
            />
        </div>
    </div>

</div>

</template>


<script>
const url = window.location.pathname;

import CardUser from "@/components/cards/CardUser.vue";
import { ref as vref, onMounted, watch } from "vue";
import { db } from "@/firebase/index";
import {
    ref,
    off,
    onValue,
    update,
} from "firebase/database";
import { useStore } from 'vuex';

export default {
    components: {
        CardUser
    },

    setup(props) {
        const store = useStore();
        const id = store.state.storeMedia.idPlayer
        let lastCallback = null;
        const selected = vref({
            selected: ""
        });

        watch(
            () => props.dataUrl,
            () => {
                off(lastCallback);
                const dataBasePlayerUrl = url  + '/gameDataUser/' + id;
                const teamDataDB = ref(db, dataBasePlayerUrl);
                onValue(teamDataDB, snapshot => {
                    updateDataBase(snapshot);
                });
                lastCallback = teamDataDB;
            }
        );

        onMounted(() => {
            const dataBasePlayerUrl = url  + '/gameDataUser/' + id;
            const teamDataDB = ref(db, dataBasePlayerUrl);
            onValue(teamDataDB, snapshot => {
                updateDataBase(snapshot);
            });
            lastCallback = teamDataDB;
        });

        const updateAnsw = text => {
            const dataBasePlayerUrl = url + '/gameDataUser/' + id;
            const teamDataDB = ref(db, dataBasePlayerUrl);
            if (text == selected.value) {
                text = null;
            }
            update(teamDataDB, {
                vote: text
            });
        };

        function updateDataBase(snapshot) {
            const data = snapshot.val();
            if (data) {
                selected.value = data.vote;
            }
            else{
                selected.value = null;
            }
        }

        return {
            selected,
            updateAnsw
        };
    }
};
</script>

<style lang="scss" scoped>
.text-size{
    font-size:1.5vh
}
.userWarap {
    width: 17vw;
    height: 17vw;
    :deep(.card_user) {
        width: 15vw;
        height: 15vw;
    }

    // height: 80px+ 10px;;
}

.selected {
    :deep(.card_user) {
        border: 5px solid #ff7033;
    }
}
</style>