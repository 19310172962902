<template>
  <div class="welcome">  
    <section class="card-group">
      <p class="text-2xl text-white font-bold p-10 w-2/4 text-center mx-auto">
        {{$t('PERMISSIONS.text')}}
      </p>
    </section>
  </div>
</template>

<script>

  import CardWelcome from '@/components/cards/CardWelcome';

  export default {
    
    name: 'WelcomeError',

    components: {
        CardWelcome,
    },
  }
</script>

<style scoped lang="scss">

  .welcome{
    
    .card-group { 
    
      display: grid;


    }

    button{
      margin-top: 64px;
    }

    h2{
    z-index:10;
    color:white
    }

  }
</style>
