import { createStore , createLogger } from "vuex";
import storeMedia from "./storeMedia"
import storeNav from "./storeNav"
import storeGameState from "./storeGameState"
import storeGameData from "./storeGameData"
import storeAuth from "./storeAuth"

const store = createStore({
    modules:{
        storeMedia:storeMedia,
        storeGameState:storeGameState,
        storeNav:storeNav,
        storeGameData:storeGameData,
        storeAuth:storeAuth,
    },
    plugins:[createLogger()]
})

export default store