

<template>
    <div class="welcome" id="foo">
      <inline-svg :src="require('@/assets/svg/logo_bonfire.svg')"/>
      <h1>{{ $t('WELCOME0.title') }}</h1>
      <BtnBase @click="setStep(step)" :text="$t('WELCOME0.btn')" />
    </div>
</template>
<script>

  import InlineSvg from 'vue-inline-svg';
  import BtnBase from '@/components/buttons/BtnBase';

  export default {
    
    name: 'SetUp',

    components: {
        InlineSvg,
        BtnBase
    },

    props:{
      step:Number,
      
    },

    methods:{

      setStep: function(var_step){

        // Pasem a la seguent pagina Welcome
        this.$emit('setStep',var_step + 1); 

      },

    },

    setup(){

    }

  }
</script>

<style scoped lang="scss">
  
  .welcome{

    h1{
      
      margin-top: 32px;

      & + p{
        margin-top: 8px;
      }

    }

    button{
      margin-top: 32px;
    }

  }

</style>
