<template>
    <div class="w-full h-full flex flex-col items-center justify-center text-center">
        <div class=" userWrap flex flex-row flex-auto w-full items-end justify-center flex-wrap">
            <h3 class="text-primary-100">
                {{ $t("thisOrThat.winner", $store.state.storeGameData.thisOrThatData.winner.length) }}
            </h3>
        </div>
        <div class=" userWrap flex flex-row flex-auto w-full items-center justify-center flex-wrap gap-3">
            <div v-for="winner in $store.state.storeGameData.thisOrThatData.winner" v-bind:key="winner">
                <CardUserImgVue :track="$store.getters.getMediaById(winner)" />
            </div>
        </div>
        <div class="flex flex-row flex-auto w-full items-start justify-center flex-wrap px-10">
            <h3 v-if="!$store.state.storeGameData.thisOrThatData.alive_players"
                v-html="$t('thisOrThat.main_wins_no_alive_players')"/>
            <h3
                v-else-if="
                    $store.state.storeGameData.thisOrThatData.alive_players &&
                        $store.state.storeGameData.thisOrThatData.winner[0] ===
                            $store.state.storeGameData.thisOrThatData.main_player
                "
                v-html="$t('thisOrThat.main_wins_guessing')"
            />
            <h3 v-else v-html="$t('thisOrThat.player_win')" />
        </div>
    </div>
</template>

<script>
import CardUserImgVue from "@/components/cards/CardUserImg.vue";
export default {
    components: {
        CardUserImgVue,
    },
};
</script>

<style lang="scss" scoped>
.userWrap {
    width: 80px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
