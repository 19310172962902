<template>
  <GameContainer :timer="true"  v-if="$store.state.storeGameState.gameStep == 0">
    <Tutorial />
  </GameContainer>
  <GameContainer :timer="true"  v-else-if="$store.state.storeGameState.gameStep == 1">
      <SellerView/>
  </GameContainer>
  <GameContainer :timer="true"  v-else-if="$store.state.storeGameState.gameStep == 2">
      <FinalRanking 
        :deafultRanking="false" 
        :dataRanking="$store.state.storeGameData.sellerSpongeData.rankingSeller" 
        :customWord="$t('game.score')"
      />
  </GameContainer>
  <GameContainer   v-else-if="$store.state.storeGameState.gameStep == 3">
    <FinalFeedback/>
  </GameContainer>
</template>

<script>
import Tutorial from "./Tutorial.vue";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import PhaseRanking from '@/components/gameWidgets/PhaseRanking.vue'
import FinalRanking from '@/components/gameWidgets/FinalRanking.vue'
import SellerView from './SellerView.vue'
import RankingSponge from './RankingSponge.vue'
import FinalFeedback from '../FinalFeedback.vue'
export default {



  components: {
    Tutorial,
    GameContainer,
    FinalRanking,
    PhaseRanking,
    SellerView,
    RankingSponge,
    FinalFeedback

  },


};
</script>

<style>
</style>