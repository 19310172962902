const es = {
    // ON BOARDING
    test: "hola",
    "WELCOME0": {
        title: "Bienvenido/a a Bonfire",
        btn: "¡Vamos allá!",
    },
    "WELCOME1": {
        headphones: "Usa auriculares",
        headphones_text: "Para evitar que se acople el sonido de los altavoces con el micrófono.",

        internet: "Conéctate a una red estable",
        internet_text: "Asegúrate de que tienes una conexión de alta velocidad y una señal de red estable.",

        window: "Conéctate desde un ordenador",
        window_text: "Bonfire es compatible con ordenadores de sobremesa y portátiles. No garantizamos el correcto funcionamiento en tablets o dispositivos móviles.",

        chat: "Cierra otras aplicaciones",
        chat_text: "Otras aplicaciones que usen chat de voz o videollamada pueden interferir en la experiencia de Bonfire, asegúrate de que están cerradas.",
        btn: "Siguiente",
    },
    "PERMISSIONS": {
        "text": "Permite al navegador utilizar el micrófono y la cámara. Necesitamos estos accesos para que te puedas comunicar con tus compañeros dentro del juego. Puedes desactivar la cámara o silenciar el micrófono más adelante."
    },
    "WELCOME2": {
        title: "Prueba de micro y cámara",
        text_mic: "Selecciona el dispositivo de micrófono y comprueba que se enciende el fuego al hablar.",
        text_camera: "Selecciona el dispositivo de cámara y comprueba que te ves estupendo/a.",
        btnOK: "Funciona",
        btnNoOK: "No funciona",
        btnOKNoCam: "Continuar sin cámara",
        btn_sound: "Reproducir sonido de prueba",
        btn_stop_sound: "Detener sonido de prueba",
        mic_error_title: "Problemas de micrófono",
        mic_error_text: "Prueba los siguientes pasos para solucionar los problemas con el micro:",
        mic_error_list: [
            "Comprueba que has seleccionado el dispositivo de micrófono adecuado.",
            "Cierra cualquier aplicación que pueda estar usando el micrófono.",
            "Desconecta y vuelve a conectar el dispositivo.",
            "Comprueba el volumen del micrófono (si tiene).",
            "Asegúrate de que no hay ningún obstáculo cubriendo el dispositivo.",
            "Prueba con otro micrófono. La mayoría de los auriculares tienen uno, prueba a ver si funciona.",
            "Accede desde otro navegador (recomendamos Google Chrome).",
            "Comprueba tu conexión a internet.",
            "Si sigue sin funcionar, prueba a conectarte desde otro ordenador.",
        ],
        CAM_error_title: "Problemas de cámara",
        CAM_error_text: "Prueba los siguientes pasos para solucionar los problemas con la cámara:",
        CAM_error_list: [
            "Comprueba que has seleccionado el dispositivo de cámara adecuado.",
            "Cierra cualquier aplicación que pueda estar usando la cámara.",
            "Desconecta y vuelve a conectar la cámara.",
            "Asegúrate de que no hay ningún obstáculo cubriendo la cámara.",
            "Usa una cámara alternativa.",
            "Accede desde otro navegador (recomendamos Google Chrome).",
            "Comprueba tu conexión a internet.",
            "Si sigue sin funcionar, prueba a conectarte desde otro ordenador.",
        ],
        CAM_not_detected: "No se ha detectado dispositivo de cámara"
    },
    "WELCOME3": {
        title: "¡Patata!",
        text: "Sonríe y mira a la cámara. Esta foto será el icono de tu perfil durante el juego.",
        btnPhoto: "Hacer foto",
        btnRepeat: "Repetir",
        btnNext: "Siguiente",
        text_no_camera: "Aquí tienes tu avatar.",
        continue: "Siguiente",
        error_photo: "Tu versión de Google Chrome no está actualizada. Actualice Google Chrome o intente entrar con Firefox. Gracias",

    },
    "WELCOME4": {
        title: "¿Cómo te llamas?",
        text: "Escribe el nombre que se mostrará en el juego. Si el resto de jugadores ya te conocen, puedes ponerte un alias molón. ",
        btn: "Entra a Bonfire",
        placeholder: "Nombre",
        error_input: "Tienes que introducir un nombre de usuario."
    },




    // GENERAL
    general: {
        players: "Jugadores",
        play: "Jugar",
        back: "Atrás",
        game_start: "Lo sentimos, este juego ha empezado sin ti. Espera a que termine para unirte al siguiente.",
        date_format: "es-ES",
        user_disconected: "Desconectado"
    },

    // ALERTS 
    alerts: {
        error: "Error",
        success: "Éxito",
        need_more_players: "Se necesitan más jugadores para este juego. Jugadores mínimos: ",
        skip_tutorial: "Tutorial saltado",
        skip_tutorial_fail: "No te puedes saltar este tutorial.",
        you_host: "Eres el host",
        your_host_request: " te ha pedido ser el host", // X request the host
        host_request: "Has pedido ser el host",
        name_update: "Nombre actualizado",
        photo_update: "Foto actualizada",
        give_host: "Dar",
        game_start: "El juego ha empezado",
        game_end: "El juego ha finalizado",
        step_skipped: "Se ha saltado la fase",
        no_skipped: "Fase no saltada",
        no_skipped_text: "Para saltar la fase tienes que mantener apretado el botón saltar durante 1 segundo",
    },



    // AUTH 
    auth: {
        alert_login_success: "Has iniciado sesión con éxito.",
        alert_login_fail: "No se ha podido iniciar la sesión.",
        login: "Iniciar sesión",
        text_need_login: "Necesitas iniciar sesión para poder jugar.",
        login_google: "Iniciar sesión con Google",
        signup: "Regístrate",
        logout: "Cerrar sesión",
        wrong_password: "Contraseña incorrecta",

    },


    settingMenu: {
        settings: "Configuración",
        user_setting: "Nombre de usuario",
        photo_settings: "Foto de usuario",
        microphone_settings: "Micrófono",
        camera_settings: "Cámara",
        bonfire_name: "Cambiar nombre de usuario",
        bonfire_image: "Cambiar foto de usuario",
        update: "Guardar",
        title_mic: "Seleccionar micrófono",
        text_mic: "Selecciona el dispositivo de micrófono y comprueba que se enciende el fuego al hablar.",
        title_camera: "Seleccionar cámara",
        text_camera: "Selecciona la cámara y comprueba que te ves estupendo/a.",
    },

    // Modals:
    Modals: {
        error_title: "Error",
        game_no_exist: "Esta partida no existe.",
        game_finish: "Esta partida ya ha terminado.",
        game_full: "La partida está llena.",
        game_user_not_allowed: " no tiene permiso para jugar en esta sala.", // X user is not allwoed to play
        game_user_already_contected: "Tu usuario ya está conectado.",

    },

    // Card Game
    cardGame: {
        duration: "Min:",
        mins: "min",
        groupSize: "jugadores",
        game_year: "Juego del mes",
        most_played: "El más popular"
    },


    //LOBBY
    navbar: {
        toolTip_invite_players: "Invitar jugadores",
        toolTip_lobby: "Volver al lobby",
        modal_invite_title: "Invitar jugadores",
        modal_invite_text: "Manda este enlace a tus compañeros/as para que se unan a esta sesión de Bonfire:",
        modal_invite_copy: "Copiar invitación",
        toolTip_camera_icon: "Apagar / Enchegar camara",
        toolTip_host_you: "Eres el host",
        toolTip_request_host: "Solicitar host",
        logout: "Cerrar sessión",
        exit: "Salir"

    },

    bottomvar: {
        toolTip_camera_icon_on: "Activar cámara",
        toolTip_camera_icon_off: "Desactivar cámara",
        toolTip_micro_on: "Activar microfono",
        toolTip_micro_off: "Desactivar microfono",
        toolTip_slow_mode_on: "Activar modo lento",
        toolTip_slow_mode_off: "Desactivar modo lento",
        toolTip_menu_on: "Abrir menú",
        toolTip_menu_off: "Cerrar menú",
        toolTip_share_screen_on: "Compartir pantalla",
        toolTip_share_screen_off: "Dejar de compartir",
        toolTip_options_on: "Abrir configuración",
        toolTip_options_off: "Cerrar configuración",
    },

    modalOptionsMeida: {
        title: "Cambiar audio/video",
        select_micro: "Cambiar micrófono",
        select_camera: "Cambiar cámara",
        btn_ok: "Cambiar",
        error_media: "Selecciona un dispositivo."
    },

    modalHost: {
        title: "Eres el host de este Bonfire.",
        text: "Se te ha asignado el control de la sesión de juego. Te encargarás de seleccionar cada actividad y podrás detener el juego si es necesario. Puedes pasar el host a otro jugador desde el panel de control de sesión.",
        btn_ok: "De acuerdo",
    },

    modalInvite: {
        title: "Invitar jugadores",
        text: "Manda este enlace a tus compañeros/as para que se unan a esta sesión de Bonfire:",
        btn_ok: "Copiar enlace",
        alert_title: "Enlace copiado.",
        alert_text: "El enlace se ha copiado correctamente en tu portapapeles. Solo tienes que pegarlo.",
    },

    gameSelect: {
        theme: "Categoría",
        option: "Set de juego",
        altert_start: "Una vez empezado el juego, no se podrán unir más jugadores.",
        no_content: "Este juego no tiene selección de contenido",
        placeholder: "Seleccionar",
        language: "Idioma",
        duration: "Duración"
    },



    // GENERAL GAME

    game: {
        tutorial_wait: "Esperando a que el host inicie el juego.",
        btn_skip_tutorial: "Saltar tutorial",
        btn_next_tutorial: "Siguiente",
        btn_before_tutorial: "Anterior",
        points: "puntos",
        round: "Ronda",
        player: "jugador",
        retunr_lobby: "Ahora puedes volver al lobby para seleccionar otro juego.",
        skip_step: "Saltar",
        score: "nota",
        start: "Empezar",
    },

    results: {
        correct: "Han acertado:",
        incorrect: "No han acertado:",
        votes: "votos",
    },

    ranking: {
        title: "Ranking",

    },

    feedback: {
        title: "El juego ha terminado.",
        text: "Ahora el host de la partida puede volver al lobby para seleccionar otro juego. Antes de irte nos puedes dejar tu opinión sobre este juego.",
        text_placeholder: "Escribe un comentario.",
        altert_thanks: "Muchas gracias. Tu feedback nos ayuda a seguir mejorando.",
        btn_send: "Enviar feedback",
        btn_lobby: "Volver al lobby"
    },

    chat: {
        input_placeholder: "Escribe un mensaje...",
        tooltip_open: "Abrir chat",
        tooltip_close: "Cerrar chat",
    },

    // COMPONENTNS GAMES
    openQuestion: {
        placeholder: "Respuesta",
        alert_send: " Respuesta guardada ",
        btn_save: "Guardar respuesta",
        answer_text: "Esta es tu respuesta: ",
        text_error: "Debes introducir una respuesta",

    },


    // PICTIONARY
    pictionary: {
        title_1: "Bienvenido/a a ¡Dibújalo!",
        text_1: "Pon a prueba tus habilidades artísticas dibujando un concepto lo mejor posible. \n A continuación te explicamos cómo jugar.",
        title_2: "Dibuja",
        text_2: "Recibirás un concepto y dispondrás de unos segundos para hacer un dibujo que lo represente. Recuerda que los demás jugadores pueden oírte, no reveles nada sobre tu dibujo o tu concepto. No escribas palabras en tu dibujo.",
        title_3: "Adivina",
        text_3: "Cuando termines tu dibujo, este se enviará al resto de participantes para que adivinen lo que es. Tú recibirás los dibujos de tus compañeros y tendrás que seleccionar la respuesta correcta. Recibirás puntos cada vez que alguien adivine tu dibujo y por cada dibujo que tu adivines.",
        draw_top: "¡A dibujar!",
        draw_bottom: "Dibuja el concepto: ",
        guess_top: "Adivina de qué se trata.",
        guess_bottom: "¿Qué concepto crees que está representado en esta obra de arte?",
        results_drew: " dibujó ",
        points_guess_each: " cada vez que un jugador adivina tu dibujo."
    },
    // FACT MATCHING
    factMatching: {
        title_1: "Bienvenido/a a ¿Quién lo dijo?",
        text_1: "Conoce un poco mejor a tus compañeros/as adivinando sus respuestas a diferentes preguntas \xa0 A continuación te explicamos cómo jugar:",
        title_2: "Responde la pregunta        ",
        text_2: "Se hará la misma pregunta a todos los jugadores y cada uno tendrá que dar una respuesta sincera. \xa0 Intenta que tu forma de escribir la respuesta no te identifique demasiado.",
        title_3: "Relaciona las respuestas",
        text_3: "Recibirás las respuestas del resto de jugadores y tendrás que adivinar quién ha escrito cada una. Recibirás puntos por cada respuesta que relaciones con la persona correcta.",
        title_match: "¿Cuánto conoces a tus compañeros/as? Es hora de adivinar sus respuestas.",
        title_results: "Vamos a ver quién conoce mejor a sus compañeros/as.",
        text_results: "contestó",
        no_answer: "No ha respondido",
        users: "Usuarios"
    },

    // MINGLE TINGLE
    mingleTingle: {
        title_1: "Bienvenido/a a Sobremesa Express.",
        text_1: "Conoce un poco mejor a tus compañeros/as hablando sobre diferentes temas que podrían surgir en una sobremesa. \xa0 A continuación te explicamos cómo jugar.",
        title_2: "El tiempo es oro",
        text_2: "Cada pareja dispone de unos segundos para hablar y responder a la pregunta de esa ronda. \xa0 Después de ese tiempo, las parejas se reharán aleatoriamente y se propondrá otra pregunta.",
        title_3: "Una última pregunta para todos",
        text_3: 'Todos los jugadores se vuelven a reunir y tienen que contestar conjuntamente una pregunta del estilo "Quién es más probable que...".',
        title_chat: "Charla con tu compañero/a sobre la siguiente pregunta:",
        title_share: "Compartir con el resto de jugadores.",
        no_answer: "Sin respuesta",
        answer: "La respuesta del equipo es",
        vote_title: "Vota por uno de tus compañeros o compañeras:",
    },

    // WEREWOLF
    wereWolf: {
        title_1: "Bienvenidos/as a Hombre Lobo.",
        text_1: "En tu aldea hay un lobo que cada noche mata a un aldeano. Descúbre quién es antes de que tu aldea quede desolada.",
        title_2: "¿Quién crees que es el lobo?",
        text_2: "Cada noche es una ronda, y en cada ronda el lobo matará en secreto a un aldeano. A la mañana siguiente, si sigues vivo/a, te reunirás con el resto de aldeanos para tratar de descubrir quién es el sanguinario hombre lobo.",
        title_3: "Atrapa al lobo antes de que sea demasiado tarde",
        text_3: "El juego seguirá hasta que el hombre lobo se haya comido a toda la aldea o hasta que descubráis quién es. Con la ayuda del Curandero y el Vidente, tendréis más posibilidades de vencerlo. No desveléis vuestra identidad si no queréis que el hombre lobo vaya a por vosotros.",
        character_title: "Tu personaje es: ",
        character_killer_title: "Hombre Lobo",
        character_killer_text: "Eres el terror de tu aldea. En cada ronda, puedes elegir a quién matar. No seas demasiado obvio/a e intenta no ser descubierto. Saca tu mejor cara de póker. Puedes mentir y decir que eres el Curandero o el Vidente, pero ándate con ojo y que no se descubran tus mentiras",
        character_town_title: "Aldeano",
        character_town_text: "Eres un simple aldeano intentando no ser comido por el hombre lobo en la oscuridad de la noche. Usa tus habilidades de detective para descubrir quién es el hombre lobo antes de que sea demasiado tarde. Puedes mentir y decir que eres el Curandero o el Vidente, pero vigila porque puede ser un arma de doble filo.",
        character_healer_title: "Curandero",
        character_healer_text: "En cada ronda, tienes la posibilidad de curar a ciegas a un aldeano. Si por casualidad (o por inspiración divina) coincide con la persona a la que ha matado el lobo esa noche, todos os mantendréis con vida y no morirá nadie esa noche. Si quieres, puedes desvelar tu identidad pero ándate con ojo: el hombre lobo podría venir a por ti la siguiente noche.",
        character_seer_title: "Vidente",
        character_seer_text: "En cada ronda, tienes la posibilidad de preguntar a los espíritus quién es el hombre lobo. Los espíritus solo responden “sí” o “no”, así que señala a uno de tus compañeros y los dioses te dirán si es el hombre lobo o no. Si quieres, puedes desvelar tu identidad pero ándate con ojo: el hombre lobo podría venir a por ti la siguiente noche",
        action_killer: "Selecciona a tu víctima.",
        action_town: "Espera a que pase la noche y cruza los dedos para que no te coma el lobo.",
        action_healer: "¿A quién quieres proteger de una posible muerte? Esa persona sólo se salvará si el hombre lobo la intenta matar esta misma noche.",
        action_seer: "¿Quién crees que es el hombre lobo?",
        action_result_no_kill: "Nadie ha muerto esta noche. La aldea está de suerte.",
        action_result_kill: " ha sido asesinado.",
        action_result_vident_yes: "Los espíritus están de tu lado. Has señalado correctamente al hombre lobo. La información es poder, úsalo sabiamente.",
        action_result_vident_no: "Los espíritus no están de tu lado esta noche. La persona que has señalado NO es el hombre lobo.",
        vote_phase_title: "¿Quién crees que es el hombre lobo? Vota por uno de tus vecinos o vecinas. El que tenga más votos será eliminado. Si hay un empate, nadie será eliminado y pasaremos a la siguiente ronda.",
        vote_result_no_kill: "Nadie ha sido eliminado.",
        vote_result_kill: " ha sido eliminado democráticamente por la aldea.",
        vote_result_killer_yes: "¡Enhorabuena aldeanos! Habéis descubierto y eliminado al hombre lobo. La aldea puede volver a descansar tranquilamente sin asesinatos.",
        vote_result_killer_no: "Habéis eliminado a uno de los vuestros. El hombre lobo sigue suelto y aterrorizando a la aldea.",
        alert_dead: "Lo sentimos, estás muerto/a. Los muertos no pueden votar.",
        vote: "Votos | Voto | Votos",
        dead: "Eliminado/a",
        win_killer: "El hombre lobo ha ganado y ha eliminado a la aldea.",

    },

    // Seller Sponge
    sellerSponge: {
        title_1: "Bienvenidos/as a Te lo compro.",
        text_1: "Intenta vender a tus colegas un objeto aparentemente inservible. Usa bien tu tiempo para pensar el máximo de usos posibles.",
        title_2: "Empieza la improvisación",
        text_2: "Dispones de un tiempo limitado para intentar convencer a tus compañeros de que compren tu producto al más puro estilo teletienda.",
        title_3: "Fase de votaciones",
        text_3: "Tus compañeros valorarán cuánto les has convencido de comprar tu producto.",
        seller_title: "¡Que empiece la teletienda!",
        seller_vote_text: "En una escala del 0 al 10, ¿cómo de probable es que compres este producto después de la intervención de tu compañero/a?",
        seller_helper_text: "Encuentra maneras diferentes de vender este producto.",
        player_offline: "Este usuario está desconectado.",
    },

    // The Mole
    topo: {
        title_1: "Bienvenidos/as a El Topo.",
        text_1: "A todos los jugadores (menos al topo) se les dice una palabra secreta. En cada ronda, cada jugador debe escribir una palabra relacionada con la palabra secreta. Si eres demasiado obvio, el topo descubrirá la palabra secreta y ganará la partida. Si te alejas demasiado de la palabra, el resto de jugadores pensarán que eres el topo y te eliminarán. ",
        title_2: "Escribe una palabra relacionada con la palabra secreta",
        text_2: "El topo también tiene que escribir una palabra relacionada con la palabra secreta. Usará las palabras proporcionadas por el resto de jugadores para intentar acercarse a la palabra secreta y no ser descubierto.",
        title_3: "¿Quién crees que es el topo?",
        text_3: "Después de ver todas las respuestas, todos los jugadores se ponen de acuerdo para eliminar a quién creen que es el topo. Si eliminan a un jugador que no es el topo, se vuelve a hacer otra ronda con la misma palabra secreta. Hasta que se descubra el topo o el topo adivine la palabra secreta. Si el topo es descubierto, tiene una oportunidad de decir cuál cree que es la palabra secreta. Si la adivina, será el ganador del juego.",
        rol_text: "La palabra secreta es: ",
        rol_text_topo: "Eres el topo.",
        topo_hit: "Eres el topo. Intenta acercarte a la palabra secreta basándote en las palabras de tus compañeros. Buena suerte.",
        no_topo_hit: "No eres el topo. No seas demasiado obvio con tus palabras ni tampoco te alejes demasiado.",
        waiting_to_start: "Ya casi te toca.",
        turn: "Turno del jugador ",
        word: "Palabra relacionada",
        input_word: "Intenta acercarte a la palabra secreta guiándote por las palabras de tus compañeros:",
        player_turn: "Es tu turno",

        vote_title: "Vota al que tenga cara de topo:",
        vote_title_out: "Estás eliminado, espera a que los demás jugadores terminen de votar.",

        topo_quiz__topo_title: "¡Vaya! Te han pillado... Como eres el topo, ahora puedes intentar adivinar la palabra secreta.",
        topo_quiz_title: "Esperando que el topo adivine la palabra...",

        result_player_dead: " ha sido elminado ",
        result_no_topo: "Pero no era un topo...",
        result_topo_no_win: "El topo no ha sido capaz de adivinar la palabra",
        result_topo_count: "Topos restantes: ",
        result_draw: "¡Vaya! Ha habido un empate. Ningún jugador ha sido eliminado. Todos pasamos a la siguiente ronda.",

        final_result_topo_guess: "El topo ha adivinado la palabra. ¡Los topos han ganado!",
        final_result_topo_kills: "Todos los topos han sido eliminados, los jugadores han ganado.",
        final_result_player_dead: "La mayoria de los jugadores han sido eliminados.</br>Los topos <span class='text-primary-100'>{topos}</span> han ganado. | La mayoria de los jugadores han sido eliminados, el topo <span class='text-primary-100'>{topos}</span> ha ganado.",



    },

    quiz: {
        title_1: "Bienvenidos/as a Trivia.",
        text_1: "Responde el máximo de preguntas correctamente sobre un tema concreto.",
        title_2: "+50 puntos",
        text_2: "Por cada respuesta correcta te llevarás 50 puntos.",
        title_3: "Final",
        text_3: "El jugador o jugadora con más puntos al final del juego será el ganador.",
        corrent_answ: "La respuesta correcta es: "
    },

    thisOrThat: {
        title_1: "Bienvenidos/as a Léeme la mente.",
        text_1: "Un jugador o jugadora será seleccionado para responder 6 preguntas (6 rondas) de carácter personal. El resto de los jugadores deberán tratar de adivinar cuál ha sido la respuesta del jugador seleccionado.",
        title_2: "Léele la mente al jugador elegido",
        text_2: "Si aciertas su respuesta, avanzarás a la siguiente ronda. Si fallas, serás eliminado del juego. No des pistas de si sigues vivo o has sido eliminado.",
        title_3: "Ronda final",
        text_3: "Cuando se terminen las 6 rondas, se eliminen todos los jugadores o solo quede un jugador vivo, el jugador elegido podrá intentar adivinar quién ha llegado hasta el final para llevarse la victoria.",
        main_responding: "El jugador <span class='text-primary-100'>{main_player}</span> esta respondiendo",
        main_response: "El jugador <span class='text-primary-100'>{main_player}</span> ha respondido <span class='text-primary-100'>{response}</span>",
        choose: "Escoge una de las dos opciones",
        main_guess: "Jugador <span class='text-primary-100'>{main_player}</span> adivina quien a quedado",
        winner: "El ganador es: | Los ganadores son:",
        main_wins_no_alive_players: "Ningun jugador a conseguido sobrevivir, el jugador principal y por tanto es el ganador del juego.¡Enhorabuena!",
        main_wins_guessing: "El jugador principal ha adivinado qué jugador o jugadores han llegado a la final y por tanto es el ganador del juego.¡Enhorabuena!",
        player_win: "El jugador principal no ha conseguido adivinar qué jugador o jugadores han llegado a la final y por tanto los jugadores que han llegado a la final son los ganadores.¡Enhorabuena!",
    }



}

module.exports = {
    es
}