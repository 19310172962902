<template>
    <div class="main">
        <transition-group enter-active-class="animate__animated animate__flipInX" leave-active-class="animate__animated animate__fadeOut" appear mode="out-in" >
            
            <CardTeam team_name="Equip A" classCard="default"  />
            <CardTeam team_name="Equip B" classCard="default"  />
            <CardTeam team_name="Equip C" classCard="default"  />
            <CardTeam team_name="Equip D" classCard="default"  />
            <CardTeam team_name="Equip E" classCard="default"  />
            <CardTeam team_name="Equip F" classCard="default"  />
        </transition-group>
    </div>
</template>

<script>

    import CardTeam from '@/components/cards/CardTeam'

    export default {
        
        name: 'LobbyPage',

        components:{
            CardTeam
        },

        props:{
            active: Boolean
        },

        data: function(){
            return{

                players: [
                    { classCard: 'self' },
                    { classCard: '' },
                    { classCard: 'mute' },
                    { classCard: 'talking' },
                    { classCard: '' },
                    { classCard: 'empty' },
                    { classCard: 'empty' },
                    { classCard: 'empty' },
                ]

            }
        }



    }

</script>

<style lang="scss" scoped>
    .main{
        width: 100%;
    }

</style>