<template>
    <template v-if="$store.state.storeGameState.gameState == 'lobby'">
        <DynamicLobby />
    </template>
    <template v-else-if="$store.state.storeGameState.ableToPlay">
        <Pictionary v-if="$store.state.storeGameState.game == 'pictionary'" />
        <FactMatching v-else-if="$store.state.storeGameState.game == 'factMatching'" />
        <MingleTingle v-else-if="$store.state.storeGameState.game == 'mingleTingle'" />
        <SellerSponge v-else-if="$store.state.storeGameState.game == 'sellerSponge'" />
        <WereWolf v-else-if="$store.state.storeGameState.game == 'wereWolf'" />
        <Topo v-else-if="$store.state.storeGameState.game == 'topo'" />
        <Quiz v-else-if="$store.state.storeGameState.game == 'quiz'" />
        <DemoGame v-else-if="$store.state.storeGameState.game == 'demoGame'" />
        <ThisOrThat v-else-if="$store.state.storeGameState.game == 'thisOrThat'" />
    </template>
    <template v-else>
        <div class="w-full flex flex-col items-center justify-center">
            <h1 class="text-center">{{ $t("general.game_start") }}</h1>
        </div>
    </template>
</template>

<script>
import Pictionary from "../games/pictionary/Pictionary.vue";
import FactMatching from "../games/factMatching/FactMatching.vue";
import MingleTingle from "../games/mingleTingle/MingleTingle.vue";
import SellerSponge from "../games/sellerSponge/SellerSponge.vue";
import WereWolf from "../games/wereWolf/WereWolf.vue";
import Topo from "../games/topo/Topo.vue";
import Quiz from "../games/quiz/Quiz.vue";
import DynamicLobby from "../dynamicLobby/DynamicLobby.vue";
import DemoGame from "../games/demoGame/DemoGame.vue";
import ThisOrThat from "../games/thisOrThat/ThisOrThat.vue";

export default {
    components: {
        Pictionary,
        FactMatching,
        MingleTingle,
        SellerSponge,
        WereWolf,
        DynamicLobby,
        Topo,
        Quiz,
        DemoGame,
        ThisOrThat,
    },
};
</script>

<style lang="scss" scoped>
.game_content {
    height: 100%;
    width: 100%;
    overflow: hidden;

    background: url("../../assets/img/game_prueba.png") center center;
    background-size: cover;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
</style>
