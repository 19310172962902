<template>
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        appear
        mode="out-in"
        :key="$store.state.storeGameData.mingleTingleData.roomPlayers"
    >
        <div class="flex flex-col gap-5 my-5">
            <h4 class="text-center">
                {{ $t("mingleTingle.title_chat") }}
            </h4>
            <h4 class="text-center text-primary-100">
                {{ $store.state.storeGameData.mingleTingleData.textQuestion }}
            </h4>
            <div class="flex flex-row gap-4 justify-center">
                <div class="" v-for="player in $store.state.storeGameData.mingleTingleData.roomPlayers" :key="player">
                    <div class="userWarap">
                        <CardUser :track="$store.getters.getMediaById(player)" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CardUser from "@/components/cards/CardUser.vue";
import Question from "@/components/gameWidgets/Question.vue";
import QuestionSlider from "@/components/gameWidgets/QuestionSlider.vue";
import Quiz from "@/components/gameWidgets/Quiz.vue";

import { useStore } from "vuex";
import { computed } from "vue";

export default {
    components: {
        CardUser,
        Question,
        QuestionSlider,
        Quiz,
    },

    setup(props) {
        const store = useStore();
        const url = computed(() => store.state.storeGameData.mingleTingleData.currentRoom);

        return {
            url,
        };
    },
};
</script>

<style lang="scss" scoped>
.userWarap {
    width: 25vw;
    :deep(.card_user) {
        width: 25vw;
        height: 25vw;
    }

    // height: 80px+ 10px;;
}

.userWarap-ranking {
    width: 160px;
    :deep(.card_user) {
        width: 80px + 10px;
        height: 80px + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
