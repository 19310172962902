<template>
  <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 0">
    <Tutorial />
  </GameContainer>
  <GameContainer :timer="true" :countdown="true" v-else-if="$store.state.storeGameState.gameStep == 1">
    <ChatMingle/>
  </GameContainer>
  <GameContainer :timer="true" v-else-if="$store.state.storeGameState.gameStep == 2">
    <FinalVote/>
  </GameContainer>
  <GameContainer :timer="true" v-else-if="$store.state.storeGameState.gameStep ==3">
    <FinalRanking 
        :deafultRanking="false" 
        :dataRanking="$store.state.storeGameData.mingleTingleData.finalVotes" 
        :customWord="$t('results.votes')"
      />
  </GameContainer>
  <GameContainer  v-else-if="$store.state.storeGameState.gameStep ==4">
    <FinalFeedback/>
  </GameContainer>
</template>

<script>
import Tutorial from "./Tutorial.vue";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import PhaseRanking from '@/components/gameWidgets/PhaseRanking.vue'
import ChatMingle from './ChatMingle.vue'
import ResultChatMingle from './ResultChatMingle.vue'
import FinalFeedback from '../FinalFeedback.vue'
import FinalVote from './FinalVote.vue'
import FinalRanking from '@/components/gameWidgets/FinalRanking.vue';

export default {
  components: {
    Tutorial,
    GameContainer,
    PhaseRanking,
    ChatMingle,
    ResultChatMingle,
    FinalFeedback,
    FinalVote,
    FinalRanking,
  },


};
</script>

<style>
</style>