<template>
  <div class="card" :class="card_name">
    <p class="m-2"> 
      {{$t('WELCOME2.text_camera')}}
    </p>
    <video class="video" muted autoplay playsinline :srcObject="localStream" v-if="localStream"></video>
    <div v-else>
      <div class="loader">Loading...</div>
    </div>

    <select v-if="media.cameras.length > 0" v-on:change="$store.dispatch('changeSource',key)" v-model="key">
      <option v-for="camera in media.cameras" v-bind:key="camera.id" v-bind:value="camera">{{camera.label}}</option>¡
    </select>
    <p v-else>
      {{$t('WELCOME2.CAM_not_detected')}}
    </p>
  </div>
 
</template>

<script>
import {ref} from 'vue'
export default {
  name: "CardWelcome",

  props: {
    ico: String,
    title: String,
    card_name: String,
    media: Object,
    localStream: Object,
  },

  setup(props)
  {
      const key = ref(props.media.cameras[0])
      return {
          key
      }
  }
};
</script>

<style lang="scss" scoped>
.video {
  width: 25vh;
}
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;

  text-align: center;
  border: 2px solid color(border);

  .volume-indicators {
    margin-top: 24px;

    svg {
      margin: 0 6px;
    }
  }

  select {
    width: 100%;
    margin-top: 32px;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: color(primary);
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: color(primary);
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>