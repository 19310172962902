<template>
    <div class="header">

        <div class="marcador">

            <InlineSvg :src="require('@/assets/svg/trofeo.svg')" />

            <ul>
                <li>
                    <img src="../../assets/img/user_prueba.png" />
                    <div class="name">Raül</div>
                    <div class="score h6">
                        <InlineSvg :src="require('@/assets/svg/moneda.svg')" />
                        300pts
                    </div>
                </li>
                <li>
                    <img src="../../assets/img/user_prueba.png" />
                    <div class="name">Carla</div>
                    <div class="score h6">
                        <InlineSvg :src="require('@/assets/svg/moneda.svg')" />
                        280 pts
                    </div>
                </li>
                <li class="self">
                    <img src="../../assets/img/user_prueba.png" />
                    <div class="name">Tu</div>
                    <div class="score h6">
                        <InlineSvg :src="require('@/assets/svg/moneda.svg')" />
                        210 pts.
                    </div>
                </li>
                <li></li>
                <li></li>
            </ul>

        </div>

    </div>
</template>

<script>

    import InlineSvg from 'vue-inline-svg';

    export default {
        name: 'BaseAlerts',

        components:{
            InlineSvg
        },    
    }

</script>

<style lang="scss" scoped>

    .header{

        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        padding: 12px;

        background: linear-gradient(177.87deg, rgba(241, 241, 241, 0.8) 40.84%, rgba(232, 232, 232, 0.4) 78.31%), #FFFFFF;
        box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.25);

        .marcador{

            display: flex;
            align-items: center;

            & > svg{
                margin-right: 12px;
            }

            ul{

                display: flex;
                justify-content: flex-start;
                align-items: center;
    
                list-style-type: none;

                li{

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-radius: 4px;
                    overflow: hidden;
                    margin-right: 12px;

                    border: 1px solid color(border);

                    img{
                        width: 32px;
                        height: 32px;
                        margin: 0 6px 0 0;
                    }

                    .name{
                        margin-right: 6px;
                    }

                    .score{

                        font-size: 16px;
                        line-height: 16px;
                        padding: 6px 12px;

                    }

                    &.self{
                        border-color: color(primary)
                    }

                }
    
            }

        }


    }

</style>
