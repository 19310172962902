<template>
    <div class="flex flex-col h-full p-10 ">
        <p class="text-center ">
            {{ $t("game.tutorial_wait") }}
        </p>
        <div class="flex flex-col h-full items-center justify-center ">
            <transition enter-active-class="bounce-enter-active " appear :key="tutorialPage">
                <CardTutorial
                    :setStep="setStep"
                    :outStep="outStep"
                    :tutorialPage="tutorialPage"
                    :showNext="content.length"
                    class="flex "
                >
                    <div class="flex flex-col gap-5 w-3/4">
                        <h1>{{ tutoralPageShow.title }}</h1>
                        <p class="text-lg">
                            {{ tutoralPageShow.text }}
                        </p>
                    </div>
                </CardTutorial>
            </transition>
            <div class="pager-wrap m-3 ">
                <TutorialPager :step="tutorialPage" :setStep="setStepFixed" :tutorialLength="content.length" />
            </div>
        </div>
    </div>
</template>

<script>
import TutorialPager from "@/components/tutorial/TutorialPager.vue";
import CardTutorial from "@/components/tutorial/CardTutorial.vue";
import Timer from "@/components/gameWidgets/Timer.vue";
import TutorialLayout from "@/components/tutorial/TutorialLayout.vue";
import { ref, watch } from "vue";

export default {
    props: {
        content: Array,
    },
    components: {
        TutorialPager,
        CardTutorial,
        Timer,
        TutorialLayout,
    },

    setup(props) {
        const tutorialPage = ref(0);
        const tutoralPageShow = ref(props.content[0]);
        const classAnimation = ref("animate__fadeInRight");
        watch(
            () => tutorialPage.value,
            (lastvalue, value) => {
                if (lastvalue > value) {
                    classAnimation.value = "animate__fadeInRight";
                } else {
                    classAnimation.value = "animate__fadeOutLeft";
                }
                tutoralPageShow.value = props.content[tutorialPage.value];
            }
        );
        function setStep(data_in) {
            tutorialPage.value = data_in + 1;
        }
        function outStep(data_in) {
            tutorialPage.value = data_in - 1;
        }
        function setStepFixed(data_in) {
            tutorialPage.value = data_in;
        }

        return {
            tutorialPage,
            setStep,
            outStep,
            tutoralPageShow,
            classAnimation,
            setStepFixed,
        };
    },
};
</script>
<style>
.bounce-enter-active {
    animation: bounce-in 1s;
}
.bounce-leave-active {
    animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
</style>
