<template>
    <div class="welcome" v-if="$store.getters.getMedia.cameras.length > 0">
        <h2 class="h3">{{ $t("WELCOME3.title") }}</h2>
        <p>{{ $t("WELCOME3.text") }}</p>
        <div class="card-group">
            <CardVideoPhoto
                :media="$store.getters.getMedia"
                :localStream="$store.state.storeMedia.localStream"
                :photoTaken="photoTaken"
                :urlBlob="urlBlob"
            />
        </div>
        <div class="buttons-group">
            <BtnBase
                @click="takePhoto()"
                :text="$t('WELCOME3.btnPhoto')"
                classBtn="btn-primary btn-outline"
                v-if="!photoTaken"
            />
            <BtnBase
                @click="resetPhoto()"
                :text="$t('WELCOME3.btnRepeat')"
                classBtn="btn-primary btn-outline"
                v-if="photoTaken"
            />
            <BtnBase @click="nextStep(step)" :text="$t('WELCOME3.btnNext')" :disabled="!photoTaken" />
        </div>
        <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <ModalWelcome v-if="modal" @setModal="setModal" />
        </transition>
    </div>
    <div class="welcome" v-else>
        <h2 class="h3">{{ $t("WELCOME3.text_no_camera") }}</h2>
        <div class="card-group">
            <img class="video" :src="require('@/assets/userImage/' + avatarImgUrl)" alt="" />
        </div>
        <div class="buttons-group">
            <BtnBase @click="nextStep(step)" :text="$t('WELCOME3.continue')" />
        </div>
        <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <ModalWelcome v-if="modal" @setModal="setModal" />
        </transition>
    </div>
</template>

<script>
import CardVideoPhoto from "@/components/cards/CardVideoPhoto";
import BtnBase from "@/components/buttons/BtnBase";
import ModalWelcome from "@/components/modals/ModalWelcome";
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";

export default {
    name: "SetUp",

    components: {
        CardVideoPhoto,
        BtnBase,
        ModalWelcome,
    },

    props: {
        step: Number,
    },

    data: function() {
        return {
            modal: false,
        };
    },

    setup() {
        const { t } = useI18n();
        const playerDataFake = [
            "cat1.jpg",
            "cat2.jpg",
            "cat3.jpg",
            "cat4.jpg",
            "cat5.jpg",
            "cat6.jpg",
            "cat7.jpg",
            "cat8.jpg",
        ];
        function getBlobFromMediaStream(stream) {
            if ("ImageCapture" in window) {
                const videoTrack = stream.getVideoTracks()[0];
                const imageCapture = new ImageCapture(videoTrack);
                return imageCapture.takePhoto();
            } else {
                const video = document.createElement("video");
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                video.srcObject = stream;

                return new Promise((resolve, reject) => {
                    video.addEventListener("loadeddata", async () => {
                        const { videoWidth, videoHeight } = video;
                        canvas.width = videoWidth;
                        canvas.height = videoHeight;

                        try {
                            await video.play();
                            context.drawImage(video, 0, 0, videoWidth, videoHeight);
                            canvas.toBlob(resolve, "image/png");
                        } catch (error) {
                            reject(error);
                        }
                    });
                });
            }
        }

        const store = useStore();
        const localStream = ref(computed(() => store.state.storeMedia.localStream));
        const video = ref(computed(() => store.getters.getMedia));
        const avatarImgUrl = playerDataFake[Math.floor(Math.random() * 8)];
        console.log(video.value.cameras);
        if (video.value.cameras.length == 0) {
            store.commit("setUrlAvatar", +avatarImgUrl);
            const res = fetch(require("@/assets/userImage/" + avatarImgUrl))
                .then((res) => res.blob()) // Gets the response and returns it as a blob
                .then((blob) => {
                    console.log(blob);
                    store.commit("setBlobImg", blob);
                    urlBlob.value = URL.createObjectURL(blob);
                });
        }
        const urlBlob = ref();
        const photoTaken = ref(false);

        store.dispatch("stopMicVol");
        const resetPhoto = () => {
            photoTaken.value = false;
        };

        const takePhoto = () => {
            console.log(localStream.value);
            const mediaStreamTrack = localStream.value.getVideoTracks()[0];
            console.log("in");
            const imageCapture = getBlobFromMediaStream(localStream.value);
            const img = document.querySelector("img");
            console.log(imageCapture);
            imageCapture
                .then((blob) => {
                    console.log(blob);
                    photoTaken.value = true;
                    urlBlob.value = URL.createObjectURL(blob);
                    store.commit("setBlobImg", blob);
                })
                .catch((error) => {
                    console.error("takePhoto() error:", error);
                    notify(
                        {
                            group: "bottom",
                            title: t("Modals.error_title"),
                            text: t("WELCOME3.error_photo"),
                        },
                        10000
                    );
                });
        };
        console.log(localStream.value);
        return {
            takePhoto,
            urlBlob,
            photoTaken,
            resetPhoto,
            avatarImgUrl,
        };
    },

    methods: {
        nextStep: function(var_step) {
            // Pasem a la seguent pagina Welcome
            this.$emit("setStep", var_step + 1);
        },

        setModal: function(modal_status) {
            this.modal = modal_status;
        },
    },
};
</script>

<style scoped lang="scss">
.video {
    width: 350px;
}
.welcome {
    .card-group {
        margin-top: 48px;
        display: flex;
        align-items: stretch;

        :deep(.card) {
            margin: 0 24px;
            min-width: 245px;
        }
    }

    .buttons-group {
        margin-top: 48px;

        :deep(button) {
            margin: 0 12px;
        }
    }

    a,
    a:link,
    a:visited {
        color: color(primary);
        cursor: pointer;
        margin-top: 48px;
    }

    a:hover {
        color: darken(color(primary), 15%);
    }
}
</style>
