import { createApp } from 'vue';

import App from './App.vue';
import store from './store/index.js';
import Notifications from 'notiwind'
import { createI18n } from 'vue-i18n/index'
import './index.css'
import {es} from './locale/es.js'
import {en} from './locale/en.js'
import VueLazyLoad from 'vue3-lazyload'


// import the module
// define the plugin and pass object for config
const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: navigator.language,
    fallbackLocale: 'en', // set fallback locale
    globalInjection: true, 
    messages:{
        'es-ES':es,
        'es':es,
        'en':en,
    }
  })

const loadimage = require('@/assets/img/Spinner-1s-200px.gif')


const app = createApp(App);
app.use(i18n)
app.use(VueLazyLoad, {
  preLoad: 1.3,
  loading: loadimage,
  error: loadimage,
  attempt: 1
})
// Install BootstrapVue
app.use(store);
app.use(Notifications)
app.mount('#app');

