<template>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 0">
        <Tutorial />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 1">
        <WordShow />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 2">
        <VoteTopo />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 3">
        <TopoQuiz />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 4">
        <TopoResult />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 5">
        <TopoFinalResult />
    </GameContainer>
    <GameContainer v-if="$store.state.storeGameState.gameStep == 6">
        <FinalFeedback />
    </GameContainer>
</template>
<script>
import Tutorial from "./Tutorial.vue";
import WordShow from "./WordShow.vue";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import VoteTopo from "./VoteTopo.vue";
import TopoQuiz from "./TopoQuiz.vue";
import TopoResult from "./TopoResult.vue";
import TopoFinalResult from "./TopoFinalResult.vue";
import FinalFeedback from "../FinalFeedback.vue";

export default {
    components: {
        Tutorial,
        WordShow,
        GameContainer,
        VoteTopo,
        TopoQuiz,
        TopoResult,
        TopoFinalResult,
        FinalFeedback,
    },
};
</script>
<style lang="scss"></style>
