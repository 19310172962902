<template>
    <div class="card w-2/4 flex flex-col gap-2" :class="card_name">
        <p class="">
            {{ $t("WELCOME2.text_mic") }}
        </p>
        <inline-svg class="h-12" :src="require('@/assets/svg/micro.svg')" />

        <div class="volume-indicators">
            <inline-svg v-for="index in fireNumber" v-bind:key="index" :src="require('@/assets/svg/logo_volume.svg')" />
            <inline-svg :src="require('@/assets/svg/logo_volume.svg')" :style="{ 'clip-path': firePaint }" />
        </div>
        <select v-on:change="$store.dispatch('changeSource', key)" v-model="key">
            <option
                v-for="(microphone, index) in media.microphones"
                v-bind:key="microphone.id"
                v-bind:value="microphone"
                >{{ microphone.label }}</option
            >
        </select>
        <audio class="player song">
            <source :src="require('@/assets/music/test.mp3')" type="audio/mpeg" />
            Your browser does not support the audio tag.
        </audio>
        <BtnBase
            v-if="play == 0"
            :text="$t('WELCOME2.btn_sound')"
            classBtn="btn-primary btn-xs"
            v-on:click="play_stop"
        />
        <BtnBase
            v-if="play == 1"
            :text="$t('WELCOME2.btn_stop_sound')"
            classBtn="btn-primary btn-xs"
            v-on:click="play_stop"
        />
    </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import BtnBase from "@/components/buttons/BtnBase";

import { watch, ref, onUnmounted } from "vue";
export default {
    name: "CardWelcome",

    components: {
        InlineSvg,
        BtnBase,
    },

    props: {
        ico: String,
        title: String,
        card_name: String,
        media: Object,
        volMic: Number,
    },

    setup(props) {
        const fireNumber = ref(0);
        const firePaint = ref(0);
        const play = ref(0);
        const key = ref(props.media.microphones[0]);
        var audio = new Audio(require("@/assets/music/test.mp3"));
        function play_stop() {
            if (play.value == 0) {
                play.value = 1;
                audio.play();
            } else {
                audio.pause();
                play.value = 0;
            }
        }

        onUnmounted(() => {
            audio.pause();
        });

        watch(
            () => props.volMic,
            () => {
                fireNumber.value = parseInt(props.volMic / 20);
                var temp = parseInt(100 - (props.volMic % 20) * 5);
                firePaint.value = "inset(0% " + temp + "% 0% 0%)";
            },
            () => props.media,
            () => {
                if (props.key == {}) {
                    props.key = props.media.microphones[0];
                }
            }
        );

        return {
            fireNumber,
            firePaint,
            play_stop,
            play,
            key,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;

    text-align: center;
    border: 2px solid color(border);

    .volume-indicators {
        display: flex;
        flex-direction: row;
        margin-top: 24px;

        svg {
            margin: 0 6px;
        }
    }

    select {
        width: 100%;
        margin-top: 32px;
    }
}
.btn {
    margin-top: 32px;
}
</style>
