<template>

  
  <div class="countdown" v-if="display">
    <transition
    class="timer-animation"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
        >

    <div v-if="!$store.state.storeGameState.loadingTimer" class="countdown-number" >{{timeDisplay}}</div>
    <div v-else>
      <img :src="require('@/assets/img/Spinner-1s-200px.svg')" alt="" class="loader">
    </div>
    </transition>
  <svg v-if="display">
    <circle   v-bind:class="{ 'cricle-animation': animation,'zero': zero  }"  r="44" cx="48" cy="48"></circle>
  </svg>
  </div>

  
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import {useStore} from 'vuex'
export default {

    props:{
        time:Number,  
    },

  setup(props) {
    let timer = null;
    let time = 0;
    const timeDisplay = ref("");
    const display = ref(true)
    const animation = ref(false)
    const zero= ref(false)
    const store = useStore();
    const animationTime = ref("0s")
    let refernceTime;
    if(props.time)
    {
        refernceTime = props.time
        console.log("In")
    }
    else
    {
        console.log("In")
        refernceTime = ref(computed(()=>store.state.storeGameState.timerTime))
    }

    function timerFunc()
    {
      clearInterval(timer);
     
      time = refernceTime.value/1000 ;
      timerUpdate();
      timer = setInterval(() => {
        time--;
        timerUpdate();
      }, 1000);
    }

    const timerUpdate=()=>{
      // nextTick();
      var date = new Date(0);
      if(time < 0)
      {
          timeDisplay.value ="0:00"
          animation.value = false;
          zero.value = true;
      }
      else
      {
        zero.value = false;
        date.setSeconds(time); // specify value for SECONDS here
        timeDisplay.value = date.toISOString().substr(15, 4);
      }
      if(time < 10.9 && animation.value== false && time > 0)
      {
        animation.value = true;
        animationTime.value = String(time) + "s";
      }
      else if(time > 10.9)
      {
        animation.value = false;
      }
     
    }

    onMounted(() => {
      timerFunc();
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    watch(refernceTime, () => {
      animation.value = false;
      timerFunc();
    });
    

    return{
        timeDisplay,
        display,
        animation,
        zero,
        animationTime,
    }
  }
};
</script>

<style lang="scss" scoped>


.loader{
    height: 96px;
  width: 96px;
  border-radius: 200px;
}

.countdown {
  position: relative;
  height: 96px;
  width: 96px;
  text-align: center;
  background: #F9F9F9;
  border-radius: 200px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  right: -1%;
}

.countdown-number {
  color: #5F6165;
  display: inline-block;
  line-height: 96px;
  // font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
}

svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 96px;
  height: 96px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

svg circle {
  stroke-dasharray: 301px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 5px;
  stroke: #FF7033;
  fill: none;
  // animation: countdown 10s linear infinite forwards;
}

.cricle-animation{
  animation: countdown v-bind(animationTime) linear infinite forwards;
  animation-iteration-count: 1
}

.zero{
  color:v-bind(animationTime);
  stroke-dasharray: 301px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 0px;
  stroke: #FF7033;
  fill: none;
}

.timer-animation{
  --animate-delay: 0.5s;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 301px;
  }
}
</style>