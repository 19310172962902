<template>
    <div v-if="$store.state.storeGameData.wereWolfData.rol=='town'" class="flex flex-col items-center justify-center">
        <h4 class="text-center p-10" >
            {{$t('wereWolf.action_town')}}
        </h4>
        <img
            :src="require('@/assets/games/wereWolf/Night.svg')" alt=""
            class="w-2/4 mx-auto ">

    </div>
    <div v-else-if="$store.state.storeGameData.wereWolfData.rol=='killer'">
        <div class="flex flex-col gap-10" >
            <h4 class="text-center" >
                {{$t('wereWolf.action_killer')}}
            </h4>
            <UserSelectorVue
                :users="$store.state.storeGameData.wereWolfData.actionPlayers"
                :dataUrl="'/gameDataUser/' + $store.state.storeMedia.idPlayer"
                :parameterDB="'action'"

            />
        </div>
    </div>
    <div v-else-if="$store.state.storeGameData.wereWolfData.rol=='seer'">
        <div class="flex flex-col gap-10" >
            <h4 class="text-center" >
                {{$t('wereWolf.action_seer')}}
            </h4>
            <UserSelectorVue
                :users="$store.state.storeGameData.wereWolfData.actionPlayers"
                :dataUrl="'/gameDataUser/' + $store.state.storeMedia.idPlayer"
                :parameterDB="'action'"

            />
        </div>        
    </div>
    <div v-else-if="$store.state.storeGameData.wereWolfData.rol=='healer'">
        <div class="flex flex-col gap-10" >
            <h4 class="text-center" >
                {{$t('wereWolf.action_healer')}}
            </h4>
            <UserSelectorVue
                :users="$store.state.storeGameData.wereWolfData.actionPlayers"
                :dataUrl="'/gameDataUser/' + $store.state.storeMedia.idPlayer"
                :parameterDB="'action'"

            />
        </div>
        
    </div>
</template>

<script>
import UserSelectorVue from "@/components/gameWidgets/UserSelector.vue";
import { ref } from 'vue'
export default {
    components:{
        UserSelectorVue,
    },

    setup(){
        const selectedUser = ref()

        return{
            selectedUser
        }
    }

    

};
</script>

<style lang="scss" scoped>
.userWarap {
  width: 15vw;
  height: 15vw;
  :deep(.card_user) {
    width: 15vw;
    height: 15vw;
  }

  // height: 80px+ 10px;;
}
</style>