<template>
    <transition
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
        appear
        mode="out-in"
    >
        <div v-if="$store.state.storeGameState.screenShare" class="flex flex-col w-full justify-center">
            <video autoPlay playsinline :srcObject="$store.state.storeMedia.shareScreenTrack"></video>
        </div>
        <div
            class="flex flex-col w-full gap-3"
            v-else-if="bonFireGames.length > 0 && $store.state.storeGameState.gameSelected == 'none'"
        >
            <div class="grid grid-cols-2  gap-2 mx-2 mt-5" v-if="outstandingGame1 && outstandingGame2">
                <CardGame :title="$t('cardGame.game_year')" :bonfireGame="outstandingGame1" />
                <CardGame :title="$t('cardGame.most_played')" :bonfireGame="outstandingGame2" />
            </div>
            <div class="mx-5 my-auto">
                <carousel :wrapAround="true" :items-to-show="2.8">
                    <slide v-for="game in bonFireGames" :key="game" class="mx-5">
                        <CardGame :bonfireGame="game" />
                    </slide>

                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
        </div>
        <div v-else-if="bonFireGames.length > 0" class="w-full my-auto">
            <div class="flex flex-col items-center gap-4 m-4 max-card mx-auto">
                <div class="flex flex-row m-3 items-center">
                    <div class="w-2/4">
                        <img :src="gameSelectedDB.icon" alt="" class="w-3/4" />
                    </div>
                    <div class="flex flex-col gap-5 w-2/4 items-center justify-center">
                        <h2 class="text-center">
                            {{ gameSelectedDB.name }}
                        </h2>
                        <div class="flex flex-row gap-10 font-bold mx-auto">
                            <p>{{ $t("gameSelect.duration") }} {{ gameSelectedDB.duracion }} min</p>
                            <p>{{ gameSelectedDB.grup_size }} {{ $t("general.players") }}</p>
                        </div>
                        <p class="custom-justify">
                            {{ gameSelectedDB.descripcion_princiapl }}
                        </p>
                    </div>
                </div>

                <div class="grid grid-cols-2 w-full">
                    <div class="flex flex-col justify-center w-full items-center" v-if="Categorys.original.length">
                        <p>
                            {{ $t("gameSelect.language") }}
                        </p>
                        <select
                            :disabled="!$store.state.storeMedia.localStream.admin"
                            class="w-48 rounded-lg my-2 text-center h-12 bg-transparent  border-gray-300"
                            v-model="languageContent"
                            v-on:change="resetSelector()"
                        >
                            <option v-for="(lang, key) in $store.state.storeNav.languages" :key="key" :value="key">
                                {{ lang }}
                            </option>
                        </select>
                        <p>
                            {{ $t("gameSelect.theme") }}
                        </p>
                        <div
                            class="w-48 h-12 border border-solid border-gray-300 rounded-lg p-2"
                            v-if="CategoryLoading"
                        >
                            <WaveLoading />
                        </div>

                        <select
                            :disabled="!$store.state.storeMedia.localStream.admin"
                            v-else
                            class="w-48 rounded-lg my-2 text-center h-12 bg-transparent  border-gray-300"
                            v-model="selectedCategory"
                            v-on:change="fetchSubCategory()"
                        >
                            <option value="" class="text-center">{{ $t("gameSelect.placeholder") }}</option>
                            <option v-if="Categorys.custom.length" disabled value=""> --- Customs --- </option>
                            <template v-for="cat in Categorys.custom" :key="cat.id">
                                <option v-if="cat.name != ''" :value="cat.id" class="text-center">{{
                                    cat.name
                                }}</option>
                            </template>
                            <option v-if="Categorys.custom.length" disabled value="">--- Originals --- </option>
                            <template v-for="cat in Categorys.original" :key="cat.id">
                                <option
                                    v-if="cat['name_' + languageContent] != ''"
                                    :value="cat.id"
                                    class="text-center"
                                    >{{ cat["name_" + languageContent] }}</option
                                >
                            </template>
                        </select>
                        <p>
                            {{ $t("gameSelect.option") }}
                        </p>
                        <div
                            class="w-48 h-12 border border-solid border-gray-300 rounded-lg p-2"
                            v-if="subCategoryLoading"
                        >
                            <WaveLoading />
                        </div>

                        <select
                            :disabled="!$store.state.storeMedia.localStream.admin"
                            v-else
                            class="w-48 rounded-lg my-2 text-center bg-transparent  border-gray-300"
                            v-model="selectedSubCategory"
                        >
                            <option value="" class="text-center">{{ $t("gameSelect.placeholder") }}</option>
                            <option
                                v-for="cat in SubCategorys"
                                :key="cat.sub_name"
                                :value="cat.id"
                                class="text-center"
                                >{{ cat.sub_name }}</option
                            >
                        </select>
                    </div>
                    <div class="" v-else>
                        <h4 class="text-center">
                            {{ $t("gameSelect.no_content") }}
                        </h4>
                    </div>
                    <div class="flex flex-col gap-5 items-center justify-center custom-justify">
                        <p>
                            {{ $t("gameSelect.altert_start") }}
                        </p>
                        <div class="flex flex-row gap-3 items-center justify-center">
                            <BtnLoad
                                type="button"
                                class="btn btn-primary btn-outline"
                                :disabled="
                                    !$store.state.storeMedia.localStream.admin ||
                                        (Categorys.original.length > 0 && !selectedSubCategory)
                                "
                                @click="startGame()"
                                :isLoading="isLoading"
                            >
                                {{ $t("general.play") }}
                            </BtnLoad>
                            <BtnLoad
                                type="button"
                                class="btn btn-primary btn-outline"
                                :disabled="!$store.state.storeMedia.localStream.admin"
                                @click="$store.dispatch('backGame', { setLoading }), restartSelector()"
                                :isLoading="isLoading"
                            >
                                {{ $t("general.back") }}
                            </BtnLoad>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from "axios";
import CardGame from "./CardGame.vue";
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { useStore } from "vuex";
import BtnBase from "@/components/buttons/BtnBase.vue";
import BtnLoad from "@/components/buttons/BtnLoad.vue";
import WaveLoading from "@/components/loading/WaveLoading.vue";
import { useI18n } from "vue-i18n";
export default {
    components: {
        CardGame,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        BtnBase,
        BtnLoad,
        WaveLoading,
    },

    setup() {
        const { locale } = useI18n();
        console.log(locale.value);
        const languageContent = ref(locale.value);
        const store = useStore();
        const bonFireGames = ref([]);
        const gameSelectedDB = ref({});
        const subCategoryLoading = ref(true);
        const CategoryLoading = ref(true);
        const Categorys = ref({
            original: [],
            custom: [],
        });
        const SubCategorys = ref([]);
        const selectedCategory = ref("");
        const selectedSubCategory = ref("");
        const outstandingGame1 = ref(null);
        const outstandingGame2 = ref(null);

        let bonfireGamesRaw;
        const gameSelected = computed(() => store.state.storeGameState.gameSelected);

        const isLoading = ref(false);
        const setLoading = () => {
            isLoading.value = !isLoading.value;
            console.log(isLoading);
        };

        const fetchCategory = () => {
            axios
                .get(process.env.VUE_APP_BE_REMOTEBOOST_URL + "content/categoryInGame", {
                    params: {
                        name_id: gameSelectedDB.value.name_id.name_id,
                        url: window.location.pathname.replace("/", ""),
                    },
                })
                .then((response) => {
                    const dataCategory = {
                        custom: [],
                        original: [],
                    };
                    dataCategory.custom = response.data.filter((e) => e.custom == true);
                    dataCategory.original = response.data.filter((e) => e.custom == false);
                    Categorys.value = dataCategory;
                    CategoryLoading.value = false;
                });
        };

        const fetchSubCategory = () => {
            subCategoryLoading.value = true;
            selectedSubCategory.value = "";
            if (selectedCategory.value == "") {
                return;
            }
            axios
                .get(process.env.VUE_APP_BE_REMOTEBOOST_URL + "content/subcategory?id=" + selectedCategory.value)
                .then((response) => {
                    SubCategorys.value = response.data.filter((e) => e.idioma === languageContent.value);
                    subCategoryLoading.value = false;
                });
        };

        const restartSelector = () => {
            CategoryLoading.value = true;
            selectedCategory.value = "";
            Categorys.value = {
                custom: [],
                original: [],
            };
            subCategoryLoading.value = true;
            selectedSubCategory.value = "";
        };

        function shuffle(array) {
            let currentIndex = array.length,
                randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex != 0) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }

            return array;
        }

        watch(
            () => [gameSelected.value, bonFireGames.value],
            () => {
                gameSelectedDB.value = bonFireGames.value.find((e) => e.name_id.name_id == gameSelected.value);
                console.log(gameSelected);
                if (gameSelected.value != "none") {
                    fetchCategory();
                }
            }
        );
        onMounted(() => {
            fetchBonFire();
        });

        watch(
            () => [locale.value],
            () => {
                fetchBonFire();
            }
        );

        const fetchBonFire = () => {
            axios
                .get(process.env.VUE_APP_BE_REMOTEBOOST_URL + "activities/bonfire?language=" + locale.value, {
                    params: {
                        bonfire_url: window.location.pathname.replace("/", ""),
                    },
                })
                .then((response) => {
                    bonFireGames.value = response.data;
                    bonfireGamesRaw = response.data;
                    shuffle(bonFireGames.value);
                    response.data.map((game) => {
                        console.log(game.name_id.most_played);
                        if (game.name_id.most_played) {
                            outstandingGame2.value = game;
                        }
                        if (game.name_id.game_of_the_year) {
                            outstandingGame1.value = game;
                        }
                    });
                });
        };

        const startGame = () => {
            setLoading();
            store.dispatch("startGame", {
                game: store.state.storeGameState.gameSelected,
                category: selectedCategory.value,
                subCategory: selectedSubCategory.value,
                setLoading: setLoading,
            });
        };

        const resetSelector = () => {
            selectedCategory.value = "";
            selectedSubCategory.value = "";
            subCategoryLoading.value = true;
        };
        return {
            bonFireGames,
            gameSelectedDB,
            subCategoryLoading,
            Categorys,
            CategoryLoading,
            selectedCategory,
            fetchSubCategory,
            SubCategorys,
            restartSelector,
            startGame,
            selectedSubCategory,
            outstandingGame1,
            outstandingGame2,
            languageContent,
            resetSelector,
            isLoading,
            setLoading,
        };
    },
};
</script>

<style lang="scss">
.max-card {
    max-width: 900px;
}
.carousel__prev,
.carousel__next {
    background-color: color(primary) !important;
}
.carousel__pagination-button {
    background-color: color(primary, 60) !important;
}

.carousel__pagination-button--active {
    background-color: color(primary) !important;
}
.custom-justify {
    text-align: justify;
    display: inline;
}
</style>
