import { db } from '../../firebase'
import { ref, off, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get } from "firebase/database";

const url = window.location.pathname


const sellerSpongeData = {
    "sellerInfo": {},
    "currentSeller": null,
    "currentVote": 0,
    rankingSeller: [],
    // "top3":[],
    // "under":[],
}

let tempData = {}

const sellerSpongeMutations = {
    sellerSpongeSetAnswer(state, data) {
        if (state.sellerSpongeData.currentSeller) {
            state.sellerSpongeData.currentVote = data[state.sellerSpongeData.currentSeller]
        }
        else {
            state.sellerSpongeData.currentVote = 0;
            tempData = data
        }
    },
    sellerSpongeSetGameDataFact(state, data) {
        console.log(data)
        state.sellerSpongeData.currentSeller = data.currentSeller
        state.sellerSpongeData.sellerInfo = data[data.currentSeller]
        if (tempData) {
            if (state.sellerSpongeData.currentSeller in tempData) {
                state.sellerSpongeData.currentVote = tempData[state.sellerSpongeData.currentSeller]

            }
            else {
                state.sellerSpongeData.currentVote = 0;
            }
        }
        else {
            state.sellerSpongeData.currentVote = 0;
        }




    },
    sellerSpongeSetGameData2Fact(state, data) {

    },
    sellerSpongeSetGameResults(state, data) {
        let tempArray = []
        tempData = {}
        state.sellerSpongeData.currentSeller = null;
        for (const [key2, value2] of Object.entries(data)) {
            let tempDict = data[key2]
            tempDict.idPlayer = key2
            tempArray.push(tempDict)
        }
        function compare(a, b) {
            if (parseFloat(a.pos) > parseFloat(b.pos)) {
                return 1;
            }
            if (parseFloat(a.pos) < parseFloat(b.pos)) {
                return -1;
            }
            return 0;
        }
        tempArray.sort(compare);
        console.log(tempArray, "---------------------------------")

        let result = [];

        for (let i = 0; i < tempArray.length; i++) {
            const player = tempArray[i];

            if (i == 1 && player.pos == 2) {
                result.unshift({ pos: player.pos, player: { points: player.points, idPlayer: player.idPlayer, custom: player.score } });
            } else {
                result.push({ pos: player.pos, player: { points: player.points, idPlayer: player.idPlayer, custom: player.score } });
            }

        }

        state.sellerSpongeData.rankingSeller = result


    }
}

const sellerSpongeActions = {

}

const sellerSpongeStartGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer
    const teamDataDB = ref(db, dataBasePlayerUrl);
    onValue(teamDataDB, (data) => {
        commit("sellerSpongeSetAnswer", data.val())
    });
}
const sellerSpongeFinishGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer
    const teamDataDB = ref(db, dataBasePlayerUrl);
    off(teamDataDB)
}


const sellerSpongeGameDataAdmin = ({ commit, state, rootState }, data) => {
    commit("sellerSpongeSetGameDataFact", data.val())
}

const sellerSpongeGameDataAdmin2 = ({ commit, state, rootState }, data) => {
    commit("sellerSpongeSetGameData2Fact", data.val())
}

const sellerSpongeGameDataResults = ({ commit, state, rootState }, data) => {
    commit("sellerSpongeSetGameResults", data.val())
}
const sellerSpongeGameDataScret = ({ commit, state, rootState }, data) => {
}


export {
    sellerSpongeGameDataAdmin,
    sellerSpongeGameDataAdmin2,
    sellerSpongeGameDataResults,
    sellerSpongeData,
    sellerSpongeMutations,
    sellerSpongeActions,
    sellerSpongeStartGame,
    sellerSpongeFinishGame,
    sellerSpongeGameDataScret
}