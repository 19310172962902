<template>
    <loading :active="isLoading" :is-full-page="true" color="#FF7033" />

    <!-- NavbarTop -->
    <NavbarTop :class="page == 0 ? 'setupNav' : ''" :page="page" @setModal_add="setModal_add" />

    <!-- Pages -->
    <SetUp v-if="page == 0" />
    <GamePage v-else :team_format="team_format" :game_active="game_active" :game_master="game_master" />
    <!-- <LobbyPage v-else-if="page==1" @setPage="setPage" :page="page" /> -->

    <!-- NavbarBottom -->
    <transition
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
        appear
        mode="out-in"
    >
        <NavbarBottom
            v-if="page == 2"
            @setModal_logout="setModal_logout"
            :team_format="team_format"
            @team_format="setTeamFormat"
            :game_active="game_active"
            @game_active="setGameActive"
            :game_master="game_master"
            @game_master="setGameMaster"
            @alert="setAlert"
        />
    </transition>
    <MediaOptions v-if="page != 0" :status="$store.state.storeMedia.mediaOptionsModal" />
    <ModalHost :status="$store.state.storeMedia.modalHost" />
    <ModalAuth :status="$store.state.storeAuth.modalAuth" />
    <Chat :status="$store.state.storeMedia.chat.active" />
    <ModalAdd @setModal="setModal_add" :status="modal_add" />

    <!-- Modal -->
    <ModalBase @setModal="setModal_logout" :status="modal_logout" />
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
    >
        <ModalWelcome v-if="$store.state.storeMedia.permisions == 3" :cantClose="cantClose" />
    </transition>
</template>

<script>
import NavbarTop from "./components/navbars/NavbarTop.vue";
import NavbarBottom from "./components/navbars/NavbarBottom.vue";
import SetUp from "./views/SetUpPage.vue";
import LobbyPage from "./views/LobbyPage.vue";
import GamePage from "./views/GamePage.vue";
import ModalBase from "@/components/modals/ModalBase";
import ModalAdd from "@/components/modals/ModalAdd";
import ModalHost from "@/components/modals/ModalHost";
import MediaOptions from "@/components/modals/MediaOptions.vue";
import ModalAuth from "@/components/modals/ModalAuth.vue";
import Chat from "@/components/chat/Chat.vue";

import { useStore } from "vuex";
import { ref, computed, watch, onMounted } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import Loading from "vue-loading-overlay";
import ModalWelcome from "@/components/modals/ModalWelcome.vue";
import { notify } from "notiwind";

export default {
    name: "App",

    components: {
        NavbarTop,
        NavbarBottom,
        SetUp,
        LobbyPage,
        GamePage,
        ModalBase,
        ModalAdd,
        ModalHost,
        MediaOptions,
        Loading,
        ModalWelcome,
        ModalAuth,
        Chat,
    },

    data: function() {
        return {
            page: 0, // Page: 0 -> Setup / 1 -> Lobby / 2 -> Game
            game_active: 0, // Juego activo: Base --> 0, Pictionary --> 1
            team_format: 0, // Formato del equipo: 4 Players --> 0, 8 Players --> 1, Rivals --> 2, Individuals --> 3
            game_master: false, // El juego tiene Game Master
            modal_logout: false, // Estat del modal (Visible/Hidden)
            modal_add: false, // Estat del modal (Visible/Hidden)
            modalHost: false, // ESTAT DEL MODAL HOST

            alert_data: {
                status: false,
                text: "",
                type: "default",
            },
        };
    },

    setup(props, context) {
        const store = useStore();
        const page = ref(computed(() => store.getters.getPage));
        const isLoading = ref(true);
        const url = window.location.pathname;
        const { t, locale, fallbackLocale, messages } = useI18n();

        const checkRoom = () => {
            let headers = {};
            axios
                .get(process.env.VUE_APP_BE_REMOTEBOOST_URL + "bonfire/bonfireRooms" + url, {
                    headers,
                    params: {
                        checkRoom: true,
                    },
                })
                .then((res) => {
                    isLoading.value = false;
                })
                .catch(function(error) {
                    if (error.response.data.error_id == 3) {
                        isLoading.value = false;
                        store.commit("setPage", 1);
                    }
                });
        };

        onMounted(() => {
            if (localStorage.login) {
                if (localStorage.login == "fail") {
                    notify(
                        {
                            group: "bottom",
                            title: "Login has been fail",
                            text: t("auth.alert_login_fail"),
                        },
                        2000
                    );
                } else if (localStorage.login == "success") {
                    notify(
                        {
                            group: "top",
                            title: "Login success",
                            text: t("auth.alert_login_success"),
                        },
                        2000
                    );
                }
                localStorage.removeItem("login");
            }

            if (window.location.pathname == "/login/google") {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const code = urlParams.get("code");
                store.dispatch("loginGoogle", code);
                return;
            } else {
                localStorage.setItem("lastBonfire", window.location.pathname);
            }

            if (localStorage.user) {
                const headers = { authorization: "Token " + localStorage.user };
                axios
                    .get(process.env.VUE_APP_BE_REMOTEBOOST_URL + "accounts/rol", { headers })
                    .then((res) => {
                        isLoading.value = false;
                        console.log(res.data);
                        store.commit("setUserData", {
                            name: res.data.name,
                            email: res.data.email,
                        });

                        if (res.data.bonfire_setup) {
                            store.commit("setUserImageUrl", res.data.image);
                            store.commit("setPlayerName", res.data.bonfire_name);
                            store.commit("setIdPlayer", res.data.bonfire_id);
                            store.commit("setPage", 1);
                        } else {
                        }
                    })
                    .catch((e) => {
                        localStorage.removeItem("user");
                    });
            } else if (localStorage.AnonymousPlayer) {
                axios
                    .get(
                        process.env.VUE_APP_BE_REMOTEBOOST_URL + "accounts/bonfireUser/" + localStorage.AnonymousPlayer
                    )
                    .then((response) => {
                        isLoading.value = false;
                        store.commit("setUserImageUrl", response.data.image);
                        store.commit("setPlayerName", response.data.bonfire_name);
                        store.commit("setIdPlayer", response.data.bonfire_id);
                        store.commit("setPage", 1);
                    })
                    .catch((e) => {
                        isLoading.value = false;
                        checkRoom();
                    });
            } else {
                checkRoom();
            }

            // set language

            let notInLang = false;
            const currentLanguage = localStorage.getItem("language");
            console.log({ currentLanguage });
            if (currentLanguage) {
                locale.value = currentLanguage;
                for (let lang in messages.value) {
                    if ([lang][0] == locale.value) {
                        notInLang = true;
                    }
                }
                if (!notInLang) {
                    locale.value = "en";
                }
                return;
            }
            for (let lang in messages.value) {
                if ([lang][0] == locale.value) {
                    notInLang = true;
                }
            }
            if (!notInLang) {
                locale.value = "en";
            }

            if (navigator.language == "es-ES") {
                locale.value = "es";
            }
            console.log(locale.value);
        });

        return {
            page,
            isLoading,
        };
    },

    methods: {
        setPage: function(var_page) {
            console.log("in");
            // Pasem a la seguent pagina Welcome
            this.page = var_page;
        },

        setModal_logout() {
            this.modal_logout = !this.modal_logout;
        },

        setModal_add() {
            this.modal_add = !this.modal_add;
        },

        setHost() {
            this.modalHost = !this.modalHost;
        },

        // Activamos la alerta
        setAlert(alert) {
            this.alert_data["status"] = false;

            setTimeout(() => {
                this.alert_data["status"] = alert["status"];
                this.alert_data["text"] = alert["text"];
                this.alert_data["type"] = alert["type"];
            }, 1000);
        },

        // Definimos el tipo de equipos que participan en el juego
        setTeamFormat(team_format) {
            this.team_format = team_format;
        },

        // Definimos el juego activo
        setGameActive(game_active) {
            this.game_active = game_active;
        },

        // Activamos/Desactivamos Game Master
        setGameMaster(game_master) {
            this.game_master = game_master;
        },
    },
};
</script>

<style lang="scss" scoped>
.setupNav {
    @media screen and (max-height: 700px) {
        display: none !important;
    }
}
</style>

<style lang="scss">
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: $font-family;
    color: color(text, 100);

    #app {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
    }
}
</style>
