<template>
  <div class="card card-players-off" :class="card_name">
    <div class="card-players-off-header">
      <div v-if="team" class="text1">
        Queden
        <b>{{$store.getters.getPlayersNoTeam}} jugadors</b> per afegirse a un equip...
      </div>
      <div v-else class="text1">
        Hay un total
        <b>{{$store.getters.getPlayersNoTeam}} jugadors</b>
      </div>

      <div v-if="refernceTime>0" class="text2">
        Falten
        <b>{{timeDisplay}}</b> per començar la partida.
      </div>
      <div v-if="refernceTime<0" class="text2">en breves empezara la partida</div>
    </div>
    <div class="card-players-off-group">
      <div v-for="stream in $store.getters.getAllTracePlayers" v-bind:key="stream.id">
        
        <!-- <CardUser :track="stream" v-if="stream.team =='lobby'" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import CardUser from "@/components/cards/CardUser";
import { useStore } from "vuex";
import { ref, computed, watch, onMounted, onUnmounted} from "vue";
export default {
  name: "PlayersOff",

  components: {
    CardUser
  },

  props: {
    ico: String,
    title: String,
    card_name: String,
    team: Boolean
  },
  setup() {
    let timer = null;
    const store = useStore();
    const refernceTime = computed(
      () => store.state.storeGameState.startTimeSec
    );
    let time = 0;
    const timeDisplay = ref("");

    onMounted(() => {
      clearInterval(timer);
      time = refernceTime.value / 1000;
      timer = setInterval(() => {
        time--;
        var date = new Date(0);
        date.setSeconds(time); // specify value for SECONDS here
        timeDisplay.value = date.toISOString().substr(11, 8);
      }, 1000);
    });

    onUnmounted(()=>{
        clearInterval(timer);
    })

    watch(refernceTime, () => {
      clearInterval(timer);
      time = refernceTime.value / 1000;
      timer = setInterval(() => {
        time--;
        var date = new Date(0);
        date.setSeconds(time); // specify value for SECONDS here
        timeDisplay.value = date.toISOString().substr(11, 8);
      }, 1000);
    });

    return {
      refernceTime,
      timeDisplay
    };
  }
};
</script>

<style lang="scss" scoped>
.card.card-players-off {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 0;
  width: 100%;
  z-index: 2001;

  text-align: center;
  border: none;
  background: linear-gradient(
    88.61deg,
    #f4f5f7 37.76%,
    rgba(244, 245, 247, 0.5) 117.35%
  );
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);

  .card-players-off-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 24px;
  }

  .card-players-off-group {
    overflow: scroll;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: $height-user;
    margin-top: 12px;

    .card_user {
      margin-right: 16px;
      flex: 0 0 120px;

      &:first-child {
        margin-left: 24px;
      }

      &:last-child {
        margin-right: 24px;
      }
    }
  }
}
</style>