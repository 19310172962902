<template>

    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" appear>
        <div v-if="status == true" class="modal">
            
            <transition enter-active-class="animate__animated animate__fadeInDown animate__delay-1s" appear>
                
                <div v-if="status == true" class="modal-content">

                    <div class="modal-body">
                        <inline-svg @click="this.$emit('setModal',false)" :src="require('@/assets/svg/danger.svg')"/>
                        <div class="text">
                            <h2 class="h4">Atenció!</h2>
                            <p>Segur que vols abandonar la partida?</p>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <BtnBase @click="this.$emit('setModal',false)" text="Cancelar" classBtn="btn-light btn-sm" />
                        <BtnBase @click="this.$emit('setPage',1)" text="Abandonar" classBtn="btn-primary btn-sm" />                               
                    </div>

                </div>

            </transition>

        </div>
    </transition>

</template>

<script>

    import InlineSvg from 'vue-inline-svg';
    import BtnBase from '@/components/buttons/BtnBase';

    export default {
        
        name: 'ModalWelcome',

        components:{
            InlineSvg,
            BtnBase
        },

        props:{
            status: Boolean
        },
        
        methods:{

            nextStep: function(var_step){

                // Pasem a la seguent pagina Welcome
                this.$emit('setStep',var_step + 1);
            
            },

        },

    }

</script>

<style lang="scss" scoped>

    .modal{

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        z-index: 3000;

        background: rgba(color(bg,140),0.5);

        &-content{
            max-width: 540px;
            padding: 16px 16px 32px;
            
            background: #FFFFFF;
            box-shadow: 0px 10px 24px #3C3C3C;
            border-radius: 12px;
        }

        &-body{

            display: flex;
            flex-direction: row;
            align-items: center;

            :deep(svg){
                
                height: 56px;
                width: 56px;
                margin:0 4px 0 0;

                path,rect{
                    fill: color(primary);
                }

            }

        }

        &-footer{
            
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 8px;
            margin: 16px 0 0;

        }

        h2{

            width: 100%;

            &:not(:first-child){
                margin-top: 24px;
            }

            & + p{
                margin-top: 0px;
            }
        }

        p{
            font-size: 12px;
            line-height: 14px;
        }

    }

</style>