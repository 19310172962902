import { db } from '../../firebase'
import { ref, off, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get } from "firebase/database";

const url = window.location.pathname


const quizData = {
    question:[],
    answ:[],
    correct:[],
    wrong:[],
    correct_answ:null,
    image:null,
}

let tempData={}

const quizMutations = {
    quizSetAnswer(state,data){

    },
    quizSetGameData(state, data) {
        state.quizData.question = data.questions[data.currentQuestion].question
        state.quizData.answ = data.questions[data.currentQuestion].answers
        state.quizData.image = data.questions[data.currentQuestion].img
    },
    quizSetGameData2(state, data) {
    },
    quizSetGameResults(state, data) {
        state.quizData.correct = data.correct
        state.quizData.wrong =  data.wrong
        state.quizData.correct_answ = data.correct_answ
    },
    quizSetGameDataSecret(state,data){

    }
}

const quizActions = {
}

const quizStartGame =({commit,state,rootState})=>{
    const dataBasePlayerUrl = url + '/gameDataUser/'  + rootState.storeMedia.idPlayer
    const teamDataDB = ref(db, dataBasePlayerUrl);
    onValue(teamDataDB, (data) => {
        commit("quizSetAnswer", data.val())
    });
}
const quizFinishGame =({commit,state,rootState})=>{
    const dataBasePlayerUrl = url + '/gameDataUser/'  + rootState.storeMedia.idPlayer
    const teamDataDB = ref(db, dataBasePlayerUrl);
    off(teamDataDB)
}


const quizGameDataAdmin=({commit,state, rootState},data)=>{
    commit("quizSetGameData", data.val())
}

const quizGameDataAdmin2=({commit,state, rootState},data)=>{
    commit("quizSetGameData2", data.val())
}

const quizGameDataResults=({commit,state, rootState},data)=>{
    commit("quizSetGameResults", data.val())
}

const quizDataScret =({commit,state, rootState},data)=>{
    commit("quizSetGameDataSecret", data.val())
}

export { 
    quizGameDataAdmin,
    quizGameDataAdmin2,
    quizGameDataResults,
    quizData, 
    quizMutations, 
    quizActions,
    quizStartGame,
    quizFinishGame,
    quizDataScret,
}