<template>
  <div class="welcome">
    <slot/>
    <div class="flex flex-row gap-5">
      <BtnBase v-if="tutorialPage > 0" :text="$t('game.btn_before_tutorial')"  v-on:click="outStep(tutorialPage)"/>
      <BtnBase v-if="tutorialPage < showNext -1" :text="$t('game.btn_next_tutorial')"  v-on:click="setStep(tutorialPage)"/>
    </div>

    </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import BtnBase from "@/components/buttons/BtnBase";

export default {
  name: "SetUp",

  components: {
    InlineSvg,
    BtnBase
  },

  props: {
    tutorialPage: Number,
    setStep: Function,
    outStep: Function,
    showNext:  {type:Number},
  },

  methods: {
    
  }
};
</script>

<style scoped lang="scss">
.welcome {
  h1 {
    margin-top: 32px;

    & + p {
      margin-top: 8px;
    }
  }

  button {
    margin-top: 32px;
  }
}
</style>
