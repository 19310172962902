<template>
    <div
        class="container flex flex-col gap-10 overflow-scroll my-auto"
        v-if="mediapermisons == 0 || mediapermisons == 2 || mediapermisons == 3"
    >
        <transition
            enter-active-class="animate__animated animate__fadeInRight"
            leave-active-class="animate__animated animate__fadeOutLeft"
            mode="out-in"
            appear
        >
            <Welcome0 class="" v-if="step == 0" :step="step" @setStep="setStep" />
            <Welcome1 class="" v-else-if="step == 1" :step="step" @setStep="setStep" />
            <Welcome2 class="" v-else-if="step == 2" :step="step" @setStep="setStep" />
            <Welcome3 class="" v-else-if="step == 3" :step="step" @setStep="setStep" />
            <Welcome4 class="" v-else-if="step == 4" :step="step" />
        </transition>

        <div class="pager-wrap">
            <transition
                enter-active-class="animate__animated animate__fadeInUp animate__delay-2s"
                leave-active-class="animate__animated animate__fadeOutDown"
                mode="out-in"
            >
                <PagerWelcome v-show="step >= 1 && step <= 5" :step="step" />
            </transition>
        </div>
    </div>
    <div class="" v-if="mediapermisons == 1">
        <div class=" waitingPermisons">
            <WelcomeErrorPermisons />
        </div>
    </div>
</template>

<script>
import Welcome0 from "../components/cards/CardWelcome0.vue";
import Welcome1 from "../components/cards/CardWelcome1.vue";
import Welcome2 from "../components/cards/CardWelcome2.vue";
import Welcome3 from "../components/cards/CardWelcome3.vue";
import Welcome4 from "../components/cards/CardWelcome4.vue";
import PagerWelcome from "@/components/pager/PagerWelcome.vue";
import WelcomeError from "@/components/cards/WelcomeError.vue";
import WelcomeErrorPermisons from "@/components/cards/WelcomeErrorPermisons.vue";

import { useStore } from "vuex";
import { ref, computed, onMounted, watch } from "vue";
export default {
    name: "SetUp",

    components: {
        Welcome0,
        Welcome1,
        Welcome2,
        Welcome3,
        Welcome4,
        WelcomeError,
        PagerWelcome,
        WelcomeErrorPermisons,
    },

    data: function() {
        return {
            step: 0,
        };
    },

    setup(props, context) {
        const store = useStore();
        const mediapermisons = ref(computed(() => store.getters.getPermisions));
        const step = ref(0);
        const urlImg = ref("");

        onMounted(() => {
            if (step.value == 2) {
                store.dispatch("setUpMedia");
            }
        });

        function setStep(var_step) {
            step.value = var_step;
        }

        watch(step, () => {
            if (step.value == 2) {
                store.dispatch("setUpMedia");
            }
        });

        return {
            mediapermisons,
            setStep,
            step,
            urlImg,
        };
    },
};
</script>

<style scoped lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.container {
    width: 100%;

    // Añadimos un envoltorio para evitar que el elemento
    // desplace el contendor de welcome al aparecer en el Step 1
    .pager-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 100%;
    }
}

.waitingPermisons {
    display: flex;
    position: absolute;
    z-index: 1;
    padding-top: 0px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.8); /* <-- this is the culprit */
    h2 {
        & + p {
            margin-top: 8px;
            display: flex;
            align-items: stretch;
        }
    }
}
</style>
