import { db } from '../../firebase'
import { ref, off, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get } from "firebase/database";

const url = window.location.pathname


const mingleTingleData = {
    "roomPlayers": [],
    "currentRoom": null,
    "typeQuestion": null,
    "textQuestion": null,
    "currentAnswer": null,
    "answerShow": null,
    "resultPlayers": [],
    finalQuestion: "",
    finalVotes: [],
    vote: null,
}

const mingleTingleMutations = {
    mingleTingleSetAnswer(state, data) {
        console.log({ data })
        if (!data) {
            return;
        }
        state.vote = data.vote

    },
    mingleTingleSetGameDataFact(state, data) {
        for (var room in data.data.rooms) {
            if (data.data.rooms[room].findIndex((e) => e == data.idPlayer) >= 0) {
                state.mingleTingleData.currentRoom = room
                state.mingleTingleData.roomPlayers = data.data.rooms[room]
                const dataBasePlayerUrl3 = url + '/gameDataUser/' + room
                const teamDataDB3 = ref(db, dataBasePlayerUrl3);
                get(teamDataDB3).then((snapshot) => {
                    if (snapshot.exists()) {
                        if (snapshot.val().currentAnswer) {
                            state.mingleTingleData.currentAnswer = snapshot.val().currentAnswer
                        }
                        else {
                            state.mingleTingleData.currentAnswer = ""
                        }
                    }
                    else {
                        state.mingleTingleData.currentAnswer = ""
                    }

                })
            }
        }
        state.mingleTingleData.finalQuestion = data.data.finalQuestion
        state.mingleTingleData.textQuestion = data.data.dataQuestion

    },
    mingleTingleSetGameData2Fact(state, data) {
    },
    mingleTingleSetGameResults(state, data) {

        let result = [];

        if (data.votes.length == 0) {
            return result;
        }

        function compare(a, b) {
            if (parseFloat(a.points) > parseFloat(b.points)) {
                return -1;
            }
            if (parseFloat(a.points) < parseFloat(b.points)) {
                return 1;
            }
            return 0;
        }

        data.votes.sort(compare);

        let pos = 1;
        for (let i = 0; i < data.votes.length; i++) {
            const player = data.votes[i];

            if (i != 0) {
                if (player.points != data.votes[i - 1].points) {
                    pos = i + 1;
                }
            }

            if (i == 1 && pos == 2) {
                result.unshift({ pos, player: { idPlayer: player.idPlayer, custom: player.points } });
            } else {
                result.push({ pos, player: { idPlayer: player.idPlayer, custom: player.points } });
            }
        }

        state.mingleTingleData.finalVotes = result

        console.log(result)
    }
}

const mingleTingleActions = {

}

const mingleTingleStartGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    onValue(teamDataDB, (data) => {
        commit("mingleTingleSetAnswer", data.val())
    });
}
const mingleTingleFinishGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    off(teamDataDB)
}


const mingleTingleGameDataAdmin = ({ commit, state, rootState }, data) => {
    commit("mingleTingleSetGameDataFact", {
        data: data.val(),
        idPlayer: rootState.storeMedia.idPlayer
    })
}

const mingleTingleGameDataAdmin2 = ({ commit, state, rootState }, data) => {
}

const mingleTingleGameDataResults = ({ commit, state, rootState }, data) => {
    commit("mingleTingleSetGameResults", data.val())
}
const mingleTingleGameDataScret = ({ commit, state, rootState }, data) => {
}


export {
    mingleTingleGameDataAdmin,
    mingleTingleGameDataAdmin2,
    mingleTingleGameDataResults,
    mingleTingleData,
    mingleTingleMutations,
    mingleTingleActions,
    mingleTingleStartGame,
    mingleTingleFinishGame,
    mingleTingleGameDataScret
}