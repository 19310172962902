<template>
    <div class="card card-team" :class="classCard">
        <div class="card-team-header">
            <div class="header_text">
                <h1 class="h3">{{ team_name }}</h1>
                <p>{{ textCaption }}</p>
            </div>

            <button><inline-svg :src="require('@/assets/svg/menu.svg')" /></button>
        </div>

        <div class="card-team-body">
            <div v-for="stream in $store.getters.getAllTracePlayers" v-bind:key="stream.id">
                <CardUser classCard="talking" :track="stream" v-if="stream.team == team_name" />
            </div>
        </div>

        <div class="card-team-footer">
            <div class="btn-wrap">
                <BtnBase
                    :disabled="classCard == 'default' && $store.getters.getUserTeam == team_name"
                    text="Unir-me"
                    v-on:click="$store.commit('changeTeam', team_name)"
                />
            </div>
            <div v-if="classCard == 'myteam'" class="caption">T’has unit a aquest equip!</div>
        </div>
    </div>
</template>

<script>
import CardUser from "@/components/cards/CardUser.vue";
import InlineSvg from "vue-inline-svg";
import BtnBase from "@/components/buttons/BtnBase";

export default {
    name: "CardWelcome",

    components: {
        CardUser,
        InlineSvg,
        BtnBase,
    },

    props: {
        team_name: String,
        classCard: { type: String, default: "default" },
        players: Array,
    },

    computed: {
        textCaption: function() {
            return this.classCard == "default" ? "Esperant jugadors. Uneix-te ara!" : "8 jugadors. Equip complet";
        },
    },
};
</script>

<style lang="scss" scoped>
.card.card-team {
    padding: 24px;
    margin-top: 32px;

    text-align: left;
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    @media (min-width: $break-point) {
        margin-top: 48px;
    }

    .card-team-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        button {
            cursor: pointer;
            border: 0;
            background: transparent;
        }
    }

    .card-team-body {
        width: $width-user;
        height: $height-user;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 24px;
        margin-top: 32px;
        justify-content: center;
        :deep(.card_user) {
            width: $width-user;
            height: $height-user;
        }
    }

    .card-team-footer {
        width: 100%;
        margin-top: 32px;

        .caption {
            font-weight: bold;
            font-size: 20px;
        }

        .btn-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }

    &.myteam {
        position: relative;
        background: #f4f5f7;
        padding-bottom: 36px;

        &:before {
            content: "";
            position: absolute;
            height: 12px;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 0 0 12px 12px;
            z-index: 1;
            background: linear-gradient(90deg, #ff7033 0%, #faa300 34.38%, #f3b700 73.44%, #fbf7da 100%);
        }
    }

    &.full {
        background: #f4f5f7;
    }

    &.individual {
        .card-team-body {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
</style>
