import { db } from '../firebase'
import { ref, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set } from "firebase/database";
import axios from 'axios'
import { notify } from 'notiwind'
import { syncDbGameStart } from './games/comonGame'


const url = window.location.pathname


const storeGameState = {
    state() {
        return {
            startTime: null,
            gameState: "loading",
            gameType: "loading",
            startTimeSec: 0,
            game: "loading",
            gameStep: 0,
            timerTime: 0,
            error: {
                state: "false",
                error: ""
            },
            rounds: 0,
            currentRound: 0,
            currentGame: null,
            screenShare: false,
            ableToPlay: true,
            loadingTimer: false,
            lastGame: 0, // 0 = default, undefined = out of the game, 1 = in game
            mediaNav: null, // true left navbar with cams appear, false no camera navbar
            demoPhoto: 0,
        }
    },
    getters: {

    },
    mutations: {
        setGeneralInfo(state, data) {
            state.startTime = data.data.startTime
            state.startTimeSec = new Date(data.data.startTime).getTime() - new Date().getTime()
            state.gameState = data.data.gameState
            state.gameType = data.data.gameType
            state.game = data.data.game
            state.loadingTimer = data.data.loading
            state.gameStep = data.data.gameStep
            state.currentRound = data.data.currentRound
            state.rounds = data.data.rounds
            state.timerTime = new Date(data.data.timerTime).getTime() - new Date().getTime()
            state.currentGame = state.game;
            state.gameSelected = data.data.gameSelected;
            state.mediaNav = data.data.mediaNav;
            state.screenShare = data.data.screenShare
            state.playerPlaying = data.data.players
            if (data.data.players) {
                const playerExist = data.data.players.findIndex((e) => e == data.idPlayer)
                if (playerExist >= 0) {
                    state.ableToPlay = true;
                }
                else {
                    state.ableToPlay = false;
                }
            }

            if ((state.lastGame != state.currentGame && state.lastGame != 0)
                || (state.lastGame == 0 && state.currentGame != undefined)) {
                if (state.currentGame == undefined) {
                    notify({
                        group: "top",
                        template: "game_end",
                    }, 2000)
                }
                else {
                    notify({
                        group: "top",
                        template: "game_start",
                    }, 2000)
                }
                state.lastGame = state.currentGame;
            }
            if (data.data.demoPhoto) {
                state.demoPhoto = data.data.demoPhoto;
            }

        },
        setErrorStartGame(state, data) {
            state.error.state = true;
            state.error.errror = data
        }
    },
    actions: {
        syncDB({ commit, state, rootState }) {
            const dataBasePlayerUrl = url + '/generalInfo/'
            const teamDataDB = ref(db, dataBasePlayerUrl);
            onValue(teamDataDB, (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    if (state.currentGame != data.game) {
                        syncDbGameStart({ commit, state, rootState }, state.currentGame, data.game)
                    }
                    commit('setGeneralInfo', {
                        data: data,
                        idPlayer: rootState.storeMedia.idPlayer
                    })

                }

            });
        },
        async startGame({ commit, state, dispatch, rootState }, data) {
            console.log(data)
            if (data.game == 'lobby') {
                if (rootState.storeMedia.screenShare) {
                    dispatch('shareScreen')
                }
                const url = window.location.pathname
                const dataBaseHost = url + '/generalInfo/screenShare'
                set(ref(db, dataBaseHost), false);
            }
            await axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_URL + "game/start" + url, data)
                .then(res => {
                    // token = res.data.token;
                    data.setLoading();
                }).catch(function (error) {
                    console.log(error.response)
                    notify({
                        group: "bottom",
                        template: "more_players",
                        variables: error.response.data.min
                    }, 2000)
                    data.setLoading();
                });
        },
        async skipPhase({ commit, state }, data) {
            return await axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_URL + "game/skipTutorial" + url)
                .then(res => {
                    notify({
                        group: "top",
                        template: "step_skipped",
                    }, 2000)
                }).catch(function (error) {
                    console.log(error.response)
                });
        },
        async skipTutorial({ commit, state }, data) {
            data.setLoading();
            await axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_URL + "game/skipTutorial" + url)
                .then(res => {
                    notify({
                        group: "top",
                        template: "tutorial_skiped",
                    }, 2000)
                    data.setLoading();
                }).catch(function (error) {
                    console.log(error.response)
                    notify({
                        group: "bottom",
                        template: "error_tutorial",
                    }, 2000)
                    data.setLoading();
                });
        },
        selectGame({ commit, state }, data) {
            const url = window.location.pathname
            const dataBaseHost = url + '/generalInfo/gameSelected'
            set(ref(db, dataBaseHost), data);
        },
        backGame({ commit, state }, data) {
            data.setLoading();
            const url = window.location.pathname
            const dataBaseHost = url + '/generalInfo/gameSelected'
            set(ref(db, dataBaseHost), "none");
            data.setLoading();
        }

    }
}

export default storeGameState