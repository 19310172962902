import { db } from '../../firebase'
import { ref, off, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get, remove } from "firebase/database";

const url = window.location.pathname

const pictionaryData = {
    color: "black",
    method: "draw",
    points: [],
    lastcolor: "",
    witdh: 2,
    word: "",
    quiz: [],
    playerIdDraw: null,
    currentGuess: 0,
    pointsDraw: [],
    currentResult: 0,
    wordResult: "",
    playerResultId: null,
    winners: [],
    losers: [],
    customColor: false,
}

const pictionaryMutations = {
    pictionarySetWidth(state, width) {
        if (state.pictionaryData.method == "erase") {
            if (width == 8) {
                state.pictionaryData.witdh = 20;

            }
            else if (width == 5) {
                state.pictionaryData.witdh = 10;
            }
            else if (width == 2) {
                state.pictionaryData.witdh = 6;
            }
        }
        else {
            state.pictionaryData.witdh = width;
        }
    },


    pictionarySetColor(state, color) {
        if (state.pictionaryData.witdh = 20) {
            state.pictionaryData.witdh = 2;
        }
        if (color[0] == '#') {

            state.pictionaryData.color = color
            state.pictionaryData.method = "draw"
            state.pictionaryData.customColor = true
        }
        else {

            state.pictionaryData.color = color
            state.pictionaryData.method = "draw"
            state.pictionaryData.customColor = false
        }
    },
    pictionarySetMethod(state, method) {
        if (method == "erase") {
            state.pictionaryData.lastcolor = state.pictionaryData.color
            state.pictionaryData.color = "white"
            state.pictionaryData.witdh = 20;
            state.pictionaryData.customColor = false
        }
        else {
            state.pictionaryData.color = state.pictionaryData.lastcolor
            state.pictionaryData.witdh = 2;
            if (state.pictionaryData.color[0] == '#') {
                state.pictionaryData.customColor = true
            }
        }
        state.pictionaryData.method = method
    },
    pictionarySetPoints(state, data) {
        const new_point = JSON.parse(data.val())
        console.log(new_point)

        if (data.key == "0" || new_point.lineWidth == 0) {
            state.pictionaryData.points = []

        }
        else {
            state.pictionaryData.points.push(data.val())

        }
    },
    pictionarySetGameData(state, data) {
        state.pictionaryData.word = data.word;
    },
    pictionarySetGameData2(state, data) {
        state.pictionaryData.quiz = data.data[data.idPlayer].guess[data.data.currentGuess].words
        state.pictionaryData.playerIdDraw = data.data[data.idPlayer].guess[data.data.currentGuess].playerID
        state.pictionaryData.currentGuess = data.data.currentGuess
        const dataBasePlayerUrl3 = url + '/gameDataUser/' + state.pictionaryData.playerIdDraw
        const teamDataDB3 = ref(db, dataBasePlayerUrl3);
        get(teamDataDB3).then((snapshot) => {
            if (snapshot.exists() && snapshot.val().points) {
                let tempArray = []
                for (const [key, value] of Object.entries(snapshot.val().points)) {
                    tempArray.push(value)
                }
                state.pictionaryData.pointsDraw = tempArray
            }
            else {
                state.pictionaryData.pointsDraw = []
            }

        });

    },
    pictionarySetGameResults(state, data) {
        state.pictionaryData.wordResult = data.results[data.currentResult].word
        state.pictionaryData.playerResultId = data.results[data.currentResult].playerID
        state.pictionaryData.winners = data.results[data.currentResult].correct
        state.pictionaryData.losers = data.results[data.currentResult].wrong
        const dataBasePlayerUrl3 = url + '/gameDataUser/' + state.pictionaryData.playerResultId
        const teamDataDB3 = ref(db, dataBasePlayerUrl3);
        get(teamDataDB3).then((snapshot) => {
            if (snapshot.exists()) {
                let tempArray = []
                for (const [key, value] of Object.entries(snapshot.val().points)) {
                    tempArray.push(value)
                }
                state.pictionaryData.pointsDraw = tempArray
            }
            else {
                state.pictionaryData.pointsDraw = []
            }
        });
    }
}

const pictionaryActions = {
    pictionarySendPoints({ commit, state, rootState }, data) {
        const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer + "/points"
        const teamDataDB = ref(db, dataBasePlayerUrl);
        const newPostRef = push(teamDataDB);
        set(newPostRef, JSON.stringify(data))
    },
    pictionaryClearPoints({ commit, state, rootState }, data) {
        const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer + "/points"
        const teamDataDB = ref(db, dataBasePlayerUrl);
        remove(teamDataDB)
        const newPostRef = push(teamDataDB);
        set(newPostRef, "{\"color\":\"white\",\"lineWidth\":0,\"points\":[{\"lastx\":0,\"lasty\":0,\"x\":0,\"y\":0}]}")
    }
}

const pictionaryStartGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer + '/points'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    onChildAdded(teamDataDB, (data) => {
        commit("pictionarySetPoints", data)
    });
}
const pictionaryFinishGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/' + rootState.storeMedia.idPlayer + '/points'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    off(teamDataDB)
}

const pictionaryGameDataAdmin = ({ commit, state, rootState }, data) => {
    commit("pictionarySetGameData", data.val()[rootState.storeMedia.idPlayer])
}

const pictionaryGameDataAdmin2 = ({ commit, state, rootState }, data) => {
    commit("pictionarySetGameData2", {
        data: data.val(),
        idPlayer: rootState.storeMedia.idPlayer
    })
}

const pictionaryGameDataResults = ({ commit, state, rootState }, data) => {
    commit("pictionarySetGameResults", data.val())
}
const pictionaryGameDataScret = ({ commit, state, rootState }, data) => {
}


export { pictionaryGameDataAdmin, pictionaryGameDataAdmin2, pictionaryGameDataResults, pictionaryData, pictionaryMutations, pictionaryActions, pictionaryStartGame, pictionaryFinishGame, pictionaryGameDataScret }