<template>
    <GameContainer :timer="true" :countdown="true" v-if="$store.state.storeGameState.gameStep == 0">
        <Tutorial />
    </GameContainer>
    <GameContainer :timer="true" :countdown="true" v-if="$store.state.storeGameState.gameStep == 1">
        <div class="flex flex-col">
            <Question
                :width="'large'"
                :question="$store.state.storeGameData.factMatchingData.question"
                :dataUrl="dataUrl"
                :currentAnswer="$store.state.storeGameData.factMatchingData.currentAnswer"
            />
        </div>
    </GameContainer>
    <GameContainer :timer="true" :countdown="true" v-if="$store.state.storeGameState.gameStep == 2">
        <div class="flex flex-col">
            <FactMatch2 />
        </div>
    </GameContainer>
    <GameContainer :timer="true" :countdown="true" v-if="$store.state.storeGameState.gameStep == 3" class="h-full">
        <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            appear
            mode="out-in"
        >
            <div
                class="flex flex-col gap-10 items-center "
                :key="$store.state.storeGameData.factMatchingData.playerResultId"
            >
                <h4>
                    {{ $t("factMatching.title_results") }}
                </h4>
                <h4 class="m-2 text-center "></h4>
                <div class="flex flex-row justify-center   gap-4 ">
                    <div class="w-3/12 ">
                        <div class="userWarap ml-auto">
                            <CardUser
                                :track="
                                    $store.getters.getUserById(
                                        $store.state.storeGameData.factMatchingData.playerResultId
                                    )
                                "
                                v-if="
                                    $store.getters.getUserById(
                                        $store.state.storeGameData.factMatchingData.playerResultId
                                    )
                                "
                            />
                        </div>
                    </div>
                    <div class="column w-9/12">
                        <h4 class="m-2">
                            {{
                                $store.getters.getUserByIdName(
                                    $store.state.storeGameData.factMatchingData.playerResultId
                                )
                            }}

                            <span v-if="$store.state.storeGameData.factMatchingData.wordResult != 'empty'">
                                {{ $t("factMatching.text_results") }}
                                {{ $store.state.storeGameData.factMatchingData.wordResult }}
                            </span>
                            <span v-else>
                                {{ $t("factMatching.no_answer") }}
                            </span>
                        </h4>
                    </div>
                </div>
                <h4 class="m-4 text-center">+ (50 {{ $t("game.points") }} {{ $t("pictionary.points_guess_each") }})</h4>
                <PhaseRanking
                    class=""
                    :winners="$store.state.storeGameData.factMatchingData.winners"
                    :losers="$store.state.storeGameData.factMatchingData.losers"
                    :pointsPlayers="100"
                    :pointsMainPlayer="50"
                />
            </div>
        </transition>
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 4">
        <FinalRanking />
    </GameContainer>
    <GameContainer v-if="$store.state.storeGameState.gameStep == 5">
        <FinalFeedback />
    </GameContainer>
</template>

<script>
import Tutorial from "./Tutorial";

import Timer from "@/components/gameWidgets/Timer";
import GameRound from "@/components/gameWidgets/GameRound";

import Quiz from "@/components/gameWidgets/Quiz";
import Question from "@/components/gameWidgets/Question.vue";
import FactMatch2 from "@/components/gameWidgets/FactMatch2.vue";

import { useStore } from "vuex";
import { computed, onMounted, watch, ref } from "vue";
import CardUser from "@/components/cards/CardUser";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import PhaseRanking from "@/components/gameWidgets/PhaseRanking.vue";
import FinalRanking from "@/components/gameWidgets/FinalRanking.vue";
import FinalFeedback from "../FinalFeedback.vue";

export default {
    components: {
        Tutorial,
        Timer,
        Quiz,
        CardUser,
        GameRound,
        Question,
        GameContainer,
        FactMatch2,
        FinalRanking,
        PhaseRanking,
        FinalFeedback,
    },

    setup() {
        const store = useStore();

        const idPlayer = computed(() => store.state.storeMedia.idPlayer);

        const dataUrl = ref("/gameDataUser/" + idPlayer.value);
        return {
            dataUrl,
        };
    },
};
</script>

<style lang="scss" scoped>
.row {
    flex: 1 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
}

.fullrow {
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.row2x2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.rowCenter {
    align-items: stretch;
    justify-content: center;
}

.userWarap {
    width: 160px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}

.userWarap-ranking {
    width: 160px;
    :deep(.card_user) {
        width: 80px + 10px;
        height: 80px + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
