<template>
    <div class="welcome flex flex-col gap-3">
        <inline-svg :src="require('@/assets/svg/user.svg')" />
        <h2 class="h3">{{ $t("WELCOME4.title") }}</h2>
        <p class="w-2/4">{{ $t("WELCOME4.text") }}{{ page }}</p>
        <InputPrimary
            :maxlength="String(maxlength)"
            :placehold="$t('WELCOME4.placeholder')"
            :error="error"
            :text_error="$t('WELCOME4.error_input')"
            v-model:valueInput="name_input"
        />
        <BtnLoad
            type="button"
            class="btn btn-primary btn-outline"
            :disabled="name_input == ''"
            @click="setPagetoGame()"
            :isLoading="isLoading"
        >
            {{ $t("WELCOME4.btn") }}
        </BtnLoad>
    </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import BtnLoad from "@/components/buttons/BtnLoad";
import InputPrimary from "@/components/inputs/InputPrimary.vue";
import { useStore } from "vuex";
import { ref } from "vue";
import axios, { post } from "axios";
import { config } from "../../config.js";
import { set } from "firebase/database";

export default {
    name: "SetUp",

    components: {
        BtnLoad,
        InlineSvg,
        InputPrimary,
        config,
    },

    props: {
        step: Number,
        page: Number,
    },

    setup(props, context) {
        const store = useStore();
        store.dispatch("stopMicVol");
        const name_input = ref("");
        const error = ref(false);
        const maxlength = config.maxlength;
        const isLoading = ref(false);
        const setLoading = () => {
            isLoading.value = !isLoading.value;
            console.log(isLoading);
        };

        const uploadUserData = async () => {
            let data = new FormData();
            data.append("name", name_input.value);
            data.append("playerId", store.state.storeMedia.idPlayer);

            data.append("file", store.state.storeMedia.blobImg);

            let config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            if (store.state.storeAuth.user) {
                config.headers.authorization = "Token " + localStorage.user;
            }

            post(process.env.VUE_APP_BE_REMOTEBOOST_URL + "accounts/bonfireUser", data, config)
                .then((response) => {
                    console.log("response", response);
                    store.commit("setUserImageUrl", response.data.image);
                    localStorage.setItem("AnonymousPlayer", response.data.bonfire_id);
                    store.commit("setUserImageUrl", response.data.image);
                    store.commit("setPlayerName", response.data.bonfire_name);
                    store.commit("setIdPlayer", response.data.bonfire_id);
                    store.commit("setPage", 1);
                    setLoading();
                })
                .catch((error) => {
                    console.log("error", error);
                    setLoading();
                });
        };

        async function setPagetoGame() {
            setLoading();
            console.log(name_input);
            if (name_input.value != "") {
                await uploadUserData();
            } else {
                error.value = true;
                setLoading();
            }
        }
        return {
            setPagetoGame,
            ref,
            error,
            name_input,
            uploadUserData,
            isLoading,
            setLoading,
            maxlength,
        };
    },
};
</script>

<style scoped lang="scss">
.welcome {
    button,
    input {
        margin-top: 48px;
    }

    input {
        text-align: center;
    }
}
</style>
