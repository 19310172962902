<template>
    <div class="flex flex-col gap-10 w-full">
        <div>
            <h1 class=" mt-5 mb-0 text-center text-2xl">{{ $t("ranking.title") }}</h1>
            <p class="text-lg text-center">
                {{ $t("game.round") }} {{ $store.state.storeGameState.currentRound }} /
                {{ $store.state.storeGameState.rounds }}
            </p>
        </div>
        <div class="flex flex-row w-full content-between mb-10">
            <div class="w-4/12" v-for="(player, index) in rankingPlayers.slice(0, 3)" v-bind:key="player">
                <div class="userWarap m-auto" :class="rankingData[player.pos - 1].class" v-if="player.player">
                    <img
                        :src="require('@/assets/games/ranking_new/ranking' + (player.pos - 1) + '.svg')"
                        alt=""
                        class="w-3/4 opacity-50 mb-5 flex mx-auto "
                    />
                    <CardUser
                        :track="$store.getters.getUserById(player.player.idPlayer)"
                        class=""
                        :photoMode="true"
                        v-if="index < 3"
                    />
                    <h4 class="text-center m-2">
                        {{ player.pos }} -
                        {{ $store.getters.getUserByIdName(player.player.idPlayer) }}
                    </h4>
                    <h4 v-if="'points' in player.player" class="text-center m-2">
                        {{ player.player.points }} {{ $t("game.points") }}
                    </h4>
                    <h4 v-if="customWord != '' || player.player.custom != ''" class="text-center m-2">
                        {{ player.player.custom }} {{ customWord }}
                    </h4>
                </div>
            </div>
        </div>
        <div
            class="sub-ranking flex flex-row flex-wrap gap-10 p-5 bottom-full justify-center"
            v-if="$store.getters.getNotTopPlayerRanking.length > 0"
        >
            <div v-for="player in rankingPlayers.slice(3)" v-bind:key="player.idPlayer">
                <div class="userWrap-sub flex flex-col items-center gap-1">
                    <CardUser :track="$store.getters.getUserById(player.player.idPlayer)" class="" :photoMode="true" />
                    <p class="text-center">
                        {{ player.pos }} -
                        {{ $store.getters.getUserByIdName(player.player.idPlayer) }}
                    </p>
                    <p v-if="'points' in player.player" class="text-center">
                        {{ player.player.points }} {{ $t("game.points") }}
                    </p>
                    <p v-if="customWord != '' || player.player.custom != ''" class="text-center">
                        {{ player.player.custom }} {{ customWord }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardUser from "@/components/cards/CardUser.vue";
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
export default {
    props: {
        deafultRanking: { type: Boolean, default: true },
        dataRanking: { type: Array, default: [] },
        customWord: { type: String, default: "" },
    },
    components: {
        CardUser,
    },

    setup(props) {
        const store = useStore();
        let rankingPlayers = {};
        let players = computed(() => store.state.storeGameState.playerPlaying);

        if (props.deafultRanking) {
            rankingPlayers = computed(() => store.getters.getRankingPlayers);
        } else {
            rankingPlayers = props.dataRanking;
        }

        const rankingData = [
            {
                class: "first",
                text: "1-",
            },
            {
                class: "second",
                text: "2-",
            },
            {
                class: "third",
                text: "3-",
            },
        ];

        return {
            rankingData,
            rankingPlayers,
        };
    },
};
</script>

<style lang="scss" scoped>
.userWarap {
    width: 160px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}

.userWrap-sub {
    :deep(.card_user) {
        width: 80px;
        height: 80px;
    }

    // height: 80px+ 10px;;
}

.second {
    top: 50px;
    position: relative;
}

.third {
    top: 70px;
    position: relative;
}

.sub-ranking {
    background: #f4f5f7;
    /* Border/20 */

    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}
</style>
