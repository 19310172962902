<template>

        <div class="modal">
            
            <transition enter-active-class="animate__animated animate__fadeInDown animate__delay-1s" leave-active-class="animate__animated animate__fadeOutDown" appear>
                
                <div class="modal-content">
                    <h1 v-if="cantClose" class="text-center">
                        Error
                    </h1>
                    <div class="modal-header">
                        <inline-svg @click="this.$emit('setModal',false)" :src="require('@/assets/svg/close.svg')" v-if="!cantClose" />
                    </div>

                    <div class="modal-body m-2">

                        <h2 class="h4">{{$t('WELCOME2.mic_error_title')}}</h2>
                        <p class="text-justify w-full m-1">{{$t('WELCOME2.mic_error_text')}}</p>
                        <ul>
                            
                            <li v-for="index in  (7)" :key="index" class="m-1">
                                 {{$t('WELCOME2.CAM_error_list.' + index )}}

                            </li>
                        </ul>
                        <h2 class="h4">{{$t('WELCOME2.CAM_error_title')}}</h2>
                        <p class="text-justify w-full m-1">{{$t('WELCOME2.CAM_error_text')}}</p>
                        <ul>
                            
                            <li v-for="index in  (7)" :key="index" class="m-1">
                                 {{$t('WELCOME2.CAM_error_list.' + index )}}

                            </li>
                        </ul>

                        <BtnBase v-if="!cantClose" @click="this.$emit('setModal',false)" text="Tancar" classBtn="btn-primary btn-sm" />                               

                    </div>

                </div>

            </transition>

        </div>

</template>

<script>

    import InlineSvg from 'vue-inline-svg';
    import BtnBase from '@/components/buttons/BtnBase';

    export default {
        
        name: 'ModalWelcome',
        props:{
            cantClose:{type:Number,default:true}
        },
        components:{
            InlineSvg,
            BtnBase
        },
        
        methods:{

            nextStep: function(var_step){

                // Pasem a la seguent pagina Welcome
                this.$emit('setStep',var_step + 1);
            
            },

        },
  

    }

</script>

<style lang="scss" scoped>

    .modal{

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        text-align: justify;
        z-index:9999999999999999;
        background: rgba(color(bg,140),0.5);

        &-content{
            max-width: 540px;
            padding: 16px 16px 32px;
            
            background: #FFFFFF;
            box-shadow: 0px 10px 24px #3C3C3C;
            border-radius: 12px;
        }

        &-header{
            
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 8px;

            svg{
                
                height: 16px;
                widows: 16px;
                cursor: pointer;

            }

        }

        &-body{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        h2{

            width: 100%;

            &:not(:first-child){
                margin-top: 24px;
            }

            & + p,& + ul{
                margin-top: 12px;
            }
        }

    
        ul{
            
            padding: 0 0 0 16px;
            width: 100%;

        }

        .btn{
            margin-top: 32px;
        }

    }

</style>