<template>
    <div class="flex flex-col w-full">
        <h4 class="mb-5" v-html="response" />
        <div class=" userWrap flex flex-row flex-auto w-full items-start justify-center flex-wrap gap-3">
            <div v-for="player in arrayPlayers" v-bind:key="player">
                <div v-if="$store.state.storeGameData.thisOrThatData.main_player === $store.state.storeMedia.idPlayer">
                    <CardUserImgVue
                        :track="$store.getters.getMediaById(player)"
                        :showName="false"
                        :defaultPhoto="true"
                    />
                </div>
                <div v-else>
                    <CardUserImgVue :track="$store.getters.getMediaById(player)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardUserImgVue from "@/components/cards/CardUserImg.vue";
export default {
    components: {
        CardUserImgVue,
    },
    props: {
        response: { type: String, default: "No response recieved" },
        arrayPlayers: { trype: Array, default: [] },
    },
    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.userWrap {
    width: 80px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
