import { db } from '../firebase'
import { ref, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get } from "firebase/database";
import { pictionaryData, pictionaryMutations, pictionaryActions } from './games/pictionaryGame'
import { gameFunctions } from './games/comonGame'
import { factMatchingData, factMatchingMutations, factMatchingActions } from './games/factMatchingGame'
import { mingleTingleData, mingleTingleMutations, mingleTingleActions } from './games/mingleTingleGame'
import { sellerSpongeData, sellerSpongeMutations, sellerSpongeActions } from './games/sellerSpongeGame'
import { wereWolfData, wereWolfMutations, wereWolfActions } from './games/wereWolfGame'
import { topoData, topoMutations, topoActions } from './games/topoGame'
import { quizData, quizMutations, quizActions } from './games/quizGame'
import { thisOrThatData, thisOrThatMutations, thisOrThatActions } from './games/thisOrThatGame'

const url = window.location.pathname




const storePictionary = {
    state() {

        // set all data from all games
        return {
            pictionaryData,
            factMatchingData,
            mingleTingleData,
            sellerSpongeData,
            wereWolfData,
            topoData,
            quizData,
            thisOrThatData,

        }
    },

    mutations: {
        ...pictionaryMutations,
        ...factMatchingMutations,
        ...mingleTingleMutations,
        ...sellerSpongeMutations,
        ...wereWolfMutations,
        ...topoMutations,
        ...quizMutations,
        ...thisOrThatMutations,

    },
    actions: {
        ...pictionaryActions,
        ...factMatchingActions,
        ...mingleTingleActions,
        ...sellerSpongeActions,
        ...wereWolfActions,
        ...topoActions,
        ...quizActions,
        ...thisOrThatActions,
        startGameSync({ commit, state, rootState }) {

            const dataBasePlayerUrl2 = url + '/gameDataAdmin/'
            const teamDataDB2 = ref(db, dataBasePlayerUrl2);
            onValue(teamDataDB2, (data) => {
                if (data.val()) {
                    console.log(rootState.storeGameState.game)
                    if (rootState.storeGameState.game) {
                        gameFunctions[rootState.storeGameState.game].gameDataAdmin({ commit, state, rootState }, data)
                    }
                }
            });
            console.log(rootState.storeMedia.idPlayer)
            const dataBasePlayerUrl5 = url + '/gameDataAdmin/' + rootState.storeMedia.idPlayer
            const teamDataDB5 = ref(db, dataBasePlayerUrl5);

            onValue(teamDataDB5, (data) => {
                if (data.val()) {
                    if (rootState.storeGameState.game) {
                        gameFunctions[rootState.storeGameState.game].gameDataSecret({ commit, state, rootState }, data)
                    }
                }
            });

            const dataBasePlayerUrl3 = url + '/gameDataAdmin2/'
            const teamDataDB3 = ref(db, dataBasePlayerUrl3);
            onValue(teamDataDB3, (data) => {
                if (data.val()) {
                    if (rootState.storeGameState.game) {
                        gameFunctions[rootState.storeGameState.game].gameDataAdmin2({ commit, state, rootState }, data)
                    }
                }

            });

            const dataBasePlayerUrl4 = url + '/gameDataResults/'
            const teamDataDB4 = ref(db, dataBasePlayerUrl4);
            onValue(teamDataDB4, (data) => {
                if (data.val()) {
                    if (rootState.storeGameState.game) {
                        gameFunctions[rootState.storeGameState.game].gameDataResults({ commit, state, rootState }, data)
                    }
                }
            });


        },

    }


}

export default storePictionary