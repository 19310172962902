<template>
    <div class="main">
        <transition enter-active-class="animate__animated animate__flipInX" leave-active-class="animate__animated animate__fadeOut" appear mode="out-in" >
            <CardLobby  team_name="lobby" classCard="myteam individual"  />
        </transition>
    </div>
</template>

<script>

    import CardLobby from '@/components/cards/CardLobby.vue'

    export default {
        
        name: 'LobbyPage',

        components:{
            CardLobby
        },

        props:{
            active: Boolean
        },

        data: function(){
           
        }



    }

</script>

<style lang="scss" scoped>
    .main{
        width: 100%;
        height: 100%;
        overflow-x:auto;
    }
</style>