<template>
    <div class="flex flex-col w-6/12 gap-10 items-center justify-center">
        <div v-if="$store.state.storeGameData.wereWolfData.rol =='town'" class="flex flex-col gap-5">
            <h4 class="text-center">{{$t('wereWolf.character_title')}} <strong>{{$t('wereWolf.character_town_title')}} </strong></h4>
            <p class="text-lg text-center">{{$t('wereWolf.character_town_text')}}</p>
            <img

            :src="require('@/assets/games/wereWolf/Villager.svg')" alt=""
            class="w-2/4 mx-auto ">
        </div>
        <div v-if="$store.state.storeGameData.wereWolfData.rol =='killer'" class="flex flex-col gap-5">
            <h4 class="text-center">{{$t('wereWolf.character_title')}} <strong>{{$t('wereWolf.character_killer_title')}} </strong></h4>
            <p class="text-lg text-center">{{$t('wereWolf.character_killer_text')}}</p>
            <img

            :src="require('@/assets/games/wereWolf/Werewolf.svg')" alt=""
            class="w-2/4 mx-auto ">
        
        </div>
        <div v-if="$store.state.storeGameData.wereWolfData.rol =='healer'" class="flex flex-col gap-5">
            <h4 class="text-center">{{$t('wereWolf.character_title')}} <strong>{{$t('wereWolf.character_healer_title')}} </strong></h4>
            <p class="text-lg text-center">{{$t('wereWolf.character_healer_text')}}</p>
            <img

            :src="require('@/assets/games/wereWolf/Healer.svg')" alt=""
            class="w-2/4 mx-auto ">
        
        </div>
        <div v-if="$store.state.storeGameData.wereWolfData.rol =='seer'" class="flex flex-col gap-5">
            <h4 class="text-center">{{$t('wereWolf.character_title')}} <strong>{{$t('wereWolf.character_seer_title')}} </strong></h4>
            <p class="text-lg text-center">{{$t('wereWolf.character_seer_text')}}</p>
            <img

            :src="require('@/assets/games/wereWolf/Seer.svg')" alt=""
            class="w-2/4 mx-auto ">
        
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>