/**
 * return   2 Ok
 *          1 Waiting
 *          0 Error
 */

async function checkPermisionsMicrophone(commit) {

    navigator.permissions.query({ name: 'microphone' }).then(function (result) {
        if (result.state === 'granted') {
            commit('acceptPermisions')
        } else if (result.state === 'prompt') {
            commit('waitingPermisions')
        } else if (result.state === 'denied') {
            commit('errorPermisions')
        }
    })

}

/**
 * return   2 Ok
*          1 Waiting
*          0 Error
*/

async function checkPermisionsCamera(commit) {

    navigator.permissions.query({ name: 'camera' }).then(function (result) {
        if (result.state === 'granted') {
            commit('acceptPermisions')
        } else if (result.state === 'prompt') {
            commit('waitingPermisions')
        } else if (result.state === 'denied') {
            commit('errorPermisions')
        }
    })

}

/**
* return   2 Ok
*          1 Waiting
*          0 Error
*/

async function getDevicesMedia() {
    return new Promise(resolve => {
        console.log(navigator.mediaDevices)
        navigator.mediaDevices.enumerateDevices().then(devices => {
            console.log(devices)
            resolve(devices = {
                "audio": devices.filter(device => device.kind === 'audioinput'),
                "video": devices.filter(device => device.kind === 'videoinput'),

            })

        })
    })
}


function micLevelRoom() {
    // setInterval(() => {
    //   if (room) {
    //     room.getStats().then((report) => {
    //       console.log(report)
    //       if (report.length > 0) {
    //         report[0].remoteAudioTrackStats.forEach((stat) => {
    //           commit('setMicLevel', {
    //             "micLevel": stat.audioLevel,
    //             "id": stat.trackSid
    //           })
    //         });
    //       }
    //     });
    //   }
    // }, 250)
}

export { checkPermisionsMicrophone, checkPermisionsCamera, getDevicesMedia }