<template>
  <div class="welcome">  
    <section class="card-group">
      <CardWelcome ico="auriculares" :title="$t('WELCOME1.headphones')" :text="$t('WELCOME1.headphones_text')" card_name="card-welcome-1" />
      <CardWelcome ico="wifi" :title="$t('WELCOME1.internet')" :text="$t('WELCOME1.internet_text')" card_name="card-welcome-2" />
      <CardWelcome ico="finder" :title="$t('WELCOME1.window')" :text="$t('WELCOME1.window_text')" card_name="card-welcome-3" />
      <CardWelcome ico="video" :title="$t('WELCOME1.chat')" :text="$t('WELCOME1.chat_text')" card_name="card-welcome-4" />
    </section>
    <BtnBase @click="nextStep(step)" :text="$t('WELCOME1.btn')" />
  </div>
</template>

<script>

  import CardWelcome from '@/components/cards/CardWelcome';
  import BtnBase from '@/components/buttons/BtnBase';

  export default {
    
    name: 'SetUp',

    components: {
        CardWelcome,
        BtnBase,
    },

    props:{
      step:Number
    },

    methods:{

      nextStep: function(var_step){

        // Pasem a la seguent pagina Welcome
        this.$emit('setStep',var_step + 1); 

      },

    },

  }
</script>

<style scoped lang="scss">

  .welcome{

    .card-group {
    
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;

    }

    button{
      margin-top: 64px;
    }

  }
  
  

</style>
