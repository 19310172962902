<template>
    <div class="game-container">
        <div class="game-row">
            <GameRound class="countdown" v-if="countdown" />
            <Timer class="timer" v-if="timer" />
        </div>
        <div class="inside-container">
            <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                appear
                mode="out-in"
            >
                <slot></slot>
            </transition>
        </div>
        <BtnSkip
            type="button"
            v-if="$store.state.storeMedia.localStream.admin && $store.state.storeGameState.currentGame"
            class="btn btn-outline flex relative ml-auto  "
            :class="$store.state.storeGameState.mediaNav ? 'btn-custom' : 'no-nav'"
            :disabled="false"
            @mousedown="startTimer"
            @mouseup="stopTimer"
            :isLoading="false"
        >
            {{ $t("game.skip_step") }}
            <!--<InlineSvg :src="require('@/assets/svg/arrow-alt-circle-right-solid.svg')" />-->
        </BtnSkip>
        <slot name="bottom"></slot>
    </div>
</template>
<script>
import InlineSvg from "vue-inline-svg";
import Timer from "@/components/gameWidgets/Timer";
import GameRound from "@/components/gameWidgets/GameRound.vue";
import BtnSkip from "@/components/buttons/BtnSkip.vue";
import { useStore } from "vuex";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";
export default {
    props: {
        timer: Boolean,
        countdown: Boolean,
    },

    components: {
        Timer,
        GameRound,
        BtnSkip,
        InlineSvg,
    },

    setup() {
        const store = useStore();
        var timer;
        var start;
        const { t } = useI18n();
        const startTimer = () => {
            start = Date.now();
            timer = setTimeout(() => {
                store.dispatch("skipPhase");
            }, 1000);
        };

        const stopTimer = () => {
            if (Date.now() - start < 1000) {
                notify(
                    {
                        group: "bottom",
                        title: t("alerts.no_skipped"),
                        text: t("alerts.no_skipped_text"),
                    },
                    2000
                );
            }

            clearTimeout(timer);
        };

        return {
            stopTimer,
            startTimer,
        };
    },
};
</script>
<style lang="scss" scoped>
.game-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    background: #f9f9f9;
    box-shadow: inset -2px 0px 1px rgba(0, 0, 0, 0.05), inset 0px -2px 1px rgba(0, 0, 0, 0.05),
        inset 1px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 2px 0px 0px 0px;
    border-radius: 16px;
}

.inside-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: static;
    width: 100%;
    height: 100%;
    background: #ffffff;
    /* Border/20 */

    border: 1px dashed #e6e6e6;
    box-sizing: border-box;
}
.game-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-height: 35px;
}
.timer {
    margin-left: auto;
}
.btn-custom {
    @media (max-width: $break-point) {
        @apply mx-auto;
    }
    @media (min-width: $break-point) {
        @apply bottom-6;
    }
}
.no-nav {
    @apply mx-auto;
}
</style>
