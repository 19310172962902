<template>
  <div>
    <div class="grid2x2">
      <div class="buttonContainer" v-for="question in questions" v-bind:key="question">
        <BtnBase :disabled="disabled" v-on:click="updateAnsw(question)"  :classBtn="'btn'" :text ="question" v-bind:class="[dataQuiz.selected == question   ? 'selected' :'btn-outline' ]"/>
      </div>
     </div>
    <!-- <div class="grid1x1center">
      <BtnBase :text="'validate'" />
    
    </div> -->
  </div>
</template>

<script>

/**
  INPUTS: object of:
          title of button
          seleced or not


 */
import BtnBase from "@/components/buttons/BtnBase.vue";
import { ref as vref , onMounted, watch} from 'vue'
import { db} from '../../firebase'
import { ref, off, onValue,onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set } from "firebase/database";


const url = window.location.pathname


export default {
  components: {
    BtnBase,
    
  },

  props:{
    questions:Array,
    disabled:{deafult:false,type:Boolean},


    dataUrl:Object,
    parameterDB:{default:"selected", type:String},

  },

  

  setup(props){
    let lastCallback = null
    const dataQuiz = vref({
        "selected":"",
    });

    watch(()=> props.dataUrl ,()=>{
      off(lastCallback)
      const dataBasePlayerUrl =  url + props.dataUrl
      const teamDataDB = ref(db,dataBasePlayerUrl);
      onValue(teamDataDB, (snapshot) => {
        const data = snapshot.val();
        if(data)
        {
          dataQuiz.value.selected = data[props.parameterDB]
        }
      });
      lastCallback = teamDataDB
    })

    onMounted(() => {
  
      const dataBasePlayerUrl =  url + props.dataUrl
      const teamDataDB = ref(db,dataBasePlayerUrl);
      onValue(teamDataDB, (snapshot) => {
        const data = snapshot.val();
        if(data)
        {
          dataQuiz.value.selected = data[props.parameterDB]
        }
      });
      lastCallback = teamDataDB
    });

    const updateAnsw = (text) => {
      const dataBasePlayerUrl =  url + props.dataUrl
      const teamDataDB = ref(db,dataBasePlayerUrl);
      update(teamDataDB, {
          [props.parameterDB]:text
      })
    };





    return{
      
      dataQuiz,
      updateAnsw,
    }
  }
};
</script>

<style>
.grid2x2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.grid2x2 > div {
  display: flex;
  flex-basis: calc(50% - 10px);
  justify-content: center;
  flex-direction: column;
}

.grid1x1center {
     display: flex;
  justify-content: center;
}

.buttonContainer {
  margin: 5px;
}
</style>