import axios from 'axios';
import { notify } from "notiwind";

const user = localStorage.getItem('user');
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };
const VUE_APP_BE_REMOTEBOOST_URL = process.env.VUE_APP_BE_REMOTEBOOST_URL


const saveLoginData = (state)=>{
    if(state == "success")
    {
        localStorage.setItem('login','success')
    }
    else if (state == 'fail')
    {
        localStorage.setItem('login','fail')
    }
}


const storeAuth = {
    state() {
        return {
            ...initialState,
            loginLoading: false,
            errorLogin: false,
            userInfo: {
                name: null,
                email: null,

            },
            modalAuth: false,

        }
    },

    mutations: {
        loginClean(state) {
            state.loginLoading = true;
            state.errorLogin = false;
        },
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
            
        },
        loginFailure(state) {
            state.loginLoading = false;
            state.status.loggedIn = false;
            state.user = null;
            state.errorLogin = true;
        },
        setUserData(state, data) {
            state.userInfo.name = data.name
            state.userInfo.email = data.email

        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
            localStorage.removeItem("user")
        },
        setModalAuth(state, data) {
            state.modalAuth = data
        }
    },
    actions: {
        login({ commit }, user) {
            commit('loginClean')
            axios
                .post(VUE_APP_BE_REMOTEBOOST_URL + 'api/authentication/login/', {
                    email: user.email,
                    password: user.password
                })
                .then(response => {
                    if (response.data.key) {
                        localStorage.setItem('user', response.data.key)
                    }
                    commit('loginSuccess', response.data.key)
                    location.reload()
                    saveLoginData('success')
                    return Promise.resolve(response.data.key)
                }).catch((e) => {
                    console.log("in")
                    notify(
                        {
                            group: "bottom",
                            template: "wrong_password",
                        },
                        2000
                    );
                    return commit('loginFailure')
                })
        },
        logout({ commit, state }) {
            axios
                .post(VUE_APP_BE_REMOTEBOOST_URL + 'api/authentication/logout/', {}, { headers: { Authorization: 'Bearer ' + state.user } })
                .then(e => {
                    return localStorage.removeItem('user')
                })
            commit('logout');
            location.reload();
        },
        loginGoogle({ commit, state }, data)  {
            axios
                .post(VUE_APP_BE_REMOTEBOOST_URL + "auth/google-bonfire", {
                    code: data
                })
                .then(response => {
                    if (response.data.key) {
                        localStorage.setItem('user', response.data.key)
                    }
                    console.log(localStorage.lastBonfire)
                    commit('loginSuccess', response.data.key)
                    saveLoginData('success')
                    window.location.href = (localStorage.lastBonfire)
                    return Promise.resolve(response.data.key)
                }).catch((e) => {
                    console.log("in")
                    saveLoginData('fail')
                    window.location.href = (localStorage.lastBonfire)
                    return commit('loginFailure')
                })
        }

    }


}

export default storeAuth