<template>

    <div class="card-players">
        
        <!-- <div class="card-players-master" v-if="game_master">
            <CardUser2 name="Carles" :game_master="true" :avatar="'user_game_master'" />
        </div> -->

        <!-- 4 Players -->
        <div class="card-players-team wrap-4-players" v-if="team_format==0" >
            <div v-for="stream in $store.getters.getAllTracePlayers" v-bind:key="stream.id"  >
                <CardUser :track="stream" :showPoints="true"/>
            </div>
            <!-- <CardUser2 v-for="(user,index) in 4" :key="index" /> -->
        </div>
        
        <!-- 8 Players -->
        <!-- <div class="card-players-team" v-else-if="team_format==1" >
            <CardUser2 v-for="(user,index) in 8" :key="index" />
        </div> -->

        <!-- Rivals -->
        <!-- <div class="card-players-rivals" v-else-if="team_format==2" >
            <h6>Team 1</h6>
            <div class="card-players-team" >
                <CardUser2 v-for="(user,index) in 8" :key="index" />
            </div>
            <h6>Team 2</h6>
            <div class="card-players-team" >
                <CardUser2 v-for="(user,index) in 6" :key="index" />
            </div>
            <h6>Team 3</h6>
            <div class="card-players-team" >
                <CardUser2 v-for="(user,index) in 7" :key="index" />
            </div>
            <h6>Team 4</h6>
            <div class="card-players-team" >
                <CardUser2 v-for="(user,index) in 5" :key="index" />
            </div>
            <h6>Team 5</h6>
            <div class="card-players-team" >
                <CardUser2 v-for="(user,index) in 8" :key="index" />
            </div>
            <h6>Team 6</h6>
            <div class="card-players-team" >
                <CardUser2 v-for="(user,index) in 3" :key="index" />
            </div>
            <h6>Team 7</h6>
            <div class="card-players-team" >
                <CardUser2 v-for="(user,index) in 1" :key="index" />
            </div>
        </div> -->

        <!-- Individuals -->
        <!-- <div class="card-players-team" v-else-if="team_format==3">
            <CardUser2 v-for="(user,index) in 48" :key="index" />
        </div> -->

    </div>

</template>

<script>
    
    import CardUser2 from '@/components/cards/CardUser2.vue'
    import CardUser from '@/components/cards/CardUser.vue'

    export default {
      
        name: 'CardTeam',           

        components:{

            CardUser2,
            CardUser
            
        },

        props:{
            game_master: {type:Boolean,default:false},
            team_format: {type:Number,default:0}
        }

    }

</script>

<style lang="scss" scoped>

    .card-players{

        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 0 12px;
        float: left; /* Important per aconseguir el padding right en el scroll horitzontal */

        &-master{
            border-right: 1px solid color(border);
            padding-right: 16px;
            margin-right: 16px;
        }

        .card-players-team{
            
            display: flex;
            flex-direction: row;
            gap:1rem;
            :deep(.card_user:not(:last-of-type)){
                margin-right: 16px;
                margin: 16px;
            }


        }

        .card-players-rivals{

            display: flex;

            @media (min-width:$break-point){
                flex-direction: column;
            }

            .card-players-team:not(:last-child){
                margin-right: 16px;
            }

            h6{
                display: none;
                margin-bottom: 12px;

                @media (min-width:$break-point) {
                    display: block;

                    &:not(:first-of-type){
                        margin-top: 24px;
                    }

                }

            }

        }

    }

</style>