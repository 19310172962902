<template>
    <div class="navbar_tools">
        <ul class="list-color">
            <li  
     
            v-on:click="$store.commit('pictionarySetWidth',siz)" 
            v-for="(siz,id) in size" 
            :key="id" class="list-color-item" 
            :class="{active:$store.state.storeGameData.pictionaryData.witdh==siz},'size'+ siz, 
            $store.state.storeGameData.pictionaryData.color , 
            $store.state.storeGameData.pictionaryData.customColor==true ? 'custom' : '' ,
            {active:$store.state.storeGameData.pictionaryData.witdh == eraseSize[id]} "
        >
        </li>
        </ul>
        <ul class="list-tools">
            <li  
            v-on:click="$store.commit('pictionarySetColor',color);setColorPicker(false)" 
            v-for="(color,id) in colors" 
            :key="id" class="list-color-item" 
            :class="{active:$store.state.storeGameData.pictionaryData.color==color},color">
            </li>
            <li class="flex flex-col items-center justify-center relative palette"
                :class="{active:$store.state.storeGameData.pictionaryData.customColor==true},'custom'"
            
            >
                <inline-svg class="w-3/4 mx-auto " :src="require('@/assets/svg/palette-solid.svg')" v-on:click="()=>{colorPick=!colorPick}" :fill="pickerColor"/>
                <div class="absolute left-16 z-50 w-48 h-96 p-2 border-primary-100 border-2" v-if="colorPick">
                     <ColorPicker alpha-channel="hide" class=""  :visible-formats="['rgb']" :default-format="'hex'"  :color="pickerColor" @color-change="updateColor" >
                     </ColorPicker>                    
                </div>
            </li>
        </ul>
        <ul class="list-tools">
            <li  v-on:click="$store.commit('pictionarySetMethod','draw')" 
            class="list-tools-item" 
            :class="{active:'draw'==$store.state.storeGameData.pictionaryData.method}"><InlineSvg :src="require('@/assets/svg/pencil.svg')" /></li>
            <li  v-on:click="$store.commit('pictionarySetMethod','erase');setColorPicker(false)" 
            class="list-tools-item" 
            :class="{active:'erase'==$store.state.storeGameData.pictionaryData.method}"><InlineSvg :src="require('@/assets/svg/erase.svg')" /></li>
            <li  v-on:click="$store.dispatch('pictionaryClearPoints');" 
            class="list-tools-item" 
            ><InlineSvg :src="require('@/assets/svg/trash-solid.svg')" /></li>
        </ul>
    </div>
</template>

<script>

    import InlineSvg from 'vue-inline-svg'
    import { ColorPicker } from 'vue-accessible-color-picker';
    import { ref} from 'vue'
    import { useStore } from 'vuex';
    export default {
        
        name: 'GameTools',

        components: {
            InlineSvg,
            ColorPicker
        },

        data: function(){
            
            return{

                color_active: 0,

                tool_active: 0,
                colors: ['black','red','green','blue'],
                size:["2","5","8"]

            }

        },

        setup(){
            const eraseSize =[6,10,20]
            const store = useStore();
            const colorPick = ref(false)
            const pickerColor = ref('#f80b')
            const updateColor = (e)=>{
                
                pickerColor.value = e.colors.hex
                store.commit('pictionarySetColor',e.colors.hex)
            }

            const setColorPicker =(data)=>{
                colorPick.value =data
            }

            return{
                colorPick,
                pickerColor,
                updateColor,
                setColorPicker,
                eraseSize,
            }
        }

    }

</script>


<style >
    .vacp-copy-button{
        display: none;
    }
    .vacp-range-input-label-text--hue{
         display: none;
    }
    .vacp-color-inputs{
         display: none;
    }
</style>
<style lang="scss" scoped>
    .navbar_tools{
        --custom-color: v-bind(pickerColor);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        padding: 12px;
        height: 100%;
        flex: 0 0 72px;
        background: linear-gradient(177.87deg, rgba(241, 241, 241, 0.8) 40.84%, rgba(232, 232, 232, 0.4) 78.31%);

    }

    .list-color{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        list-style: none;

        &-item{

            margin: 4px 0;
            padding: 2px;
            border:3px solid transparent;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s all ease-out;

            &::after{
                content: "";
                display: block;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                transition: 0.3s all ease-out;
                background: center center no-repeat;

            }

            &.active{
                &::after{
                    background-image: url('../../assets/svg/check.svg');
                    background-size: 70%;
                }
            }
            &.white{

                &.active{
                    border-color:  color(primary);
                }

                &::after{
                    background-color: rgb(255,255,255);
                    
                }
            }


            &.black{

                &.active{
                    border-color: color(colors,black);
                }

                &::after{
                    background-color: color(colors,black);
                }
            }

            &.red{

                &.active{
                    border-color: color(colors,red);
                }
                
                &::after{
                    background-color: color(colors,red);
                }
            }

            &.green{

                &.active{
                    border-color: color(colors,green);
                }

                &::after{
                    background-color: color(colors,green);
                }
            }

            &.blue{

                &.active{
                    border-color: color(colors,blue);
                }

                &::after{
                    background-color: color(colors,blue);
                }
            }

            &.custom{

                &.active{
                    border-color:var(--custom-color)
                }

                &::after{
                    background-color:var(--custom-color)
                }
            }

            &.size2{
                &::after{
                    width: 16px;
                    height: 16px;
                }

               
            }

            &.size5{
                &::after{
                    width: 24px;
                    height: 24px;
                }

                
            }

        

        }

    }

    .list-tools{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 24px;

        list-style: none;

        &-item{

            display: flex;
            justify-content: center;
            align-items: center;
            margin: 4px 0;
            padding: 2px;
            border:1px solid color(border,100);
            border-radius: 50%;
            cursor: pointer;
            transition: border-width 0.6s linear;
            width: 32px;
            height: 32px;

            &.active{
                border-color: color(border,120);
                border-width: 3px;
                transition: border-width 0.2s linear;
            }

        }

        &::before{
            
            content: "";
            display: block;
            margin-bottom: 24px;
            width: 100%;
            height: 1px;
            background: color(border,120);

        }

    }

</style>