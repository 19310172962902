<template>
    <div
        class="flex flex-col gap-10 text-center w-full p-5"
        v-if="$store.state.storeGameData.topoData.currentPlayer != 'None'"
    >
        <div class="my-2 flex flex-col gap-5">
            <template v-if="$store.state.storeGameData.topoData.currentWord != 'topo'">
                <h4>
                    {{$t('topo.rol_text')}} <span class="text-primary-100 capitalize">
                        {{$store.state.storeGameData.topoData.currentWord}}
                    </span>
                </h4>
                <p>
                    {{$t('topo.no_topo_hit')}}
                </p>

            </template>
            <template v-else>
                <h4>
                    {{$t('topo.rol_text_topo')}}
                </h4>
                <p>
                    {{$t('topo.topo_hit')}}
                </p>
            </template>
        </div>
        <div class="flex flex-row w-full">
            <div class="flex flex-col gap-5 text-center w-6/12 items-center justify-center">
                <div class=" p-5">
                    <h4 v-if="$store.state.storeGameData.topoData.currentPlayer == 'None'">
                        {{$t('topo.waiting_to_start')}}
                    </h4>
                    <div
                        class=""
                        v-if="$store.state.storeGameData.topoData.currentPlayer != 'None'"
                    >
                        <transition
                            enter-active-class="bounce-enter-active "
                            appear
                            :key="$store.state.storeGameData.topoData.currentPlayer"
                        >
                            <h4 v-if="$store.state.storeGameData.topoData.currentPlayer != $store.state.storeMedia.idPlayer">
                                {{$t('topo.turn')}}
                                {{$store.getters.getUserByIdName($store.state.storeGameData.topoData.currentPlayer)}}
                            </h4>
                            <div v-else>
                                <h4>
                                    {{$t('topo.player_turn')}}
                                </h4>
                                <p>
                                    {{$t('topo.input_word')}}
                                </p>
                                <Question
                                    :dataUrl="dataUrl"
                                    :currentAnswer="$store.state.storeGameData.topoData.playerAnswer"
                                    objectKey="word"
                                />
                            </div>
                        </transition>
                    </div>
                </div>

            </div>
            <div class="flex flex-col gap-2 w-6/12 text-center img-border p-5">
                <div class="flex flex-row gap-2 w-full text-justify">
                    <div class="w-2/4 capitalize text-center text-black font-bold">
                        {{$t('game.player')}}:

                    </div>
                    <div class="w-2/4 capitalize text-center text-black font-bold">
                        {{$t('topo.word')}}:

                    </div>
                </div>

                <div
                    class="flex flex-row gap-2 w-full text-justify"
                    v-for="player,index in $store.state.storeGameData.topoData.orderPlayers"
                    :key="index"
                >
                    <div class="w-2/4 truncate text-center ">
                        {{$store.getters.getUserByIdName(player)}}

                    </div>
                    <div class="w-2/4 truncate text-center">
                        {{$store.state.storeGameData.topoData.words[player]}}

                    </div>
                </div>
            </div>

        </div>
    </div>
    <div v-else>
        <div v-if="$store.state.storeGameData.topoData.currentWord != 'topo'" class="flex flex-col justify-center items-center gap-5">
            <h4 class="text-center text-4xl">
                {{$t('topo.rol_text')}} 
                <span class="text-primary-100 capitalize">
                    {{$store.state.storeGameData.topoData.currentWord}}
                </span>
            </h4>
            <p class="text-2xl w-3/4 text-center">
                {{$t('topo.no_topo_hit')}}
            </p>
            <img :src="require('@/assets/games/topo/YoureNotTheMole .svg')" alt=""
            class="w-2/4 ">

        </div>
        <div v-else class="flex flex-col justify-center items-center gap-5">
            <h4 class="text-center text-4xl">
                {{$t('topo.rol_text_topo')}}
            </h4>
            <p class="text-2xl w-3/4 text-center">
                {{$t('topo.topo_hit')}}
            </p>
            <img :src="require('@/assets/games/topo/Youre The Mole.svg')" alt=""
            class="w-2/4 ">
        </div>
    </div>
</template>

<script>
import Question from "@/components/gameWidgets/Question.vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
    components: {
        Question,
    },

    setup() {
        const store = useStore();

        const idPlayer = computed(() => store.state.storeMedia.idPlayer);

        const dataUrl = "/gameDataUser/" + idPlayer.value;
        return {
            dataUrl,
        };
    },
};
</script>
<style lang="scss" scoped>
.img-border {
    border: 2px solid #ff7033;
    border-radius: 12px;
    object-fit: cover;
}
</style>