<template>
  <div class="flex flex-col gap-20">
    <h4 class="titleQuestion text-center">
      {{ question }}
    </h4>
    <div>
      <Slider
        :disabled="disabled"
        v-model="currentAnswer"
        class="slider-red"
        @update="changeSlider()"
        :min="0"
        :max="10"
      />
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import { ref as vref , onMounted, watch} from 'vue'
import { db} from '../../firebase'
import { ref, update} from "firebase/database";

const url = window.location.pathname;

export default {
  components: {
    Slider,
  },

  props: {
    dataUrl: { default: "", type: String },
    question: { default: "", type: String },
    currentAnswer: { default: 0, type: Number },
    disabled:{default:false,type:Boolean},
    parameterDB:{default:"currentAnswer",type:String}
  },

  data(props) {
    const changeSlider = () => {
      const dataBasePlayerUrl = url + props.dataUrl;
      console.log(dataBasePlayerUrl);
      const teamDataDB = ref(db, dataBasePlayerUrl);
      update(teamDataDB, {
          [props.parameterDB]:props.currentAnswer
      })
    };
    return {
      changeSlider,
    };
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.titleQuestion {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}

.slider-red {
  --slider-connect-bg: #ff7033;
  --slider-tooltip-bg: #ff7033;
  --slider-handle-ring-color: #ff703330;
}
</style>