import { db } from '../../firebase'
import { ref, off, onValue, onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set, get } from "firebase/database";

const url = window.location.pathname


const topoData = {
    currentWord: "",
    currentPlayer: "",
    orderPlayers: [],
    words: {},
    playerAnswer: "",
    alivePlayers: [],
    alive: null,
    topoDead: null,
    topoDeadPlayer: null,
    topoQuiz: [],
    guess: false,
    topoCount: 0,
    win: null,
    winners: [],
}

const topoMutations = {
    topoSetAnswer(state, data) {
        let tempDict = {};
        let player_answser = ""
        if (data.data) {
            for (const [key, value] of Object.entries(data.data)) {

                tempDict[key] = value.word

                if (key == data.idPlayer) {

                    player_answser = value.word
                }
            }

        }
        state.topoData.playerAnswer = player_answser
        state.topoData.words = tempDict
    },
    topoSetGameData(state, data) {
        state.topoData.currentWord = data.data[data.idPlayer].word
        if (data.data.currentPlayer == -1) {
            state.topoData.currentPlayer = "None"
        }
        else {
            state.topoData.currentPlayer = data.data.playerWord
        }
        state.topoData.orderPlayers = data.data.orderWord
        state.topoData.alivePlayers = data.data.alivePlayers
        if (data.data.alivePlayers.find(e => e == data.idPlayer)) {
            state.topoData.alive = true
        }
        else {
            state.topoData.alive = false
        }
        state.topoData.topoQuiz = data.data.words_quiz
        state.topoData.topoCount = data.data.topoCount


    },
    topoSetGameData2(state, data) {



    },
    topoSetGameResults(state, data) {
        console.log("in")
        if (data.topo == 'yes') {
            state.topoData.topoDead = true;
        }
        else {
            state.topoData.topoDead = false;
        }
        state.topoData.topoDeadPlayer = data.dead
        state.topoData.guess = data.guess
        state.topoData.win = data.win
        state.topoData.winners = data.winners
    }
}

const topoActions = {

}

const topoStartGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    onValue(teamDataDB, (data) => {
        commit("topoSetAnswer", {
            data: data.val(),
            idPlayer: rootState.storeMedia.idPlayer
        })
    });
}
const topoFinishGame = ({ commit, state, rootState }) => {
    const dataBasePlayerUrl = url + '/gameDataUser/'
    const teamDataDB = ref(db, dataBasePlayerUrl);
    off(teamDataDB)
}


const topoGameDataAdmin = ({ commit, state, rootState }, data) => {
    commit("topoSetGameData", {
        data: data.val(),
        idPlayer: rootState.storeMedia.idPlayer
    })
}

const topoGameDataAdmin2 = ({ commit, state, rootState }, data) => {

    commit("topoSetGameData2",
        data.val(),
    )
}

const topoGameDataResults = ({ commit, state, rootState }, data) => {
    commit("topoSetGameResults", data.val())
}

const topoDataScret = ({ commit, state, rootState }, data) => {
}

export {
    topoGameDataAdmin,
    topoGameDataAdmin2,
    topoGameDataResults,
    topoData,
    topoMutations,
    topoActions,
    topoStartGame,
    topoFinishGame,
    topoDataScret,
}