

const storeNav = {
    state(){
        return{
            page_setup:0,
            page:0,
            languages:{
                'es':"Español",
                'en':"English",
            },
            language:""
        }
    },
    getters:{
        getPage: state =>{
            return state.page
            
        }
    },
    mutations:{
        setPageSetUp(state,page)
        {
            state.page_setup = page
        },
        setPage(state,page){
            state.page = page
        }
    },
    // actions:{
    //     setPage({commit}){
            
    //     }
    // }
}

export default storeNav