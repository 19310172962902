<template>
    <div class="game_page">
        <loading :active="isLoading" :is-full-page="true" color="#FF7033" />
        <template v-if="!isLoading">
            <transition
                enter-active-class="animate__animated animate__fadeInRight"
                leave-active-class="animate__animated animate__fadeOutRight"
                appear
                mode="out-in"
            >
                <div v-if="$store.state.storeGameState.mediaNav" class="players" :class="setTeamFormat()">
                    <div class="players__content">
                        <CardPlayers :game_master="game_master" :team_format="team_format" />
                    </div>
                </div>
            </transition>

            <transition
                enter-active-class="animate__animated animate__fadeInLeft"
                leave-active-class="animate__animated animate__fadeOutLeft"
                appear
                mode="out-in"
            >
                <div :key="$store.state.storeGameState.mediaNav" class="main-content">
                    <div class="card-game">
                        <!-- <GameHeader /> -->
                        <div class="row">
                            <GameManage />
                        </div>
                    </div>
                </div>
            </transition>

            <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
            >
                <ModalUrlError
                    v-if="$store.state.storeMedia.errorUrl.modal"
                    :error="$store.state.storeMedia.errorUrl.error"
                />
            </transition>
        </template>
        <MediaControls v-if="!$store.state.storeMedia.errorUrl.modal" />
        <div class="" v-if="$store.state.storeMedia.permisions == 1">
            <div class=" waitingPermisons">
                <WelcomeErrorPermisons />
            </div>
        </div>
    </div>
</template>

<script>
import GameHeader from "@/components/navbars/HeaderGame";
import GameTools from "@/components/navbars/GameTools";

import CardPlayers from "@/components/cards/CardPlayers.vue";

import GameBase from "@/components/games/GameBase";
import GameManage from "@/components/gameManage/GameManage.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import BtnBase from "@/components/buttons/BtnBase";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ModalUrlError from "@/components/modals/ModalUrlError.vue";
import ModalGameError from "@/components/modals/ModalGameError";
import MediaControls from "@/components/mediaControls/MediaControls.vue";
import WelcomeErrorPermisons from "@/components/cards/WelcomeErrorPermisons.vue";
import Chat from "@/components/chat/Chat.vue";

export default {
    name: "GamePage",

    components: {
        GameHeader,
        GameTools,

        CardPlayers,
        GameManage,

        BtnBase,
        Loading,
        ModalUrlError,
        ModalGameError,
        MediaControls,
        WelcomeErrorPermisons,

        Chat,
    },

    props: {
        team_format: { type: Number, default: 0 },
        game_active: { type: Number, default: 0 },
        game_master: { type: Boolean, default: false },
    },

    data: function() {
        return {};
    },

    setup() {
        const store = useStore();
        const isLoading = ref(computed(() => store.getters.getloadingStart));
        const url = window.location.pathname;

        onMounted(() => {
            store.dispatch("connect", {
                url,
            });
            store.dispatch("syncDB");
            store.dispatch("startGameSync");
        });
        return {
            isLoading,
        };
    },

    methods: {
        setTeamFormat() {
            // Formato del equipo: 4 Players --> 0, 8 Players --> 1, Rivals --> 2, Individuals --> 3

            switch (this.team_format) {
                case 0:
                    return "team_2_columns";
                case 1:
                    return "team_3_columns";
                case 2:
                    return "team_rivals";
                case 3:
                    return "team_individuals";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.waitingPermisons {
    display: flex;
    position: absolute;
    z-index: 1;
    padding-top: 0px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.8); /* <-- this is the culprit */
    z-index: 99999999999;
    h2 {
        & + p {
            margin-top: 8px;
            display: flex;
            align-items: stretch;
        }
    }
}

.game_page {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;
    overflow: hidden;

    background: color(bg, 60);

    @media (min-width: $break-point) {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 0.75rem;
        flex-direction: row;
        padding-top: 12px;
    }
}
@media (min-width: $break-point) {
    .players::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .players {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .players__content::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .players__content {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

.players {
    padding: 24px 0 0;
    width: 100%;

    @media (min-width: $break-point) {
        order: 1;
        padding: 0;
        height: 100%;
        overflow: scroll;
    }

    &__content {
        overflow-y: hidden;
        width: 100%;
        overflow-x: scroll;

        :deep(.card-players) {
            @media (min-width: $break-point) {
                flex-direction: column;
            }

            .card-players-master {
                @media (min-width: $break-point) {
                    width: 100%;
                    padding: 0;
                    margin: 0 0 24px;
                    border: none;
                }

                .card_user {
                    width: 100%;
                    height: 256px;
                }
            }
        }

        :deep(.card-players-team) {
            @media (min-width: $break-point) {
                display: grid;
            }
        }

        :deep(.card_user) {
            width: $width-user + 10px;
            height: $width-user + 10px;
        }
    }

    &.team_2_columns {
        @media (min-width: $break-point) {
            :deep(.card-players-team) {
                grid-template-columns: $width-user + 10px $width-user + 10px;
            }
        }
    }

    &.team_3_columns {
        @media (min-width: $break-point) {
            :deep(.card-players-team) {
                grid-template-columns: $width-user + 10px $width-user + 10px $width-user + 10px;
            }
        }
    }

    &.team_rivals {
        @media (min-width: $break-point) {
            :deep(.card_user) {
                width: 88px;
                height: 88px;
            }

            :deep(.card-players-team) {
                grid-template-columns: 88px 88px 88px 88px;
            }
        }
    }

    &.team_individuals {
        @media (min-width: $break-point) {
            :deep(.card_user) {
                width: 88px;
                height: 88px;
            }

            :deep(.card-players-team) {
                grid-template-columns: 88px 88px 88px 88px;
            }
        }
    }
}

.main-content::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.main-content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.main-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    flex: 1 1 auto;
    overflow: scroll;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    @media (min-width: $break-point) {
        order: 0;
        padding: 0;
    }

    .card-game {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        flex: 1 1 auto;
        border-radius: 16px;
        // overflow: hidden;
        border: 1px solid color(border);
    }

    .row {
        flex: 1;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
    }
}
</style>
