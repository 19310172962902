<template>
  <button :disabled="disabled" class="btn rounded-xl" :class="classBtn">{{ text }}</button>
</template>

<script>
export default {
  props: {
    text: { type: String, default: "Button Text" },
    classBtn: { type: String, default: "btn-primary" },
    disabled: { deafult: false, type: Boolean },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 12px 24px;

  border: none;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  border: 2px solid transparent;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.15);

  &.btn-outline {
    background: transparent;
    border: 2px solid color(primary);
    color: color(primary);

    &:hover {
      border-color: darken(color(primary), 15%);
      background-color: color(primary);
      color: #fff;
    }

    &:disabled,
    &[disabled] {
      background-color: #cccccc;
      color: #666666;
    }
  }

  &.btn-primary {
    background: color(primary, base);
    color: color(text, base);

    &:hover {
      background: darken(color(primary), 15%);
    }
    &:disabled,
    &[disabled] {
      background-color: #cccccc;
      color: #666666;
    }
  }

  &.btn-light {
    background: color(grays, 200);
    color: color(text, 100);
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background: color(grays, 400);
      color: color(text, base);
    }
  }

  &.btn-xxs {
    padding: 8px 12px;

    font-size: 8px;
    line-height: 8px;
  }

  &.btn-xs {
    padding: 10px 16px;

    font-size: 12px;
    line-height: 12px;
  }

  &.btn-sm {
    padding: 12px 24px;

    font-size: 16px;
    line-height: 16px;
  }

  &.btn-md {
    padding: 12px 24px;

    font-size: 24px;
    line-height: 24px;
  }

  &.btn-lg {
    padding: 16px 32px;

    font-size: 32px;
    line-height: 32px;
  }
}

//quiz class
.selected {
  background-color: color(primary);
  color: #fff;
}
</style>