<template>
    <div class="header_lobby">
        <h1>Sala de espera...</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
    </div>
</template>

<style lang="scss" scoped>
    .header_lobby{
        width: 100%;
    }
</style>