<template>
    <button
        class="btn tooltip"
        :class="[classBtn ,{'btn-active':activeIcon}]"
    >
        <InlineSvg :src="require('@/assets/svg/'+ico+'.svg')" />
        <p  v-if="toolTip" 
            v-bind:class="{toolTipTop}"
            class="tooltiptext">
        {{toolTip}}</p>
    </button>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
    name: "BtnIco",

    props: {
        ico: { type: String, default: "Button Text" },
        classBtn: { type: String, default: "btn-primary" },
        activeIcon: { type: Boolean, default: false },
        toolTip: { type: String, default: "" },
        toolTipTop:{ type:Boolean, default:false}
    },
    components: {
        InlineSvg,
    },
};
</script>

<style lang="scss" scoped>
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    top: 100%;
    left: 50%;
    margin-left: -150%; /* Use half of the width (120/2 = 60), to center the tooltip */
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    width: 300%;
    
}
.toolTipTop{
    top: -150%!important;
}

.btn {
    border: none;
    background: transparent;
    cursor: pointer;

    :deep(svg) {
        width: 32px;
        height: 32px;

        path,
        rect {
            fill: color(text, 100)!important;
        }
    }

    &.danger {
        :deep(svg) {
            path,
            rect {
                fill: color(alerts, base)!important;
            }
        }
    }

    &:hover {
        :deep(svg) {
            path,
            rect {
                fill: color(primary,80)!important;
            }
        }
    }

    &.btn-active {
        :deep(svg) {
            path,
            rect {
                fill: color(primary)!important;
            }
        }
    }
}
</style>