<template>
  
  <div class="pager-indicators">
  
    <div class="pager-indicators-item"  :class="{active:step==1}"></div>
    <div class="pager-indicators-item"  :class="{active:step==2}"></div>
    <div class="pager-indicators-item"  :class="{active:step==3}"></div>
    <div class="pager-indicators-item"  :class="{active:step==4}"></div>
  </div>

</template>

<script>
    
    export default {
      
      name: 'PagerWelcome',
      
      props:{
        step: Number
      },

      methods:{

        setStep: function(var_step){

          // Pasem a la seguent pagina Welcome
          this.$emit('setStep',var_step); 

        },

      },

    }

</script>

<style lang="scss" scoped>
    

    .pager-indicators{

        display: flex;
        justify-content: center;
        align-items: center;

        .pager-indicators-item{
            
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: color(primary,60);
            margin: 0 5px;
            transition: 0.5s all 0.2s ease-in-out;
            cursor: pointer;

            &.active{
              
              width: 20px;
              height: 20px;

              background: color(primary,base);

            }

        }

    }

</style>