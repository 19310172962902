<template>
    <div class="flex flex-col flex-wrap w-full h-full items-center justify-center p-10 gap-10">
        <h3 class="flex flex-row w-full items-center justify-center">
            {{ $t("thisOrThat.choose") }}
        </h3>
        <h3 class="flex flex-row w-full items-center justify-center text-primary-100">
            {{ $store.state.storeGameData.thisOrThatData.question.question }}
        </h3>
        <div class="buttonContainer flex flex-row w-full items-center justify-center">
            <BtnBase
                @click="selectedAnswer($store.state.storeGameData.thisOrThatData.question.responses[0])"
                :classBtn="
                    answer == $store.state.storeGameData.thisOrThatData.question.responses[0]
                        ? 'btn-primary'
                        : 'btn-outline'
                "
                :text="$store.state.storeGameData.thisOrThatData.question.responses[0]"
            />
            <BtnBase
                @click="selectedAnswer($store.state.storeGameData.thisOrThatData.question.responses[1])"
                :classBtn="
                    answer == $store.state.storeGameData.thisOrThatData.question.responses[1]
                        ? 'btn-primary ml-10'
                        : 'btn-outline ml-10'
                "
                :text="$store.state.storeGameData.thisOrThatData.question.responses[1]"
            />
        </div>
    </div>
</template>

<script>
import BtnBase from "@/components/buttons/BtnBase.vue";
import { ref as vref, onMounted } from "vue";
import { ref, update, onValue } from "firebase/database";
import { db } from "../../../firebase";
import { useStore } from "vuex";
export default {
    components: { BtnBase },

    setup() {
        const store = useStore();
        const url = window.location.pathname;
        const dataBasePlayerUrl = url + "/gameDataUser/";
        const teamDataDB = ref(db, dataBasePlayerUrl);
        const answer = vref("");

        onMounted(() => {
            onValue(teamDataDB, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    if (data[store.state.storeMedia.idPlayer]) {
                        if (data[store.state.storeMedia.idPlayer].response) {
                            answer.value = data[store.state.storeMedia.idPlayer].response;
                        }
                    }
                }
            });
        });

        const selectedAnswer = (selected_answer) => {
            update(teamDataDB, { [store.state.storeMedia.idPlayer]: { response: selected_answer } });
        };

        return {
            selectedAnswer,
            answer,
        };
    },
};
</script>

<style></style>
