<template>
    <div>
        <input :readonly="readonly" :maxlength="maxlength" :disabled="disabled" type="text" v-bind:placeholder="placehold"  :value="valueInput" @input='$emit("update:valueInput", $event.target.value)' />
        <p v-if="error">{{text_error}}</p>
    </div>

</template>

<script>
import {readonly, ref} from "vue"
import {config} from '../../config.js'

export default {
    
    name:"inputPrimary",
    props:{
        valueInput:String,
        text_error:String,   
        placehold:String,
        error:Boolean,
        disabled:{type:Boolean, default:false},
        readonly:{type:Boolean, default:false},
        maxlength:{type:String, default:''}
    },

    /*setup()
    {
        const error = ref(false)
        return {
            error
        }
    }*/

}
</script>

<style lang="scss" scoped>
    div{
        border-radius: 4px;
        margin: 10px;
    }
    input{

        border: 1px solid color(border);
        background: #fff;
        padding: 12px 24px;
        border-radius: 4px;
        font-size: 16px;
        line-height: 16px;
        color: color(text,120);
        font-family: $font-family;
        width: 100%;

    }

    ::placeholder{
        color: color(text,60);
    }

</style>