<template>
    <GameContainer :timer="true"  v-if="$store.state.storeGameState.gameStep == 0">
        <Tutorial />
    </GameContainer>
    <GameContainer
        :timer="true"
        
        v-else-if="$store.state.storeGameState.gameStep == 1"
    >
        <RolViewer/>
    </GameContainer>
    <GameContainer
        :timer="true"
        
        v-else-if="$store.state.storeGameState.gameStep == 2"
    >
        <ActionPhase/>
    </GameContainer>
    <GameContainer
        :timer="true"
        
        v-else-if="$store.state.storeGameState.gameStep == 4"
    >
        <ShowActionResultVue/>
    </GameContainer>
    <GameContainer
        :timer="true"
        
        v-else-if="$store.state.storeGameState.gameStep == 5"
    >
        <VotePhase/>
    </GameContainer>
    <GameContainer
        :timer="true"
        
        v-else-if="$store.state.storeGameState.gameStep == 6"
    >
        <ShowActionResultVoteVue/>
    </GameContainer>
    <GameContainer
   
     
        v-else-if="$store.state.storeGameState.gameStep == 7"
    >
    <FinalFeedback/>
    </GameContainer>
</template>
<script>
import Tutorial from "./Tutorial.vue";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import PhaseRanking from "@/components/gameWidgets/PhaseRanking.vue";
import FinalRanking from "@/components/gameWidgets/FinalRanking.vue";
import RolViewer from "./RolViewer.vue";
import ActionPhase from "./ActionPhase.vue";
import ShowActionResultVue from "./ShowActionResult.vue";
import ShowActionResultVoteVue from "./ShowActionResultVote.vue";
import FinalFeedback from '../FinalFeedback.vue'

import VotePhase from './VotePhase.vue'
export default {
    components: {
        Tutorial,
        GameContainer,
        FinalRanking,
        PhaseRanking,
        RolViewer,
        ActionPhase,
        ShowActionResultVue,
        VotePhase,
        ShowActionResultVoteVue,
        FinalFeedback,
        
     }
};
</script>
<style lang="scss">
</style>