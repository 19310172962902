<template>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 0">
        <Tutorial />
    </GameContainer>
    <GameContainer class="w-full h-full" :timer="true" v-if="$store.state.storeGameState.gameStep == 1">
        <div class="flex flex-col justify-center items-center p-10 gap-10">
            <h4 class="text-center">
                {{ $store.state.storeGameData.quizData.question }}
            </h4>
            <img class="img-border w-2/4" v-lazy="$store.state.storeGameData.quizData.image" alt="" />
            <Quiz parameterDB="quiz_option" :dataUrl="dataUrl" :questions="$store.state.storeGameData.quizData.answ" />
        </div>
    </GameContainer>
    <GameContainer class="w-full h-full" :timer="true" v-if="$store.state.storeGameState.gameStep == 2">
        <div class="flex flex-col gap-5 items-center justify-center h-full w-full">
            <div class="h-2/4 flex flex-col items-center justify-center gap-10">
                <h4 class="text-center">
                    {{ $store.state.storeGameData.quizData.question }}
                </h4>
                <h4 class="text-center">
                    {{ $t("quiz.corrent_answ") }}
                    <span class="text-primary-100">
                        {{ $store.state.storeGameData.quizData.correct_answ }}
                    </span>
                </h4>
            </div>

            <PhaseRanking
                class="mt-auto"
                :winners="$store.state.storeGameData.quizData.correct"
                :losers="$store.state.storeGameData.quizData.wrong"
                :pointsPlayers="25"
                :pointsMainPlayer="50"
            />
        </div>
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 3">
        <FinalRanking />
    </GameContainer>

    <GameContainer v-if="$store.state.storeGameState.gameStep == 4">
        <FinalFeedback />
    </GameContainer>
</template>

<script>
import Tutorial from "./Tutorial";

import { useStore } from "vuex";
import { computed, onMounted, watch, ref } from "vue";
import Quiz from "@/components/gameWidgets/Quiz.vue";
import PhaseRanking from "@/components/gameWidgets/PhaseRanking.vue";
import FinalRanking from "@/components/gameWidgets/FinalRanking.vue";
import FinalFeedback from "../FinalFeedback.vue";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";

export default {
    components: {
        Tutorial,
        Quiz,
        FinalRanking,
        PhaseRanking,
        GameContainer,
        FinalFeedback,
    },

    setup() {
        const store = useStore();

        const idPlayer = computed(() => store.state.storeMedia.idPlayer);

        const dataUrl = ref("/gameDataUser/" + idPlayer.value);
        return {
            dataUrl,
        };
    },
};
</script>

<style lang="scss" scoped>
.row {
    flex: 1 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
}

.fullrow {
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.row2x2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.rowCenter {
    align-items: stretch;
    justify-content: center;
}

.userWarap {
    width: 160px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}

.userWarap-ranking {
    width: 160px;
    :deep(.card_user) {
        width: 80px + 10px;
        height: 80px + 10px;
    }

    // height: 80px+ 10px;;
}

.img-border {
    border: 5px solid #ff7033;
    border-radius: 12px;
    object-fit: cover;
}
</style>
