<template>
    <div class="w-full h-full items-center justify-center text-center pt-2">
        <div class="flex flex-col h-full">
            <div class="userWrap flex flex-col w-full h-full mb-2 items-center justify-center ">
                <CardUserImgVue
                    :track="$store.getters.getMediaById($store.state.storeGameData.thisOrThatData.main_player)"
                    :showName="false"
                />
                <div class="flex flex-col w-full gap-5 my-5 items-center justify-center  ">
                    <h3
                        v-html="
                            $t('thisOrThat.main_response', {
                                main_player: $store.getters.getUserById(
                                    $store.state.storeGameData.thisOrThatData.main_player
                                ).playerName,
                                response: $store.state.storeGameData.thisOrThatData.main_player_response,
                            })
                        "
                    />
                </div>
                <div class="flex flex-row gap-10 p-10 w-full">
                    <PlayersBox
                        :response="
                            '<span class=\'' +
                                ($store.state.storeGameData.thisOrThatData.question.responses[0] ===
                                $store.state.storeGameData.thisOrThatData.main_player_response
                                    ? 'text-green-400'
                                    : 'text-red-600') +
                                '\'>' +
                                $store.state.storeGameData.thisOrThatData.question.responses[0] +
                                '</span>'
                        "
                        :arrayPlayers="
                            $store.state.storeGameData.thisOrThatData.question.responses[0] ===
                            $store.state.storeGameData.thisOrThatData.main_player_response
                                ? $store.state.storeGameData.thisOrThatData.alive_players
                                : $store.state.storeGameData.thisOrThatData.round_eliminated_players
                        "
                    />
                    <PlayersBox
                        :response="
                            '<span class=\'' +
                                ($store.state.storeGameData.thisOrThatData.question.responses[1] ===
                                $store.state.storeGameData.thisOrThatData.main_player_response
                                    ? 'text-green-400'
                                    : 'text-red-600') +
                                '\'>' +
                                $store.state.storeGameData.thisOrThatData.question.responses[1] +
                                '</span>'
                        "
                        :arrayPlayers="
                            $store.state.storeGameData.thisOrThatData.question.responses[1] ===
                            $store.state.storeGameData.thisOrThatData.main_player_response
                                ? $store.state.storeGameData.thisOrThatData.alive_players
                                : $store.state.storeGameData.thisOrThatData.round_eliminated_players
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardUserImgVue from "@/components/cards/CardUserImg.vue";
import PlayersBox from "./PlayersBox.vue";
import { useStore } from "vuex";
import { ref } from "vue";
export default {
    components: {
        CardUserImgVue,
        PlayersBox,
    },
};
</script>

<style lang="scss" scoped>
.userWrap {
    width: 80px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
