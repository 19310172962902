<template>
    <div class="w-full h-full items-center justify-center text-center">
        <div
            class="flex flex-col w-full h-full items-center justify-center p-10 gap-10"
            v-if="
                $store.state.storeGameData.thisOrThatData.main_player == $store.state.storeMedia.idPlayer &&
                    $store.state.storeGameState.gameStep == 1
            "
        >
            <Question />
        </div>

        <div
            class="flex flex-col w-full h-full items-center justify-center"
            v-else-if="$store.state.storeGameState.gameStep == 2"
        >
            <span
                v-if="
                    $store.state.storeGameData.thisOrThatData.main_player === $store.state.storeMedia.idPlayer ||
                        !$store.state.storeGameData.thisOrThatData.alive_players.find(
                            (e) => e === $store.state.storeMedia.idPlayer
                        )
                "
            >
                <h3 class="mb-10">
                    {{ $t("thisOrThat.choose") }}
                </h3>
                <h3 class="text-primary-100 mb-10">
                    {{ $store.state.storeGameData.thisOrThatData.question.question }}
                </h3>
            </span>
            <span v-else>
                <Question />
            </span>
            <div class=" flex flex-row gap-10 w-full">
                <PlayersBox
                    :response="$store.state.storeGameData.thisOrThatData.question.responses[0]"
                    :arrayPlayers="$store.state.storeGameData.thisOrThatData.responses_1"
                />
                <PlayersBox
                    :response="$store.state.storeGameData.thisOrThatData.question.responses[1]"
                    :arrayPlayers="$store.state.storeGameData.thisOrThatData.responses_2"
                />
            </div>
        </div>
        <div
            v-else-if="
                $store.state.storeGameData.thisOrThatData.main_player !== $store.state.storeMedia.idPlayer &&
                    $store.state.storeGameState.gameStep == 1
            "
            class="flex flex-col flex-auto h-full w-full items-center justify-center flex-wrap gap-10 userWrap "
        >
            <CardUserImgVue
                :track="$store.getters.getMediaById($store.state.storeGameData.thisOrThatData.main_player)"
                :showName="false"
            />
            <h3
                v-html="
                    $t('thisOrThat.main_responding', {
                        main_player: $store.getters.getUserById($store.state.storeGameData.thisOrThatData.main_player)
                            .playerName,
                    })
                "
            />
            <h3 class="text-primary-100">
                {{ $store.state.storeGameData.thisOrThatData.question.question }}
            </h3>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import Question from "./Question.vue";
import CardUserImgVue from "@/components/cards/CardUserImg.vue";
import PlayersBox from "./PlayersBox.vue";
import { useStore } from "vuex";
export default {
    components: { Question, CardUserImgVue, PlayersBox },

    setup() {
        const store = useStore();

        return {};
    },
};
</script>

<style scoped lang="scss">
.userWrap {
    width: 80px;
    :deep(.card_user) {
        width: $width-user + 10px;
        height: $width-user + 10px;
    }

    // height: 80px+ 10px;;
}
</style>
