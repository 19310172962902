<template>
<transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" appear mode="out-in" >

  <div class="flex flex-col gap-10 " :key="$store.state.storeGameData.mingleTingleData.resultPlayers">
    <h4 class="text-center">
      {{$t('mingleTingle.title_share')}}
    </h4>
    <h4 class="text-center">
      {{$store.state.storeGameData.mingleTingleData.textQuestion}}
    </h4>
    <div class="flex flex-row gap-4 justify-center">
        <div class=""  v-for="player in $store.state.storeGameData.mingleTingleData.resultPlayers" :key ="player">
            <div class="userWarap">
                <CardUser :track="$store.getters.getMediaById(player)"/>
            </div>
        </div>
    </div>
    <div v-if ="$store.state.storeGameData.mingleTingleData.typeQuestion == 'slider'">
        <QuestionSlider
            v-if="$store.state.storeGameData.mingleTingleData.answerShow "
            :disabled="true"
            :currentAnswer="$store.state.storeGameData.mingleTingleData.answerShow "
        />
        <h4 v-else class="text-center">
          {{$t('mingleTingle.no_answer')}}
        </h4>
    </div>
    <div v-else class="text-center">
        <h4 v-if="$store.state.storeGameData.mingleTingleData.answerShow ">
           {{$t('mingleTingle.answer')}}: {{$store.state.storeGameData.mingleTingleData.answerShow }}
        </h4>
        <h4 v-else>
          {{$t('mingleTingle.no_answer')}}
        </h4>
    </div>
  </div>
</transition>

</template>

<script>
import CardUser from "@/components/cards/CardUser.vue";
import Question from '@/components/gameWidgets/Question.vue'
import {useStore} from 'vuex'
import { computed} from 'vue'
import QuestionSlider from '@/components/gameWidgets/QuestionSlider.vue'
import Quiz from '@/components/gameWidgets/Quiz.vue'

export default {
  components: {
    CardUser,
    Question,
    QuestionSlider,
    Quiz,
  },

  setup(props){
    const store = useStore();
    const url = computed(()=> store.state.storeGameData.mingleTingleData.currentRoom)

    return {
        url
    }
  }
};
</script>

<style lang="scss" scoped>
.userWarap {
  width: 15vw;
  :deep(.card_user) {
    width: 15vw;
    height: 15vw;
  }

  // height: 80px+ 10px;;
}

.userWarap-ranking {
  width: 160px;
  :deep(.card_user) {
    width: 80px + 10px;
    height: 80px + 10px;
  }

  // height: 80px+ 10px;;
}
</style>

