<template>

        <div class="modal">
            
            <transition enter-active-class="animate__animated animate__fadeInDown animate__delay-1s" leave-active-class="animate__animated animate__fadeOutDown" appear>
                
                <div class="modal-content">

                  

                    <div class="modal-body" v-if="error == 0">
                        <p >
                           {{$t('Modals.error_title')}}
                        </p>
                        <h4>
                             {{$t('Modals.game_no_exist')}}
                        </h4>
                    </div>
                     <div class="modal-body" v-else-if="error ==1">
                        <p >
                           {{$t('Modals.error_title')}}
                        </p>
                        <h4>
                             {{$t('Modals.game_finish')}}
                        </h4>
                    </div>
                    <div class="modal-body" v-else-if="error ==2">
                        <p >
                           {{$t('Modals.error_title')}}
                        </p>
                        <h4>
                             {{$t('Modals.game_full')}}
                        </h4>
                    </div>
                    <div class="modal-body" v-else-if="error ==3">
                        <FormAuth :textLogin="true" />
                    </div>
                    <div class="modal-body" v-else-if="error ==4">
                         <p >
                           {{$t('Modals.error_title')}}
                        </p>
                        <h4 class="text-center">
                            {{$store.state.storeAuth.userInfo.email}}  {{$t('Modals.game_user_not_allowed')}}
                        </h4>
                        <BtnBase :text="$t('auth.logout')" v-on:click="$store.dispatch('logout')" />


                    </div>
                    <div class="modal-body" v-else-if="error ==5">
                         <p >
                           {{$t('Modals.error_title')}}
                        </p>
                        <h4 class="text-center">
                           {{$t('Modals.game_user_already_contected')}}
                        </h4>


                    </div>
                    <div class="modal-body" v-else>
                        <p >
                           {{$t('Modals.error_title')}}
                        </p>
                        <h4>
                            {{$t('Modals.game_no_exist')}}
                        </h4>
                    </div>

                </div>

            </transition>

        </div>

</template>

<script>

    import InlineSvg from 'vue-inline-svg';
    import BtnBase from '@/components/buttons/BtnBase';
    import FormAuth from '../auth/FormAuth'
    export default {
        
        name: 'ModalWelcome',

        props:{
            error:String
        },
        components:{
            InlineSvg,
            BtnBase,
            FormAuth
            
        },
        
        methods:{

            nextStep: function(var_step){

                // Pasem a la seguent pagina Welcome
                this.$emit('setStep',var_step + 1);
            
            },

        },

    }

</script>

<style lang="scss" scoped>

    .modal{
        z-index:88888;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        background: rgba(color(bg,140),0.5);

        &-content{
            max-width: 540px;
            padding: 16px 16px 32px;
            
            background: #FFFFFF;
            box-shadow: 0px 10px 24px #3C3C3C;
            border-radius: 12px;
        }

        &-header{
            
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 8px;

            svg{
                
                height: 16px;
                widows: 16px;
                cursor: pointer;

            }

        }

        &-body{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        h2{

            width: 100%;

            &:not(:first-child){
                margin-top: 24px;
            }

            & + p,& + ul{
                margin-top: 12px;
            }
        }

        p,li{
            font-size: 12px;
            line-height: 14px;
        }

        ul{
            
            padding: 0 0 0 16px;
            width: 100%;

        }

        .btn{
            margin-top: 32px;
        }

    }

</style>