<template>
    <div id="items" class="flex flex-col w-full px-3">
        <h4 class="text-center">
            {{ $t("factMatching.title_match") }}
        </h4>
        <transition enter-active-class="bounce-enter-active " appear :key="acutalPage">
            <div class="grid grid-cols-2 justify-items-center mx-4 mt-6 ">
                <div
                    v-for="n in showMatch($store.state.storeGameData.factMatchingData.answerGuess)"
                    :key="n"
                    class="flex flex-row box-text m-1 "
                >
                    <div
                        v-if="returnUser(n.id)"
                        class="flex justify-center item"
                        :data-user="returnUser(n.id)"
                        :id="n.id"
                    >
                        <div :id="n.id" class="userWarap " :data-user="returnUser(n.id)">
                            <CardUserImg
                                :track="$store.getters.getMediaById(returnUser(n.id))"
                                :id="n.id"
                                :data-user="name"
                            />
                        </div>
                    </div>
                    <div v-else class="flex box-img item userWarap" :data-user="returnUser(n.id)" :id="n.id">
                        <div></div>
                    </div>

                    <div class="flex p-2">
                        <div class="font-bold mb-2 text-m" v-if="n.text != 'false'">{{ n.text }}</div>
                        <div class="font-bold mb-2 text-m" v-if="n.text == 'false'">
                            {{ $t("factMatching.no_answer") }}
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <DummyPager :step="acutalPage" :maxStep="maxPage" />

        <div class="flex flex-row gap-5 items-center justify-center my-3">
            <BtnBase :text="$t('game.btn_before_tutorial')" v-if="acutalPage > 0" v-on:click="updatePage(-1)" />
            <BtnBase :text="$t('game.btn_next_tutorial')" v-if="acutalPage < maxPage - 1" v-on:click="updatePage(1)" />
        </div>

        <div>
            <div class=" pb-3 text-center ">
                <h4>{{ $t("factMatching.users") }}</h4>
            </div>

            <div
                class="flex flex-row flex-wrap justify-center gap-2"
                v-if="Object.entries($store.state.storeGameData.factMatchingData.userMatch).length != 0"
            >
                <div
                    class="userWarap"
                    :key="name"
                    v-for="(value, name) in $store.state.storeGameData.factMatchingData.userMatch"
                >
                    <CardUserImg :track="$store.getters.getMediaById(name)" class="item" :id="name" :data-user="name" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUpdated, computed } from "vue";
import { Draggable } from "@shopify/draggable";
import { useStore } from "vuex";
import CardUserImg from "../cards/CardUserImg";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import BtnBase from "@/components/buttons/BtnBase";
import DummyPager from "@/components/gameWidgets/DummyPager.vue";
export default {
    components: {
        CardUserImg,
        Navigation,
        Carousel,
        Slide,
        Pagination,
        BtnBase,
        DummyPager,
    },

    setup() {
        let last_id_drag = null;
        let targetGet = null;
        const players = ref();
        const store = useStore();
        const acutalPage = ref(0);
        const maxPage = ref(0);
        const showMatch = (array) => {
            var arrays = [],
                size = 4;
            let a = Object.values(array);
            console.log(a);
            let counter = 0;
            while (a.length > 0) {
                arrays.push(a.splice(0, size));
                counter++;
            }
            maxPage.value = counter;
            console.log(arrays);
            return arrays[acutalPage.value];
        };

        const updatePage = (update) => {
            acutalPage.value = acutalPage.value + update;
        };
        onMounted(() => {
            const draggable = new Draggable(document.getElementById("items"), {
                draggable: ".item",
                classes: {
                    "draggable:over": ["opacity-20"],
                },
            });
            draggable.on("drag:start", (event) => {
                targetGet = event.data.originalSource.dataset.user;
            });
            draggable.on("drag:stop", (event) => {
                if (last_id_drag) {
                    const userList = computed(() => store.state.storeGameData.factMatchingData.userMatch);
                    let newData = {};
                    for (const property in userList.value) {
                        if (userList.value[property] == last_id_drag) {
                            console.log(property, targetGet);
                            newData[property] = userList.value[targetGet];
                        }
                    }
                    newData[targetGet] = last_id_drag;
                    store.dispatch("factMatchingUpdateMatch", newData);
                }
            });

            draggable.on("drag:over", (event) => {
                last_id_drag = draggable.currentOver.id;
            });
            draggable.on("drag:out", (event) => {
                last_id_drag = null;
            });
        });

        const returnUser = (value) => {
            const userList = computed(() => store.state.storeGameData.factMatchingData.userMatch);
            for (const property in userList.value) {
                if (userList.value[property] == value) {
                    return property;
                }
            }
            return "";
        };

        return {
            returnUser,
            players,
            acutalPage,
            showMatch,
            maxPage,
            updatePage,
        };
    },
};
</script>

<style lang="scss" scoped>
.user_in {
    min-width: 150px;
    min-height: 50px;
}
p {
}
.box-text {
    box-shadow: inset 0px 0px 4px 1px rgba(133, 134, 142, 0.2);
    border-radius: 12px;
    max-height: 15vh;
}
.box-img {
    background: #f4f5f7;
    border: 2px solid;
    border-radius: 12px;
    min-width: 15vh;
    height: 15vh;
}
.userWarap {
    width: 15vh;
    height: 15vh;
    :deep(.card_user) {
        width: 15vh;
        height: 15vh;
    }

    // height: 80px+ 10px;;
}

.caption {
    z-index: 50;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(315deg, #1b1c29 0%, rgba(27, 28, 41, 0) 100%);
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    font-family: $font-family-header;
    line-height: 16px;
    letter-spacing: 0.3px;
    color: #fff;
    padding: 4px 12px;
    text-transform: capitalize;
}
</style>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 1s;
}
.bounce-leave-active {
    animation: bounce-in 1s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
</style>
