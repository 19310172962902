<template>
  <div class="welcome flex flex-col gap-4">
   <h4 class="text-center">
      {{question}} 
   </h4>
   <div>
        <InputPrimary :class="width" :placehold="$t('openQuestion.placeholder')"  text_error="" v-model:valueInput='name_input' />
        <BtnBase :text="$t('openQuestion.btn_save')" v-on:click="updateAnsw" />
   </div>

   <p v-if="currentAnswer != ''">
      {{$t('openQuestion.answer_text') }} 
      <strong>
        {{currentAnswer}}
        </strong>
   </p>

  </div>
</template>

<script>

/**
  INPUTS: object of:
          title of button
          seleced or not


 */
import BtnBase from "@/components/buttons/BtnBase";
import { ref as vref , onMounted, watch} from 'vue'
import { db} from '../../firebase'
import { ref, off, onValue,onDisconnect, update, onChildAdded, onChildChanged, onChildRemoved, push, set } from "firebase/database";
import InputPrimary from '@/components/inputs/InputPrimary.vue';
import { notify } from 'notiwind'
import { useI18n } from 'vue-i18n';


const url = window.location.pathname


export default {
  components: {
    BtnBase,
    InputPrimary,
    
  },

  props:{
    dataUrl:String,
    question:String,
    currentAnswer:{default:"", type:String},
    objectKey: {default:"currentAnswer", type:String},
    width :{ default:"short", type:String}
  },

  

  setup(props){
    const {t} = useI18n();
    let lastCallback = null
    const name_input = vref("")
    const updateAnsw = () => {
      const dataBasePlayerUrl =  url + props.dataUrl
      console.log(dataBasePlayerUrl)
      const teamDataDB = ref(db,dataBasePlayerUrl);
      if(name_input.value== "")
      {
        notify({
        group: "bottom",
        title: t('Modals.error_title'),
        text: t('openQuestion.text_error')
        }, 2000)
        return;
      }

      update(teamDataDB, {
          [props.objectKey]:name_input.value
      })
      name_input.value = ""
       notify({
        group: t('Modals.error_title'),
        title:  t('openQuestion.alert_send'),
        text: ""
        }, 2000)
    };
    return{
      updateAnsw,
      name_input,
    }
  }
};
</script>

<style lang="scss" scoped>
  .welcome{

   
    input{
      text-align: center;
    }

  }
  .large{
      width: 40rem;
    }

  </style>