<template>
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        appear
    >
        <div v-if="status == true" class="modal">
            <transition enter-active-class="animate__animated animate__fadeInDown animate__delay-1s" appear>
                <div v-if="status == true" class="modal-content w-full customheight">
                    <div class="modal-header">
                        <inline-svg
                            @click="$store.commit('setmediaOptionsModal')"
                            :src="require('@/assets/svg/close.svg')"
                        />
                    </div>
                    <div class="modal-body h-full">
                        <div class="flex flex-row w-full">
                            <div class="w-2/12 flex flex-col gap-3">
                                <h4 class="text-center">
                                    {{ $t("settingMenu.settings") }}
                                </h4>
                                <div
                                    class="cursor-pointer text-center border-2 border-primary-100  p-1 "
                                    :class="menuActivate == 0 ? 'border-solid' : 'border-none'"
                                    v-on:click="setMenu(0)"
                                >
                                    <p>
                                        {{ $t("settingMenu.user_setting") }}
                                    </p>
                                </div>
                                <div
                                    class="cursor-pointer text-center border-2 border-primary-100 p-1 rounded-xl"
                                    :class="menuActivate == 1 ? 'border-solid' : 'border-none'"
                                    v-on:click="setMenu(1)"
                                >
                                    {{ $t("settingMenu.photo_settings") }}
                                </div>
                                <div
                                    class="cursor-pointer text-center border-2 border-primary-100 p-1 rounded-xl"
                                    :class="menuActivate == 2 ? 'border-solid' : 'border-none'"
                                    v-on:click="setMenu(2)"
                                >
                                    {{ $t("settingMenu.microphone_settings") }}
                                </div>
                                <div
                                    class="cursor-pointer text-center border-2 border-primary-100 p-1 rounded-xl"
                                    :class="menuActivate == 3 ? 'border-solid' : 'border-none'"
                                    v-on:click="setMenu(3)"
                                >
                                    {{ $t("settingMenu.camera_settings") }}
                                </div>
                            </div>
                            <div class="w-10/12 h-full">
                                <div v-if="menuActivate == 0" class="flex flex-col justify-center items-center h-full">
                                    <h4 class="text-center">
                                        {{ $t("settingMenu.bonfire_name") }}
                                    </h4>
                                    <InputPrimary
                                        class="w-1/2 mx-auto"
                                        v-model:valueInput="userName"
                                        :maxlength="maxlength"
                                    />
                                    <BtnLoad
                                        type="button"
                                        class="btn btn-primary btn-outline"
                                        :disabled="userName == ''"
                                        v-on:click="$store.dispatch('updateName', { userName, setLoading })"
                                        :isLoading="isLoading"
                                    >
                                        {{ $t("settingMenu.update") }}
                                    </BtnLoad>
                                </div>
                                <div
                                    v-if="menuActivate == 1"
                                    class="flex flex-col justify-center items-center h-full gap-3"
                                >
                                    <h4 class="text-center">
                                        {{ $t("settingMenu.bonfire_image") }}
                                    </h4>
                                    <div class="card-group">
                                        <CardVideoPhoto
                                            :media="$store.getters.getMedia"
                                            :localStream="$store.state.storeMedia.localStream.stream"
                                            :photoTaken="photoTaken"
                                            :urlBlob="urlBlob"
                                        />
                                    </div>
                                    <div class="buttons-group flex flex-row gap-2">
                                        <BtnLoad
                                            type="button"
                                            class="btn btn-primary btn-outline"
                                            :disabled="photoTaken"
                                            @click="takePhoto()"
                                            :isLoading="isLoading"
                                            v-if="!photoTaken"
                                        >
                                            {{ $t("WELCOME3.btnPhoto") }}
                                        </BtnLoad>
                                        <BtnBase
                                            @click="resetPhoto()"
                                            :text="$t('WELCOME3.btnRepeat')"
                                            classBtn="btn-primary btn-outline"
                                            v-if="photoTaken"
                                        />
                                        <BtnLoad
                                            type="button"
                                            class="btn btn-primary btn-outline"
                                            :disabled="!photoTaken || !newPhoto"
                                            @click="
                                                $store.dispatch('updatePhoto', { imageBlob, setLoading });
                                                sendFoto;
                                            "
                                            :isLoading="isLoading && photoTaken"
                                        >
                                            {{ $t("settingMenu.update") }}
                                        </BtnLoad>
                                    </div>
                                </div>
                                <div
                                    class="text_wrap flex flex-col justify-center items-center h-full"
                                    v-if="menuActivate == 2"
                                >
                                    <div class="flex flex-col gap-4 items-center">
                                        <h2 class="h4 text-xl text-center">{{ $t("settingMenu.title_mic") }}</h2>
                                        <p class="text-center w-3/4">
                                            {{ $t("settingMenu.text_mic") }}
                                        </p>
                                        <select
                                            class="w-3/4 mx-auto"
                                            v-model="$store.state.storeMedia.media_change.audio"
                                            v-on:change="
                                                $store.dispatch(
                                                    'updateTracksMic',
                                                    $store.state.storeMedia.media_change.audio
                                                )
                                            "
                                        >
                                            <option
                                                v-for="(microphone, index) in $store.state.storeMedia.microphones"
                                                :key="microphone"
                                                v-bind:value="microphone"
                                                >{{ microphone.label }}</option
                                            >
                                        </select>
                                        <div class="volume-indicators max-h">
                                            <inline-svg
                                                v-for="index in fireNumber"
                                                v-bind:key="index"
                                                :src="require('@/assets/svg/logo_volume.svg')"
                                            />
                                            <inline-svg
                                                :src="require('@/assets/svg/logo_volume.svg')"
                                                :style="{ 'clip-path': firePaint }"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="text_wrap flex flex-col justify-center items-center h-full"
                                    v-if="menuActivate == 3"
                                >
                                    <div class="flex flex-col gap-4 items-center">
                                        <h2 class="h4 text-xl text-center">{{ $t("settingMenu.title_camera") }}</h2>
                                        <p v-if="$store.state.storeMedia.cameras.length > 0" class="text-center w-3/4">
                                            {{ $t("settingMenu.text_camera") }}
                                        </p>
                                        <select
                                            class="w-3/4"
                                            v-if="$store.state.storeMedia.cameras.length > 0"
                                            v-model="$store.state.storeMedia.media_change.video"
                                            v-on:change="
                                                $store.dispatch(
                                                    'updateTracksVideo',
                                                    $store.state.storeMedia.media_change.video
                                                )
                                            "
                                        >
                                            <option
                                                v-for="camera in $store.state.storeMedia.cameras"
                                                :key="camera"
                                                v-bind:value="camera"
                                                >{{ camera.label }}</option
                                            >¡
                                        </select>
                                        <video
                                            class="video"
                                            autoPlay
                                            playsinline
                                            :srcObject="$store.state.storeMedia.localStream.stream"
                                        ></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="modal-footer">
                        <BtnBase
                            @click="setNewMedia()"
                            :text="$t('modalOptionsMeida.btn_ok')"
                            classBtn="btn-primary btn-sm"
                        />
                    </div> -->
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import InputPrimary from "@/components/inputs/InputPrimary.vue";
import BtnBase from "@/components/buttons/BtnBase";
import BtnLoad from "@/components/buttons/BtnLoad";
import { onMounted, ref, computed, watch } from "@vue/runtime-core";
import { notify } from "notiwind";
import CardVideoPhoto from "@/components/cards/CardVideoPhoto.vue";

import CardMicro from "@/components/cards/CardMicro.vue";

import CardVideo from "@/components/cards/CardVideo.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { config } from "../../config.js";

export default {
    name: "ModalWelcome",

    components: {
        InlineSvg,
        BtnBase,
        BtnLoad,
        InputPrimary,
        CardMicro,
        CardVideo,
        CardVideoPhoto,
        config,
    },

    props: {
        status: Boolean,
    },

    setup() {
        const store = useStore();
        const { t } = useI18n();
        const menuActivate = ref(0);
        const fireNumber = ref(0);
        const firePaint = ref(0);
        const newPhoto = ref(false);
        const maxlength = config.maxlength;
        const setMenu = (data) => {
            menuActivate.value = data;
            urlBlob.value = store.state.storeMedia.localStream.urlImg;
            userName.value = store.state.storeMedia.playerName;
            if (data == 2) {
                store.dispatch("getMicLevel");
            }
        };
        let imageBlob = ref(null);
        const userName = ref(store.state.storeMedia.playerName);

        const photoTaken = ref(true);
        const urlBlob = ref(store.state.storeMedia.localStream.urlImg);
        const resetPhoto = () => {
            photoTaken.value = false;
        };

        const isLoading = ref(false);
        const setLoading = () => {
            isLoading.value = !isLoading.value;
            console.log(isLoading);
        };

        //aduioo
        watch(
            () => store.state.storeMedia.volMic,
            () => {
                const micLevel = store.state.storeMedia.volMic + 5;
                fireNumber.value = parseInt(micLevel / 20);
                var temp = parseInt(100 - (micLevel % 20) * 5);
                firePaint.value = "inset(0% " + temp + "% 0% 0%)";
            },
            () => store.state.storeMedia.playerName,
            () => {
                userName.value = store.state.storeMedia.localStream.playerName;
            }
        );

        function getBlobFromMediaStream(stream) {
            if ("ImageCapture" in window) {
                const videoTrack = stream.getVideoTracks()[0];
                const imageCapture = new ImageCapture(videoTrack);
                return imageCapture.takePhoto();
            } else {
                const video = document.createElement("video");
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                video.srcObject = stream;

                return new Promise((resolve, reject) => {
                    video.addEventListener("loadeddata", async () => {
                        const { videoWidth, videoHeight } = video;
                        canvas.width = videoWidth;
                        canvas.height = videoHeight;

                        try {
                            await video.play();
                            context.drawImage(video, 0, 0, videoWidth, videoHeight);
                            canvas.toBlob(resolve, "image/png");
                        } catch (error) {
                            reject(error);
                        }
                    });
                });
            }
        }
        const takePhoto = () => {
            setLoading();
            newPhoto.value = true;
            const imageCapture = getBlobFromMediaStream(store.state.storeMedia.localStream.stream);
            const img = document.querySelector("img");
            imageCapture
                .then((blob) => {
                    photoTaken.value = true;
                    urlBlob.value = URL.createObjectURL(blob);
                    imageBlob.value = blob;
                    setLoading();
                })
                .catch((error) => {
                    console.error("takePhoto() error:", error);
                    setLoading();
                });
        };

        onMounted(() => {
            console.log("open");
            store.dispatch("getDevices");
            urlBlob.value = store.state.storeMedia.localStream.urlImg;
            userName.value = store.state.storeMedia.playerName;
        });
        const setNewMedia = () => {
            const video = store.state.storeMedia.media_change.video;
            const audio = store.state.storeMedia.media_change.audio;
            if (store.state.storeMedia.cameras.length > 0) {
                if (!video) {
                    errorMedia();
                    return;
                }
            }
            if (!audio) {
                errorMedia();
                return;
            }
            store.dispatch("updateTracks");
        };

        const errorMedia = () => {
            notify(
                {
                    group: "bottom",
                    title: t("Modals.error_title"),
                    text: t("modalOptionsMeida.error_media"),
                },
                2000
            );
        };

        const sendFoto = () => {
            newPhoto.value = false;
        };
        return {
            setNewMedia,
            menuActivate,
            setMenu,
            userName,
            photoTaken,
            urlBlob,
            resetPhoto,
            takePhoto,
            imageBlob,
            fireNumber,
            firePaint,
            newPhoto,
            sendFoto,
            isLoading,
            setLoading,
            maxlength,
        };
    },
};
</script>

<style lang="scss" scoped>
.video {
    height: 200px;
}
.max-h {
    max-width: 100%;
}
.customheight {
    height: 500px;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 3000;

    background: rgba(color(bg, 140), 0.5);

    &-content {
        max-width: 50%;
        padding: 16px 16px 32px;

        background: #ffffff;
        box-shadow: 0px 10px 24px #3c3c3c;
        border-radius: 12px;
    }

    &-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 8px;

        svg {
            height: 16px;
            widows: 16px;
            cursor: pointer;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text_wrap {
            display: flex;
            align-items: center;
        }

        :deep(svg) {
            height: 56px;
            width: 56px;
            margin: 0 8px 0 0;

            path,
            rect {
                fill: color(primary);
            }
        }

        :deep(input) {
            width: 100%;
            margin: 16px 0 0 0;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        margin: 16px 0 0;
    }
}
</style>
