<template>
    <div class="main">
        <transition-group enter-active-class="animate__animated animate__flipInX" leave-active-class="animate__animated animate__fadeOut" appear mode="out-in" >
            <CardTeam v-if="active" team_name="Equip A" classCard="myteam" :players="players"  />
        </transition-group>
    </div>
</template>

<script>

    import CardTeam from '@/components/cards/CardTeam'

    export default {
        
        name: 'LobbyPage',

        components:{
            CardTeam
        },

        props:{
            active: Boolean
        },

        data: function(){
            return{
                players: [
                    { classCard: 'self' },
                    { classCard: '' },
                    { classCard: 'mute' },
                    { classCard: 'talking' },
                    { classCard: '' },
                    { classCard: 'empty' },
                    { classCard: 'empty' },
                    { classCard: 'empty' },
                ]
            }
        }

    }

</script>

<style lang="scss" scoped>
    .main{
        overflow: scroll;
        overflow-y: hidden; /* Hide vertical scrollbar */
        overflow-x: hidden; /* Hide horizontal scrollbar */

    }
</style>