<template>
    <div class="row-center">
        <div class="card-word">
            <p>
                   {{$t('pictionary.draw_bottom')}}<strong class="word bold text-xl">
                    {{$store.state.storeGameData.pictionaryData.word}} 
                    </strong>

            </p>

        </div>
    </div>
  
</template>

<script>


export default {

   
}
</script>

<style lang="scss" scoped>

.word{
    color:color(primary);
}

.row-center {
display: flex;
flex-direction: row;
align-items: center;
padding: 8px 24px 8px 16px;
justify-content: center;
position: static;
width: 100%;

}
.card-word{
    background: #FFFFFF;
/* Border/20 */

border: 1px solid #E6E6E6;
box-sizing: border-box;
border-radius: 4px;
}
.card-word p{
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 6px;
    // font-family: Gilroy;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
}
.card-word strong{
    text-transform: uppercase;
}

</style>