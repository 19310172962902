<template>

    <div class="content">

        <BtnIco @click="this.status=!this.status;" ico="menu" />

        <div v-show="status == true" id="menu" ref="menu" class="menu" :style="{ top: height_menu }">
    
            <ul>

                <!-- Menu Provisional para seleccionar el formato de equipo -->
                <li class="h6">GameMaster</li>
                <li><InputSwitch :active="game_master" @active="setGameMaster" /></li>

                <!-- Menu Provisional para seleccionar el formato de equipo -->
                <li class="h6">Formato de Equipos</li>
                <li><button :class="{active:team_format==0}" @click="this.$emit('team_format',0);">4 Players</button></li>
                <li><button :class="{active:team_format==1}" @click="this.$emit('team_format',1);">8 Players</button></li>
                <li><button :class="{active:team_format==2}" @click="this.$emit('team_format',2);">Rivals</button></li>
                <li><button :class="{active:team_format==3}" @click="this.$emit('team_format',3);">Individual</button></li>
                
                <!-- Menu Provisional para seleccionar el juego activo -->
                <li class="h6">Game</li>
                <li><button :class="{active:game_active==0}" @click="this.$emit('game_active',0)">Base</button></li>
                <li><button :class="{active:game_active==1}" @click="this.$emit('game_active',1)">Pictionary</button></li>

            </ul>

            <div class="triangle">
                <InlineSvg  :src="require('@/assets/svg/triangle_down.svg')" />
            </div>

        </div>

    </div>

</template>

<script>

    import BtnIco from '@/components/buttons/BtnIco';
    import InputSwitch from '@/components/inputs/InputSwitch';
    import InlineSvg from 'vue-inline-svg'

    export default {
    
        name: 'TopRight',

        components:{
            BtnIco,
            InlineSvg,
            InputSwitch
        },

        props:{
            team_format: {type:Number,default:0}, // Formato de equipo que participan en la partida
            game_active: {type:Number,default:0}, // Juego Activo
            game_master: {type:Boolean,default:false} // Juego Activo
        },

        data:function(){
            return{
                status: false,
                height_menu: 'auto'
            }
        },

        methods:{

            // Activar/Desactivar Game Master
            setGameMaster(game_master){
                
                this.$emit('game_master',game_master);

                game_master == true ? this.$emit('alert',{'status':true,'text':'Has activado el Game Master','type':'success'}) : this.$emit('alert',{'status':true,'text':'Has desactivado el Game Master','type':'warning'})


                
            }

        }

    }

</script>

<style lang="scss" scoped>

    .content{
        
        position: relative;

    }

    .menu{

        position: absolute;
        top: 0;
        right: 0;
        height: auto;
        width:256px;
        padding: 20px 16px;
        z-index: 2999;
        background: color(grays,base);
        box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        transform: translate(0%, -117%);

        ul{
            list-style-type: none;

            li{

                display: flex;
                align-items: center;
                margin-top: 8px;

                &:first-of-type{
                    margin:0 !important;
                }

                &.h6{
                    margin-top:16px;
                }

                button{

                    background: transparent;
                    border: none;
                    cursor: pointer;
                    font-family: $font-family;
                    color: color(text,100);
                    font-size: 16px;
                    line-height: 16px;

                    &.active{
                        font-weight: bold;

                        &::after{
                            content: '<'
                        }
                    }

                    &:focus-visible {
                        outline: none;
                        color: color(primary);
                        font-weight: bold;
                    }
                }

                .text{
                    flex: 1 1 100%;
                }

                svg{
                    height: 16px;
                    width: 16px;
                    flex: 0 0 16px;
                }

            }

        }

        .triangle{

            position: absolute;
            right: 0;
            bottom: -16px;
            display: flex;
            justify-content: flex-end;
            padding:0 4px 0 0px;
            margin: -1px 0 0;

        }

    }

</style>