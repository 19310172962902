<template>
    <div class="game_content h-full flex flex-col">
        <h4 class="mt-5 text-center">
            {{ $t("pictionary.draw_top") }}
        </h4>
        <div class="cursor"></div>
        <canvas
            @mouseout="mouseOut"
            @mousemove="mouseMove"
            @mousedown="mouseDown"
            @mouseup="mouseUp"
            id="draw"
            class="draw"
            width="400"
            height="400"
        ></canvas>
    </div>
</template>

<script>
import { onMounted, computed, ref, watch, watchEffect, onUnmounted } from "vue";
import { useStore } from "vuex";
import Timer from "@/components/gameWidgets/Timer";
import GameRound from "@/components/gameWidgets/GameRound";

export default {
    components: {
        Timer,
        GameRound,
    },

    setup() {
        const root = ref(null);
        const store = useStore();
        const color = computed(() => store.state.storeGameData.pictionaryData.color);
        const points = computed(() => store.state.storeGameData.pictionaryData.points);
        const y = computed(() => store.state.storeGameData.pictionaryData.witdh);
        let mouseCursor = {};
        const cursor = (e) => {
            if (e.path) {
                if (e.path[0].id == "draw") {
                    mouseCursor.style.top = e.layerY + "px";
                    mouseCursor.style.left = e.layerX + "px";
                    mouseCursor.style.width = y.value * 2 + "px";
                    mouseCursor.style.height = y.value * 2 + "px";
                    mouseCursor.style.display = "inline";
                } else {
                    mouseCursor.style.display = "none";
                }
            }
        };

        onMounted(() => {
            mouseCursor = document.querySelector(".cursor");
            window.addEventListener("mousemove", cursor);
        });

        onUnmounted(() => {
            window.removeEventListener("mousemove", cursor);
        }),
            watch(
                () => points.value.length,
                () => {
                    if (points.value.length == 0) {
                        ctx.clearRect(0, 0, canvas.width, canvas.height);
                    }
                    for (index; index < points.value.length; index++) {
                        if (points.value[index] != "null") {
                            drawRT(JSON.parse(points.value[index]));
                        }
                    }
                }
            );

        let arrayPaint = {
            color: color.value,
            lineWidth: y.value,
            points: [],
        };
        var canvas,
            ctx,
            flag = false,
            prevX = 0,
            currX = 0,
            prevY = 0,
            currY = 0,
            dot_flag = false;

        var rect = null;
        var flag = false;
        let index = 0;
        var prevX = 0,
            currX = 0,
            prevY = 0,
            currY = 0,
            dot_flag = false;

        const mouseMove = (event) => {
            findxy("move", event);
        };
        const mouseDown = (event) => {
            findxy("down", event);
        };

        const mouseUp = (event) => {
            findxy("up", event);
        };

        const mouseOut = (event) => {
            findxy("out", event);
        };

        function drawRT(data) {
            ctx.beginPath();
            data.points.forEach((element, index) => {
                if (index > 0 && index < data.points.length - 1) {
                    ctx.strokeStyle = data.color;
                    ctx.fillStyle = data.color;
                    ctx.lineWidth = data.lineWidth * 2;
                    ctx.lineTo(element.x, element.y);
                    ctx.stroke();
                    ctx.beginPath();
                    ctx.arc(element.x, element.y, data.lineWidth, 0, Math.PI * 2);
                    ctx.fill();
                    ctx.beginPath();
                    ctx.moveTo(element.x, element.y);
                }

                // ctx.beginPath();
                // ctx.moveTo(element.lastx, element.lasty);
                // ctx.lineTo(element.x, element.y);
                // ctx.strokeStyle = data.color;
                // ctx.lineWidth = data.lineWidth;
                // ctx.stroke();
                // ctx.closePath();
            });
        }

        onMounted(() => {
            canvas = document.getElementById("draw");
            ctx = canvas.getContext("2d");
            ctx.beginPath();
            if (points.value) {
                for (index; index < points.value.length; index++) {
                    if (points.value[index] != "null") {
                        drawRT(JSON.parse(points.value[index]));
                    }
                }
            }
        });

        function draw(e) {
            ctx.strokeStyle = color.value;
            ctx.fillStyle = color.value;
            ctx.lineWidth = y.value * 2;
            ctx.lineTo(e.offsetX, e.offsetY);
            ctx.stroke();
            ctx.beginPath();
            ctx.arc(e.offsetX, e.offsetY, y.value, 0, Math.PI * 2);
            ctx.fill();
            ctx.beginPath();
            ctx.moveTo(e.offsetX, e.offsetY);
            arrayPaint.points.push({
                x: e.offsetX,
                y: e.offsetY,
            });
        }

        function findxy(res, e) {
            if (res == "move") {
                if (flag) {
                    draw(e);
                }
            }
            if (res == "up" || res == "out") {
                ctx.beginPath();
                console.log(arrayPaint);
                if (flag) {
                    draw(e);
                    store.dispatch("pictionarySendPoints", arrayPaint);
                }
                flag = false;
                arrayPaint = {
                    color: color.value,
                    lineWidth: y.value,
                    points: [],
                };
            }
            if (res == "down") {
                ctx.beginPath();
                arrayPaint = {
                    color: color.value,
                    lineWidth: y.value,
                    points: [],
                };
                draw(e);
                flag = true;
            }
        }

        return {
            mouseMove,
            mouseDown,
            mouseUp,
            mouseOut,
            points,
            color,
            y,
        };
    },
};
</script>

<style lang="scss" scoped>
.game_content {
    height: 100%;
    width: 100%;
}

.draw {
    margin: auto;
    border: 2px solid color(primary);
    //cursor: url("../../../assets/img/icons8-ball-point-pen-96.png");
    //@apply cursor-pencil;
}

.center {
    text-align: center;
}

.cursor {
    //  --custom-size: v-bind(y);
    // width:var(custom-size) + 'rem' ;
    // height:var(custom-size) + 'rem' ;
    border: 1px solid black;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    background-color: v-bind(color);
}
</style>
