<template>
    <div v-if="track && track.idPlayer">
        <div class="card card_user " v-bind:class="[track.micLevel > 500 ? 'talking' : '', track.self ? 'self' : '']">
            <video
                ref="videoRef"
                :id="'playerVideo' + track.idPlayer"
                v-if="
                    track.video &&
                        !$store.state.storeMedia.slowMode &&
                        !photoMode &&
                        !defaultPhoto &&
                        !track.disconected
                "
                class="video"
                autoPlay
                playsinline
                :srcObject="track.stream"
            ></video>
            <div v-else>
                <div class="layer_admin " v-if="track.disconected">
                    <InlineSvg :src="require('@/assets/svg/plug-solid.svg')" class="admin-crown crossed" />
                </div>
                <img
                    class="img-no-cam "
                    :class="track.disconected ? 'img-no-cam-disconected' : ''"
                    :src="defaultPhoto ? '../../assets/svg/cameraOff.svg' : track.urlImg"
                />
                <video
                    ref="videoRef"
                    :id="'playerVideo' + track.idPlayer"
                    v-if="!track.self && !track.muted"
                    class="onlysound"
                    autoPlay
                    playsinline
                    :srcObject="track.stream"
                ></video>
            </div>
            <!-- <div
                class="layer_hover"
                
                v-if="!track.self"
            >
                <InlineSvg
                    v-if="!track.muted"
                    class="micro_hover"
                    :src="require('@/assets/svg/micro.svg')"
                />
            </div> -->
            <div class="layer_admin" v-if="track.admin">
                <InlineSvg :src="require('@/assets/svg/crown-4697.svg')" class="admin-crown" />
            </div>

            <div v-if="track.muted" class="layer_mute">
                <InlineSvg :src="require('@/assets/svg/micro_mute.svg')" class="" />
            </div>
            <div class="caption" v-if="showName || showPoints">
                <div class="flex flex-row items-center w-full">
                    <div class="w-8/12" v-if="showName">
                        {{ track.playerName }}
                    </div>
                    <div
                        class="flex flex-row w-4/12 items-center gap-1"
                        v-if="showPoints && $store.state.storeGameState.gameState == 'game'"
                    >
                        {{ track.points }}
                        <InlineSvg
                            :src="require('@/assets/svg/trophy-svgrepo-com.svg')"
                            style="width:1rem; 	fill: #fff;"
                            class="fill-white"
                        />
                    </div>
                </div>
            </div>

            <!-- <div v-if ="track.points" class="caption" v-html="track.points"></div> -->
        </div>
    </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from "@vue/runtime-core";
import InlineSvg from "vue-inline-svg";
import { useStore } from "vuex";

let mountedTime = {};
// import { computed,ref,watch } from 'vue'
// import {useStore} from 'vuex'
export default {
    name: "CardUser",

    components: {
        InlineSvg,
    },

    props: {
        ico: String,
        name: { type: String, default: "Carles" },
        avatar: { type: String, default: "user_prueba" },
        classCard: { type: String, default: "default" },
        game_master: { type: Boolean, default: false },
        self: { type: Boolean, default: false },
        track: Object,
        photoMode: { type: Boolean, default: false },
        showPoints: { type: Boolean, default: false },
        defaultPhoto: { type: Boolean, default: false },
        showName: { type: Boolean, default: true },
    },

    setup(props) {
        const videoRef = ref(null);
        const store = useStore();
        const muted = (idPlayer) => {
            store.commit("setMutedById", idPlayer);
        };
        let tobeCount = 0;
        const slowMode = computed(() => store.state.storeMedia.slowMode);
        // watch(()=>[props.track,slowMode.value, videoRef.value],()=>{
        //   if(!videoRef.value)
        //   {
        //     return;
        //   }
        //   if( !props.track)
        //   {
        //     return
        //   }
        //   if('muted' in props.track)
        //   {
        //     // videoRef.value.muted = props.track.muted
        //     // console.log("unMuted")
        //   }
        // //   if('stream' in props.track)
        // //   {
        // //     videoRef.value.srcObject = props.track.stream
        // //   }

        // },
        // )
        try {
            watch(
                () => [props.track.idPlayer],
                () => {
                    if ("idPlayer" in props.track) {
                        if (tobeCount) {
                            tobeCount = 0;
                            if (props.track.idPlayer in mountedTime) {
                                mountedTime[props.track.idPlayer]++;
                            } else {
                                mountedTime[props.track.idPlayer] = 1;
                            }
                        }
                    }
                }
            );
        } catch {
            console.log("error at watch");
        }
        watch(
            () => [mountedTime],
            () => {}
        );

        onMounted(() => {
            if (!props.track) {
                tobeCount = 1;
                return;
            }
            if (!props.track.idPlayer) {
                tobeCount = 1;
                return;
            }
            if (props.track.idPlayer in mountedTime) {
                mountedTime[props.track.idPlayer]++;
            } else {
                mountedTime[props.track.idPlayer] = 1;
            }

            if (!videoRef.value) {
                return;
            }
            const actualMedia = videoRef.value.srcObject;
            if (!actualMedia) {
                return;
            }

            actualMedia.getTracks().forEach(function(track) {
                if (track.kind == "audio") {
                    // track.stop(/);
                }
                track.enabled = true;
            });
        });
        onBeforeUnmount(() => {
            try {
                const actualMedia = videoRef.value.srcObject;
                mountedTime[props.track.idPlayer]--;
                if (!actualMedia) {
                    return;
                }
                if (mountedTime[props.track.idPlayer] > 0) {
                    return;
                }
                actualMedia.getTracks().forEach(function(track) {
                    if (track.kind == "audio") {
                        track.enabled = false;
                        // track.stop(/);
                    }
                });
            } catch {
                console.log("error at unMounted");
                return;
            }
        });

        return {
            muted,
            videoRef,
        };
    },
};
</script>

<style lang="scss" scoped>
.crossed {
    z-index: 999;
    margin-top: 5px;
    margin-left: 2px;
    opacity: 0.6;
    background: linear-gradient(
        to top right,
        rgba(255, 112, 51, 0) 0%,
        rgba(255, 112, 51, 0) calc(50% - 2px),
        rgba(255, 112, 51, 1) 50%,
        rgba(255, 112, 51, 0) calc(50% + 2px),
        rgba(255, 112, 51, 0) 100%
    );
}

.img-no-img {
    top: 50%;
    left: 50%;
    position: absolute;
    margin-left: -50%;
    margin-top: -50%;
    height: 100%;
    aspect-ratio: inherit;
}

.img-no-cam {
    top: 50%;
    left: 50%;
    position: absolute;
    margin-left: -66%;
    margin-top: -50%;
    height: 100%;
    aspect-ratio: inherit;
}
.img-no-cam-disconected {
    opacity: 0.5;
}

.video {
    height: 100%;
    max-width: 200%;
    position: absolute;
}

.onlysound {
    width: 0px;
    height: 0px;
}
.admin-wrap {
    max-width: 20%;
    top: -3%;
    position: absolute;
    z-index: 10000;
}

.admin-crown {
    max-width: 20%;

    :deep(svg) {
        width: 100%;
        path,
        rect {
        }
    }
}
.card.card_user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    cursor: pointer;

    background-size: cover;
    border: none;
    border-radius: 12px;
    overflow: hidden;

    // @media (min-width:$break-point){
    //   width: 100%;
    // }

    border: 2px solid;
    &.self {
        border: 5px solid color(primary);
    }

    &.talking {
        border: 2px solid color(primary);
        box-shadow: inset 0px 0px 4px 5px rgba(255, 112, 51, 0.2);

        .caption {
            background: linear-gradient(11.02deg, #ff7033 54.31%, rgba(255, 112, 51, 0) 167.64%);

            text-transform: capitalize;
        }
    }

    .layer_mute {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background:rgba(0, 0, 0, 0.5) ;
        opacity: 1;
        display: flex;
        justify-content: right;
        padding: 5px;

        :deep(svg) {
            width: 24px;
            height: 24px;

            path,
            rect {
                fill: color(primary) !important;
            }
        }
    }

    .layer_admin {
        position: absolute;
        top: 0;
        left: 5px;
        right: 0;
        bottom: 0;
        opacity: 1;
        display: flex;
        :deep(svg) {
            width: 24px;
            height: 24px;

            path,
            rect {
                fill: color(primary) !important;
            }
        }
    }

    &.empty {
        cursor: default;
        background-image: url(../../assets/img/avatar.png);
        box-shadow: inset 0px 0px 4px 1px rgba(133, 134, 142, 0.2);

        .caption {
            display: none;
            text-transform: capitalize;
        }
    }

    &:hover:not(.mute, .empty) {
        .layer_hover {
            opacity: 1;
        }
    }

    .caption {
        z-index: 50;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 105%;
        background: rgba(0, 0, 0, 0.5);
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        font-family: $font-family-header;
        line-height: 16px;
        letter-spacing: 0.3px;
        color: #fff;
        padding: 4px 12px;
        text-transform: capitalize;
        // @media (min-width:$break-point) {
        //   padding: 12px;
        //   align-items: center;
        //   justify-content: flex-start;
        //   flex-direction: row;
        //   font-size: 20px;
        //   line-height: 20px;
        // }

        // .name{
        //   @media (min-width:$break-point) {
        //       margin-right: 4px;
        //   }
        // }
    }

    .layer_hover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        :deep(svg) {
            width: 32px;
            height: 32px;

            path,
            rect {
                fill: color(grays);
            }
        }
    }
}
</style>
