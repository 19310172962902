<template>
    <div class="flex flex-row flex-wrap gap-5 justify-center">
        <div v-for="player in users" :key="player" class="userWarap">
            <CardUserImgVue
                :track="$store.getters.getMediaById(player)"
                v-on:click="updateAnsw(player)"
                :class="selected == player ? 'selected' : ''"
            />
        </div>
    </div>
</template>

<script>
const url = window.location.pathname;

import CardUserImgVue from "../cards/CardUserImg.vue";
import { ref as vref, onMounted, watch } from "vue";
import { db } from "../../firebase";
import {
    ref,
    off,
    onValue,
    onDisconnect,
    update,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
    push,
    set
} from "firebase/database";

export default {
    components: {
        CardUserImgVue
    },

    props: {
        users: Array,

        dataUrl: Object,
        parameterDB: { default: "selected", type: String }
    },

    setup(props) {
        let lastCallback = null;
        const selected = vref({
            selected: ""
        });

        watch(
            () => props.dataUrl,
            () => {
                off(lastCallback);
                const dataBasePlayerUrl = url + props.dataUrl;
                const teamDataDB = ref(db, dataBasePlayerUrl);
                onValue(teamDataDB, snapshot => {
                    updateDataBase(snapshot);
                });
                lastCallback = teamDataDB;
            }
        );

        onMounted(() => {
            const dataBasePlayerUrl = url + props.dataUrl;
            const teamDataDB = ref(db, dataBasePlayerUrl);
            onValue(teamDataDB, snapshot => {
                updateDataBase(snapshot);
            });
            lastCallback = teamDataDB;
        });

        const updateAnsw = text => {
            const dataBasePlayerUrl = url + props.dataUrl;
            const teamDataDB = ref(db, dataBasePlayerUrl);
            if (text == selected.value) {
                text = null;
            }
            update(teamDataDB, {
                [props.parameterDB]: text
            });
        };

        function updateDataBase(snapshot) {
            const data = snapshot.val();
            if (data) {
                selected.value = data[props.parameterDB];
            }
            else{
                selected.value = null;
            }
        }

        return {
            selected,
            updateAnsw
        };
    }
};
</script>

<style lang="scss" scoped>
.userWarap {
    width: 15vw;
    height: 15vw;
    :deep(.card_user) {
        width: 15vw;
        height: 15vw;
    }

    // height: 80px+ 10px;;
}

.selected {
    :deep(.card_user) {
        border: 5px solid #ff7033;
    }
}
</style>