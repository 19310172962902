<template>
    <TutorialLayout :content="[
      {
        title:$t('wereWolf.title_1'),
        text:$t('wereWolf.text_1'), 
      },
      {
        title:$t('wereWolf.title_2'),
        text:$t('wereWolf.text_2'), 
      },
      {
        title:$t('wereWolf.title_3'),
        text:$t('wereWolf.text_3'), 
      }
    ]">
        
    </TutorialLayout>
</template>

<script>
import TutorialLayout from "@/components/tutorial/TutorialLayout";

export default {
  components: {
    TutorialLayout

  },

  setup() {
  }
};
</script>

<style scoped lang="scss">

</style>