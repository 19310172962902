<template>
    <div class="flex flex-col text-center gap-10 p-5">
        <div class="deadcard p-5 flex flex-col gap-5" v-if="$store.state.storeGameData.wereWolfData.deadPlayer">
            <div class="userWarap mx-auto">
                <CardUserVue :track="$store.getters.getMediaById($store.state.storeGameData.wereWolfData.deadPlayer)" />
            </div>
            <h4>
                {{ $store.getters.getUserByIdName($store.state.storeGameData.wereWolfData.deadPlayer) }}
                {{ $t("wereWolf.action_result_kill") }}
            </h4>
        </div>
        <div class="deadcard p-5 flex flex-col gap-5" v-else>
            <h4>
                {{ $t("wereWolf.action_result_no_kill") }}
            </h4>
        </div>
        <div class="deadcard p-5 flex flex-col gap-5" v-if="$store.state.storeGameData.wereWolfData.rol == 'seer'">
            <h4 v-if="$store.state.storeGameData.wereWolfData.seerAnswer == 'yes'">
                {{ $t("wereWolf.action_result_vident_yes") }}
            </h4>
            <h4 v-if="$store.state.storeGameData.wereWolfData.seerAnswer == 'no'">
                {{ $t("wereWolf.action_result_vident_no") }}
            </h4>
        </div>
    </div>
</template>

<script>
import CardUserVue from "@/components/cards/CardUser.vue";
export default {
    components: {
        CardUserVue,
    },
};
</script>

<style lang="scss" scoped>
.userWarap {
    width: 25vw;
    height: 25vw;
    :deep(.card_user) {
        width: 25vw;
        height: 25vw;
    }

    // height: 80px+ 10px;;
}

.deadcard {
    background: #f4f5f7;
    /* Border/40 */

    border: 2px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 6px;
}
</style>
