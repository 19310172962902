<template>
<div class="card card_user " >
  


 
      <div class="layer_hover">
        <InlineSvg class="micro_hover" :src="require('@/assets/svg/micro.svg')" />
      </div>
      <div v-if="classCard=='mute'" class="layer_mute">
        <InlineSvg :src="require('@/assets/svg/micro_mute.svg')" />
      </div>
      <div class="caption" v-html="'hola'"></div>

</div>

</template>

<script>
    
    import InlineSvg from 'vue-inline-svg';
    // import { computed,ref,watch } from 'vue'
    // import {useStore} from 'vuex'
    export default {
      
        name: 'CardWelcome',

        components:{
            InlineSvg
        },

        props:{

            ico:              String,
            name:             {type:String, default:'Carles'},
            avatar:           {type:String, default:'user_prueba'},
            classCard:        {type:String, default:'default'},
            game_master:      {type:Boolean, default: false},
            self:             {type:Boolean, default:false},
            track:            Object,
            
        },

        setup(){
         
        }

    }

</script>

<style lang="scss" scoped>

    .video{
        width: $width-user+ 10px;;
        height: $height-user+ 10px;;
    }

    .onlysound{
      width:0px;
      height: 0px;
    }
    .card.card_user{
        
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        padding: 0;
        background-repeat: no-repeat;
        background-position:  center center;
        width: $width-user;
        height: $height-user;
        position: relative;
        cursor: pointer;
        
       
        background-size: cover;
        border: none;
        border-radius: 12px;
        overflow: hidden;

        // @media (min-width:$break-point){
        //   width: 100%;
        // }

        border: 2px solid;
        &.self{

          border: 5px solid color(primary);

        }

        &.talking{

          border: 2px solid color(primary);
          box-shadow: inset 0px 0px 4px 5px rgba(255, 112, 51, 0.2);

          .caption{
            
            background: linear-gradient(11.02deg, #FF7033 54.31%, rgba(255, 112, 51, 0) 167.64%);

            text-transform: capitalize;
          }

        }

        &.mute{


          .layer_mute{

            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            background: linear-gradient(315deg, #1B1C29 0%, rgba(27, 28, 41, 0) 100%);
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 12px;
  
            :deep(svg){
              
              width: 32px;
              height: 32px;
  
            }

          }

        }

        &.empty{
            
            cursor: default;
            background-image: url(../../assets/img/avatar.png);
            box-shadow: inset 0px 0px 4px 1px rgba(133, 134, 142, 0.2);

            .caption{
              display:none;
              text-transform: capitalize;
            }

        }

        &:hover:not(.mute,.empty){
          .layer_hover{
            opacity: 1;
          }
        }

        .caption{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          background: linear-gradient(315deg, #1B1C29 0%, rgba(27, 28, 41, 0) 100%);
          text-align: left;
          font-weight: bold;
          font-size: 14px;
          font-family: $font-family-header;
          line-height: 16px;
          letter-spacing: 0.3px;
          color: #fff;
          padding: 4px 12px;
          text-transform: capitalize;
          // @media (min-width:$break-point) {
          //   padding: 12px;
          //   align-items: center;
          //   justify-content: flex-start;
          //   flex-direction: row;
          //   font-size: 20px;
          //   line-height: 20px;
          // }

          // .name{
          //   @media (min-width:$break-point) {
          //       margin-right: 4px;
          //   }
          // }


          
        }

        .layer_hover{
          
          position: absolute;
          top: 0; left: 0; right: 0; bottom: 0;
          background: linear-gradient(315deg, #1B1C29 0%, rgba(27, 28, 41, 0) 100%);
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          :deep(svg){
            width: 32px;
            height: 32px;

            path,rect{
              fill: color(grays);
            }

          }

        }

    }

</style>