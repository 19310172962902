<template>
    <div class="flex flex-col gap-10 p-5">
        <h4 class="flex flex-row mb-auto mt-5 text-center px-10">
            {{ $t("wereWolf.vote_phase_title") }}
        </h4>
        <div style="">
            <div class="flex flex-row gap-5 items-center justify-center flex-wrap">
                <!-- <svg
                class="mx-auto flame"
                width="97"
                height="82"
                viewBox="0 0 97 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M63.1398 69.4572C63.1398 75.7605 57.4801 80.9436 50.2262 81.5645C68.3371 80.9166 82.7813 68.3099 82.7972 52.8147C82.7972 43.2584 79.5768 35.4029 73.949 27.3178C72.4115 25.1173 72.1829 26.5037 71.823 28.6869C71.3507 31.5521 70.6521 35.7895 66.4718 35.0924C66.4718 11.6607 41.3142 -3.51054 41.2504 1.63202C41.1289 12.6649 34.2843 20.5069 27.5788 28.1895C21.085 35.6297 14.7217 42.9203 14.7217 52.8147C14.7217 68.3099 29.1818 80.9031 47.2927 81.5645C40.0388 80.9436 34.3791 75.7605 34.3791 69.4572C34.3791 65.4214 35.7502 62.101 38.1256 58.6861C38.1256 58.6861 51.5654 43.8523 51.9321 47.8341C52.4105 53.018 55.3024 56.3228 58.0285 59.4382C60.6615 62.4471 63.1398 65.2793 63.1398 69.4572Z"
                    fill="#FF7033"
                />
                <g opacity="0.21">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M33.9897 55.1157C33.9897 59.4503 30.0977 63.0145 25.1093 63.4414C37.5638 62.9959 47.4966 54.3268 47.5076 43.6714C47.5076 37.1 45.293 31.6981 41.4229 26.1383C40.3656 24.6251 40.2084 25.5785 39.9609 27.0798C39.6361 29.05 39.1557 31.9639 36.281 31.4846C36.281 15.3716 18.9808 4.93896 18.9369 8.47528C18.8534 16.0621 14.1465 21.4548 9.53537 26.7377C5.06974 31.854 0.693848 36.8674 0.693848 43.6714C0.693848 54.3268 10.6377 62.9866 23.0921 63.4414C18.1037 63.0145 14.2117 59.4503 14.2117 55.1157C14.2117 52.3405 15.1546 50.0572 16.7881 47.709C16.7881 47.709 26.0303 37.5084 26.2824 40.2465C26.6115 43.8112 28.6001 46.0838 30.4748 48.2261C32.2854 50.2952 33.9897 52.2428 33.9897 55.1157Z"
                        fill="#FF7033"
                    />
                </g>
                <g opacity="0.21">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M81.924 56.0165C81.924 60.5509 77.8526 64.2794 72.6343 64.7261C85.6629 64.26 96.0536 55.1912 96.0651 44.0445C96.0651 37.17 93.7484 31.519 89.6999 25.7029C88.5939 24.1199 88.4294 25.1172 88.1705 26.6878C87.8307 28.7489 87.3282 31.7971 84.321 31.2957C84.321 14.4397 66.2232 3.52601 66.1774 7.2254C66.09 15.1621 61.1661 20.8034 56.3424 26.33C51.6709 31.6821 47.0933 36.9267 47.0933 44.0445C47.0933 55.1912 57.4955 64.2503 70.524 64.7261C65.3057 64.2794 61.2343 60.5509 61.2343 56.0165C61.2343 53.1133 62.2206 50.7247 63.9295 48.2682C63.9295 48.2682 73.5977 37.5972 73.8615 40.4616C74.2057 44.1907 76.286 46.5681 78.2471 48.8092C80.1412 50.9737 81.924 53.0111 81.924 56.0165Z"
                        fill="#FF7033"
                    />
                </g>
            </svg> -->
                <div v-for="item in $store.state.storeGameData.wereWolfData.votePlayers" :key="item">
                    <CardUser
                        :track="$store.getters.getMediaById(item.idPlayer)"
                        class="userWarap"
                        :class="item.status"
                        v-on:click="vote(item.idPlayer)"
                    />
                    <div
                        v-if="item.status == 'alive'"
                        class="flex felx-row w-6/12 mx-auto my-2 vote"
                        v-on:click="vote(item.idPlayer)"
                        :class="item.idPlayer == $store.state.storeGameData.wereWolfData.myVote ? 'voted' : ''"
                    >
                        <div class="w-full p-1 text-center vote-number">
                            <p v-if="item.idPlayer in $store.state.storeGameData.wereWolfData.playersVotes">
                                {{ $store.state.storeGameData.wereWolfData.playersVotes[item.idPlayer] }}
                                {{
                                    $t(
                                        "wereWolf.vote",
                                        $store.state.storeGameData.wereWolfData.playersVotes[item.idPlayer]
                                    )
                                }}
                            </p>
                            <p v-else>0 {{ $t("wereWolf.vote", 0) }}</p>
                        </div>
                    </div>
                    <div v-else class="flex felx-row w-6/12 mx-auto my-2 vote">
                        <div class="text-center p-1 w-full">{{ $t("wereWolf.dead") }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import CardUser from "@/components/cards/CardUser.vue";
import { useStore } from "vuex";
import { notify } from "notiwind";
import { useI18n } from "vue-i18n";

export default {
    components: {
        CardUser,
    },

    setup() {
        const store = useStore();
        const { t } = useI18n();

        const numberDiv = ref(10);
        const vote = (idPlayer) => {
            if (store.state.storeGameData.wereWolfData.ableToVote) {
                store.dispatch("wereWolfUserAction", idPlayer);
            } else {
                notify(
                    {
                        group: "bottom",
                        title: t("wereWolf.alert_dead"),
                        text: "",
                    },
                    2000
                );
            }
        };
        return {
            numberDiv,
            vote,
        };
    },
};
</script>

<style lang="scss" scoped>
.circle-wrapper {
    position: absolute;

    left: 50%;

    top: 55%;

    transform: translate(-50%, -50%);
}

.flame {
    position: absolute;

    left: 50%;

    top: 50%;

    transform: translate(-50%, -50%);
}

.circle {
    display: block;
    position: absolute;
    margin: -5vw;
}

.userWarap {
    width: 15vw;
    height: 15vw;
    :deep(.card_user) {
        width: 15vw;
        height: 15vw;
    }

    // height: 80px+ 10px;;
}

.selected {
    :deep(.card_user) {
        border: 5px solid #ff7033;
    }
}

.dead {
    opacity: 30%;
}
.vote {
    /* Primary/100 */
    cursor: pointer;
    border: 2px solid grey;
    box-sizing: border-box;
    border-radius: 3px;
}

.vote-number {
    border-right: 1px solid grey;
}
.voted {
    border: 2px solid #ff7033;
}
</style>
