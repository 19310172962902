<template>
    <input type="checkbox" id="switch" :checked="active" @click="setActive" /><label for="switch"></label>
</template>

<script>
    export default {

        name: 'InputSwitch',

        emits: ["active"],

        props:{
            active: {Type:Boolean, default:false}
        },

        methods:{

            setActive(){
                this.$emit('active',!this.active);
            }

        }

    }
</script>

<style lang="scss" scoped>
    
    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 48px;
        height: 28px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    input:checked + label {
        background: color(primary);
    }

    input:checked + label:after {
        left: calc(100% - 4px);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 40px;
    }

</style>