<template>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 0">
        <Tutorial />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 1">
        <Answer />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 2">
        <Answer />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 3">
        <RoundResult />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 4">
        <GuessMain />
    </GameContainer>
    <GameContainer :timer="true" v-if="$store.state.storeGameState.gameStep == 5">
        <FinalResult />
    </GameContainer>
    <GameContainer v-if="$store.state.storeGameState.gameStep == 6">
        <FinalFeedback />
    </GameContainer>
</template>
<script>
import Tutorial from "./Tutorial.vue";
import Answer from "./Answer.vue";
import RoundResult from "./RoundResult.vue";
import FinalResult from "./FinalResult.vue";
import GuessMain from "./GuessMain.vue";
import GameContainer from "@/components/gameWidgets/GameContainer.vue";
import FinalFeedback from "../FinalFeedback.vue";

export default {
    components: {
        Tutorial,
        GameContainer,
        FinalFeedback,
        Answer,
        RoundResult,
        GuessMain,
        FinalResult,
    },
};
</script>
<style lang="scss"></style>
