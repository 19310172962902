<template>
  <div class="center h-full w-full">
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  background:transparent
}
.wave {
  width: 5%;
  height: 100%;
  background: color(primary);
  margin: 2%;
  animation: wave 1s linear infinite!important;
  border-radius: 10%;
}
.wave:nth-child(2) {
  animation-delay: 0.1s!important;
}
.wave:nth-child(3) {
  animation-delay: 0.2s!important;
}
.wave:nth-child(4) {
  animation-delay: 0.3s!important;
}
.wave:nth-child(5) {
  animation-delay: 0.4s!important;
}
.wave:nth-child(6) {
  animation-delay: 0.5s!important;
}
.wave:nth-child(7) {
  animation-delay: 0.6s!important;
}
.wave:nth-child(8) {
  animation-delay: 0.7s!important;
}
.wave:nth-child(9) {
  animation-delay: 0.8s!important;
}
.wave:nth-child(10) {
  animation-delay: 0.9s!important;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

</style>