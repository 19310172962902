<template>
      <div class="welcome">
        <h2 class="h3">{{$t('WELCOME2.title')}}</h2>
        <div class="card-group" v-if="$store.getters.getMedia">
          <CardMicro :media="$store.getters.getMedia" :volMic="$store.state.storeMedia.volMic"/>
          <!-- <CardSpeakers :media="media"/> -->
          <CardVideo :media="$store.getters.getMedia" :localStream="$store.state.storeMedia.localStream" />
        </div>
        <div class="buttons-group">
          <BtnBase @click="setModal(true)" :text="$t('WELCOME2.btnNoOK')" classBtn="btn-primary btn-outline" />
          <BtnBase @click="nextStep(step)" :text="$t('WELCOME2.btnOK')" />
        </div>
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
        >
          <ModalWelcome v-if="modal" :cantClose ="cantClose" @setModal="setModal" />
        </transition>
      </div>
</template>

<script>


import CardMicro from "@/components/cards/CardMicro.vue";
import CardSpeakers from "@/components/cards/CardSpeakers.vue";
import CardVideo from "@/components/cards/CardVideo.vue";

import BtnBase from "@/components/buttons/BtnBase";
import ModalWelcome from "@/components/modals/ModalWelcome.vue";
import { useStore } from "vuex";
import { ref, computed, watch, onMounted } from "vue"
export default {
  name: "SetUp",

  components: {
    CardMicro,
    CardSpeakers,
    BtnBase,
    ModalWelcome,
    CardVideo
  },

  props: {
    step: Number
  },

  

  data: function() {

  },

  setup(){
    const store = useStore();
    const mediapermisons = ref(computed(() => store.getters.getPermisions));
    onMounted(()=>{
      if(mediapermisons.value == 3)
        {
          console.log("iiiiiiiiim")
          modal.value = true;
          cantClose.value = true;
        }
    })
    const modal = ref(false)
    const cantClose = ref(false)
    watch(mediapermisons, () => {
        console.log(mediapermisons.value)
        if(mediapermisons.value == 3)
        {
          console.log("iiiiiiiiim")
          modal.value = true;
          cantClose.value = true;
        }
     
      });
    
    const setModal = (modal_status) => {
       modal.value = modal_status;
    }

    return{
      modal,
      cantClose,
      setModal,
    }
  },
  methods: {
    nextStep: function(var_step) {
      // Pasem a la seguent pagina Welcome
      this.$emit("setStep", var_step + 1);
    },

    
  }
};
</script>

<style scoped lang="scss">
.welcome {
  h2 {
    & + p {
      margin-top: 8px;
    }
  }

  .card-group {
    margin-top: 48px;
    display: flex;
    align-items: stretch;

    :deep(.card) {
      margin: 0 24px;
      min-width: 245px;
      min-height: 300px;
    }
  }

  .buttons-group {
    margin-top: 48px;

    :deep(button) {
      margin: 0 12px;
    }
  }
}

</style>
