<template>

    <div class="navbar navbar_bottom">
        
        <div class="menu menu-left">
            <BtnIco @click="this.$emit('setModal_logout',true)" ico="log_out" classBtn="danger" />
        </div>

        <div class="menu menu-center">
            <BtnIco ico="micro" />
            <BtnIco ico="video_camera" />
            <BtnIco ico="full_screen" />
            <BtnIco ico="volume-low" />
        </div>

        <div class="menu menu-right">
            <BtnIco ico="sun" />
            <TopRight 
                :team_format="team_format" @team_format="setTeamFormat" 
                :game_active="game_active" @game_active="setGameActive" 
                :game_master="game_master" @game_master="setGameMaster"
                @alert="setAlert" 
            />
        </div>
    </div>

</template>

<script>

    import BtnIco from '@/components/buttons/BtnIco';
    import TopRight from '@/components/dropdowns/TopRight';

    export default {
        
        name: 'NavbarBottom',

        components:{
            BtnIco,
            TopRight
        },

        props:{
            team_format: {type:Number,default:0},
            game_active: {type:Number,default:0},
            game_master: {type:Boolean,default:0},
        },

        methods:{

            // Definimos el tipo de equipos que participan en el juego
            setTeamFormat(team_format){

                this.$emit('team_format',team_format);

            },

            // Seleccionamos el juego
            setGameActive(game_active){

                this.$emit('game_active',game_active);

            },


            // Activar / Desactivar Game Master
            setGameMaster(game_master){

                this.$emit('game_master',game_master);

            },

            setAlert(alert){
                this.$emit('alert',alert)
            }

        }

    }
    
</script>

<style lang="scss" scoped>

    .navbar.navbar_bottom{

        display: flex;
        align-items: center;
        height: 88px;
        padding: 32px;
        width: 100%;
        border-top: 1px solid #E6E6E6;

        .menu{

            flex: 1 0 auto;
            display: flex;
            align-items: center;
            
            &.menu-center{
                justify-content: center;
            }
            
            &.menu-right{
                justify-content: flex-end;
            }

            button{
                
                margin:0 8px;

                &:first-child{
                    margin-left: 0;
                }

                &:last-child{
                    margin-right: 0;
                }

            }

        }

        svg{

            height: 32px;
            width: auto;
            opacity: 0.5;

        }

    }

</style>