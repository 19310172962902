import { pictionaryGameDataScret, pictionaryGameDataAdmin, pictionaryGameDataAdmin2, pictionaryGameDataResults, pictionaryStartGame, pictionaryFinishGame } from './pictionaryGame'
import { factMatchingGameDataScret, factMatchingGameDataAdmin, factMatchingGameDataAdmin2, factMatchingGameDataResults, factMatchingStartGame, factMatchingFinishGame } from './factMatchingGame'
import { mingleTingleGameDataScret, mingleTingleGameDataAdmin, mingleTingleGameDataAdmin2, mingleTingleGameDataResults, mingleTingleStartGame, mingleTingleFinishGame } from './mingleTingleGame'
import { sellerSpongeGameDataScret, sellerSpongeGameDataAdmin, sellerSpongeGameDataAdmin2, sellerSpongeGameDataResults, sellerSpongeStartGame, sellerSpongeFinishGame } from './sellerSpongeGame'
import { wereWolfDataScret, wereWolfGameDataAdmin, wereWolfGameDataAdmin2, wereWolfGameDataResults, wereWolfStartGame, wereWolfFinishGame } from './wereWolfGame'
import { topoDataScret, topoGameDataAdmin, topoGameDataAdmin2, topoGameDataResults, topoStartGame, topoFinishGame } from './topoGame'
import { quizDataScret, quizGameDataAdmin, quizGameDataAdmin2, quizGameDataResults, quizStartGame, quizFinishGame } from './quizGame'
import { thisOrThatDataScret, thisOrThatGameDataAdmin, thisOrThatGameDataAdmin2, thisOrThatGameDataResults, thisOrThatStartGame, thisOrThatFinishGame } from './thisOrThatGame'

const emptyfunc = () => {

}
const gameFunctions = {


    "pictionary": {
        "start": pictionaryStartGame,
        "finish": pictionaryFinishGame,
        "gameDataAdmin": pictionaryGameDataAdmin,
        "gameDataSecret": pictionaryGameDataScret,
        "gameDataAdmin2": pictionaryGameDataAdmin2,
        "gameDataResults": pictionaryGameDataResults,
    },
    "factMatching": {
        "start": factMatchingStartGame,
        "finish": factMatchingFinishGame,
        "gameDataAdmin": factMatchingGameDataAdmin,
        "gameDataSecret": factMatchingGameDataScret,
        "gameDataAdmin2": factMatchingGameDataAdmin2,
        "gameDataResults": factMatchingGameDataResults,
    },
    "mingleTingle": {
        "start": mingleTingleStartGame,
        "finish": mingleTingleFinishGame,
        "gameDataAdmin": mingleTingleGameDataAdmin,
        "gameDataSecret": mingleTingleGameDataScret,
        "gameDataAdmin2": mingleTingleGameDataAdmin2,
        "gameDataResults": mingleTingleGameDataResults,
    },
    "sellerSponge": {
        "start": sellerSpongeStartGame,
        "finish": sellerSpongeFinishGame,
        "gameDataAdmin": sellerSpongeGameDataAdmin,
        "gameDataSecret": sellerSpongeGameDataScret,
        "gameDataAdmin2": sellerSpongeGameDataAdmin2,
        "gameDataResults": sellerSpongeGameDataResults,
    },
    "wereWolf": {
        "start": wereWolfStartGame,
        "finish": wereWolfFinishGame,
        "gameDataAdmin": wereWolfGameDataAdmin,
        "gameDataSecret": wereWolfDataScret,
        "gameDataAdmin2": wereWolfGameDataAdmin2,
        "gameDataResults": wereWolfGameDataResults,
    },
    "topo": {
        "start": topoStartGame,
        "finish": topoFinishGame,
        "gameDataAdmin": topoGameDataAdmin,
        "gameDataSecret": topoDataScret,
        "gameDataAdmin2": topoGameDataAdmin2,
        "gameDataResults": topoGameDataResults,
    },
    "quiz": {
        "start": quizStartGame,
        "finish": quizFinishGame,
        "gameDataAdmin": quizGameDataAdmin,
        "gameDataSecret": quizDataScret,
        "gameDataAdmin2": quizGameDataAdmin2,
        "gameDataResults": quizGameDataResults,
    },
    "thisOrThat": {
        "start": thisOrThatStartGame,
        "finish": thisOrThatFinishGame,
        "gameDataAdmin": thisOrThatGameDataAdmin,
        "gameDataSecret": thisOrThatDataScret,
        "gameDataAdmin2": thisOrThatGameDataAdmin2,
        "gameDataResults": thisOrThatGameDataResults,
    },
    "loading": {
        "start": emptyfunc,
        "finish": emptyfunc,
        "gameDataAdmin": emptyfunc,
        "gameDataSecret": emptyfunc,
        "gameDataAdmin2": emptyfunc,
        "gameDataResults": emptyfunc,
    }
}


const syncDbGameStart = ({ commit, state, rootState }, currentGame, startGame) => {

    if (startGame == 'demoGame' || currentGame == 'demoGame') {
        return;
    }

    if (currentGame) {
        gameFunctions[currentGame].finish({ commit, state, rootState })
    }
    console.log(startGame)
    if (startGame != "loading" && startGame) {
        console.log(gameFunctions[startGame].start)
        gameFunctions[startGame].start({ commit, state, rootState })
    }

}

export { syncDbGameStart, gameFunctions }