<template>
  <div class="game_content">
    <canvas id="draw" class="draw" :width="400 * scale" :height="400*scale"></canvas>
    
  </div>
  
</template>

<script>
import { onMounted,computed, ref, watch } from "vue";
import {useStore} from 'vuex'

export default {
    props:{
      scale:{type:Number, default:1}
    },


  setup(props) {
    const root = ref(null)
    const store = useStore();
    const pointsDraw = computed(()=> store.state.storeGameData.pictionaryData.pointsDraw)

    var canvas, ctx, flag = false;


    onMounted(() => {
        canvas = document.getElementById("draw");
        ctx = canvas.getContext("2d"); 
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        for(let index=0; index < pointsDraw.value.length;index++)
        {
            drawRT(JSON.parse(pointsDraw.value[index]))
        }
    });
    
    watch(()=>
        pointsDraw.value, () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for(let index=0; index < pointsDraw.value.length;index++)
            {
                drawRT(JSON.parse(pointsDraw.value[index]))
            }
        }
    )

    function drawRT(data){
        ctx.beginPath();
        if(data != null)
        {
          data.points.forEach((element,index) => {
          if(index > 0 && index < data.points.length-1)
          {
              ctx.strokeStyle =  data.color;
              ctx.fillStyle =  data.color;
              ctx.lineWidth = data.lineWidth * 2 * props.scale;
              ctx.lineTo(element.x * props.scale, element.y * props.scale);
              ctx.stroke();
              ctx.beginPath();
              ctx.arc(element.x* props.scale, element.y* props.scale, data.lineWidth * props.scale, 0, Math.PI * 2);
              ctx.fill();  
              ctx.beginPath();
              ctx.moveTo(element.x * props.scale, element.y * props.scale);
           }
          });
        }
        
        
    }


    return{
      pointsDraw,
    }
  }
};
</script>

<style lang="scss">
.game_content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.draw {
  align-content: center;
  border: 2px solid color(primary);

}

.userWarap {
  width: 160px;
  :deep(.card_user) {
    width: $width-user + 10px;
    height: $width-user + 10px;
  }
}

.center{
text-align: center;
}
</style>