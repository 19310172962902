<template>
    <TutorialLayout :content="[
      {
        title:$t('pictionary.title_1'),
        text:$t('pictionary.text_1'), 
      },
      {
        title:$t('pictionary.title_2'),
        text:$t('pictionary.text_2'), 
      },
      {
        title:$t('pictionary.title_3'),
        text:$t('pictionary.text_3'), 
      }
    ]">
        
    </TutorialLayout>
</template>

<script>
import TutorialPager from "@/components/tutorial/TutorialPager";
import CardTutorial from "@/components/tutorial/CardTutorial";
import Timer from "@/components/gameWidgets/Timer";
import TutorialLayout from "@/components/tutorial/TutorialLayout.vue";
import { ref } from "vue";
export default {
    components: {
        TutorialPager,
        CardTutorial,
        Timer,
        TutorialLayout,
    },

    setup() {
        const tutorialPage = ref(1);
        function setStep(data_in) {
            console.log("in");
            tutorialPage.value = data_in + 1;
        }

        return {
            tutorialPage,
            setStep,
        };
    },
};
</script>

<style scoped lang="scss">
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 32px 88px;
    flex: 1 0 auto;
    width: 100%;
    gap: 2rem;

    // Añadimos un envoltorio para evitar que el elemento
    // desplace el contendor de welcome al aparecer en el Step 1
    .pager-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 100%;
    }
}

.waitingPermisons {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.8); /* <-- this is the culprit */

    h2 {
        & + p {
            margin-top: 8px;
            display: flex;
            align-items: stretch;
        }
    }
}
</style>