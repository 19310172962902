<template>
    <div class="lobby_page"  v-if="$store.state.storeGameState.gameType=='grup'" >
            <loading :active='isLoading' :is-full-page="true"  color="#FF7033" />


        <!--    
            Secció que conte els jugadors que encara no han seleccionat un equip.
            També inclou un temporitzador i un contador de jugadors.    
        -->
        <transition enter-active-class="animate__animated animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutLeft" appear mode="out-in" >
            <PlayersOff :teams="false" />
            
        </transition>
        
        <div class="main-content" :class="{grid:view_active==2}">

            <!-- <transition enter-active-class="animate__animated animate__fadeInLeft" leave-active-class="animate__animated animate__fadeOutRight" appear mode="out-in" >
                <HeaderLobby v-if="page==1" />
            </transition> -->

            
            <!-- <View4Players v-if="view_active==1" /> -->
            <ViewRivals  />
            <!-- <ViewIndividual v-if="view_active==3" /> -->
            
            
        </div>
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
        >
          <ModalUrlError v-if="$store.state.storeMedia.errorUrl.modal" :error="$store.state.storeMedia.errorUrl.error" />
        </transition>
        
        <!-- Btn Prueba para pasar a game -->
        <!-- <transition enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown" appear mode="out-in" >
            <BtnBase v-if="page==1" @click="this.$emit('setPage',2)" text="Anar al joc" class="btn-borrar btn-xs" />
        </transition> -->
  
    </div>
     <div class="lobby_page" v-else-if="$store.state.storeGameState.gameType=='individual'" >
        <loading :active='isLoading' :is-full-page="true"  color="#FF7033" />
        <transition enter-active-class="animate__animated animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutLeft" appear mode="out-in" >
            <PlayersOff :teams="true"/>
           
        </transition>
         <ViewIndividual/>
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
        >
          <ModalUrlError v-if="$store.state.storeMedia.errorUrl.modal" :error="$store.state.storeMedia.errorUrl.error" />
        </transition>
       

    </div>
    <div v-else>
         <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
        >
          <ModalUrlError v-if="$store.state.storeMedia.errorUrl.modal" :error="$store.state.storeMedia.errorUrl.error" />
        </transition>

    </div>
</template>

<script>


    import HeaderLobby from '@/components/lobby/HeaderLobby'
    import BtnBase from '@/components/buttons/BtnBase'
    
    import CardPlayers from '@/components/cards/CardPlayers'
    import PlayersOff from '@/components/lobby/PlayersOff'

    import View8Players from '@/components/lobby/View8Players'
    import View4Players from '@/components/lobby/View4Players'
    import ViewRivals from '@/components/lobby/ViewRivals.vue'
    import ViewIndividual from '@/components/lobby/ViewIndividual.vue'
    import { useStore } from "vuex"
    import { computed, ref } from "vue"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import ModalUrlError from "@/components/modals/ModalUrlError.vue";
    // Import stylesheet
    export default {
        
        name: 'LobbyPage',

        components:{
            
            HeaderLobby,
            BtnBase,

            CardPlayers,
            PlayersOff,

            View8Players,
            View4Players,
            ViewRivals,
            ViewIndividual,

            Loading,
            ModalUrlError,

        },

        props:{
            page: Number
        },

        setup(){
            const store = useStore();
            let streamON = computed(() => store.getters.getStreamStatus)
            const isLoading = ref(computed(() => store.getters.getloadingStart))
            
            const url = window.location.pathname
            // check if url is okei and then connect
            store.dispatch("connect",{
                url,
            });
            store.dispatch("syncDB")
            return {
                isLoading
            }


        },
        methods:{

            setView(view){

                this.view_active=view

            }

        }

    }

</script>

<style lang="scss" scoped>

    /* Borrar boton provisional */
    .btn-borrar{
        position: absolute;
        bottom:32px;
        left:32px;
    }
    
    /* Borrar boton provisional */
    .button-group{
        
        position:absolute;
        bottom:96px;
        left:32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 5000;
        border-radius: 8px;
        overflow: hidden;

        .btn-borrar{
            position: static;
            border-radius: 0px;
        }

    }

    .lobby_page{

        display: flex;
        align-items: center;
        flex-direction: column;

        width: 100%;
        flex:1 1 100%;
        overflow: hidden;

        background: url(../assets/img/bg_logo_bonfire.png) no-repeat bottom right;
        background-size: contain;

    }

    .players_without_team{

        width: 100%;
        background: linear-gradient(88.61deg, #F4F5F7 37.76%, rgba(244, 245, 247, 0.5) 117.35%);
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
        padding-bottom: 12px;

        &__header{
            display: flex;
            justify-content: space-between;
            padding: 12px 24px;
            width: 100%;
        }

        &__players{
            
            width: 100%;
            overflow: scroll;

            :deep(.card_user){
                width: 88px;
                height: 88px;
            }

        }

    }
    

    .main-content{

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width:100%;
        padding: 48px 48px 64px;
        flex:1 1 auto;
        
        overflow: scroll;
        // overflow-y: hidden; /* Hide vertical scrollbar */
        overflow-x: hidden; /* Hide horizontal scrollbar */

    

        @media (min-width:$break-point) {
            align-items: flex-start;
            max-width: 90%;
        }

        &.grid .main{

            display: grid;
            grid-template-columns: 1fr;
            column-gap: 32px;
            row-gap: 32px;
            margin-top:48px;

            @media (min-width:$break-point) {
                grid-template-columns: 1fr 1fr;
            }

            :deep(.card-team){
                margin-top: 0;
            }

        }

    }

</style>